import React from 'react';
import { ValueType } from 'react-select';
import { useToasts } from 'react-toast-notifications';

import { useUsersApi } from '@api/users';
import { UserDocumentType } from '@types';
import { applicationStore } from '@stores';
import { fileToBase64 } from '@helpers/files';
import { getFormatBytes } from '@helpers/formats';
import { BasePreloader, Button, Select } from '@components';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';
import icoDelRed from '@assets/images/ico-del-red.svg';

interface Props {
  onSave: () => void;
}

interface SelectOptionType {
  value: string;
  label: string;
}

const AddDocument: React.FC<Props> = (props) => {
  const { addToast } = useToasts();
  const usersApi = useUsersApi();
  const [saved, setSaved] = React.useState(true);
  const inputRef = React.createRef<HTMLInputElement>();
  const [file, setFile] = React.useState<File | undefined>(undefined);
  const [documentType, setDocumentType] = React.useState<string | null>(null);
  const [documentTypes, setDocumentTypes] = React.useState<UserDocumentType[]>([]);
  const documentTypeOptions = documentTypes.map((i) => ({
    label: i.translated,
    value: i.type,
  }));

  React.useEffect(() => {
    loadDocumentTypes();
    return () => usersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDocumentTypes = async () => {
    try {
      const response = await usersApi.getDocumentTypes();
      setDocumentTypes(response.data);
      if (response.data.length > 0) {
        setDocumentType(response.data[0].type);
      }
    } catch (err) {
      if (usersApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const saveFile = async () => {
    if (!file) {
      return;
    }

    const maxPostBodySize = applicationStore.getState().systemInfo?.post_max_size;
    if (!!file && !!maxPostBodySize && maxPostBodySize < file.size) {
      addToast(`Soubor ${file.name} nesmí být větší než ${getFormatBytes(maxPostBodySize)}`, { appearance: 'error' });
      return;
    }

    setSaved(false);
    try {
      const fileBase64 = await fileToBase64(file);
      usersApi
        .uploadMyDocumentFront({
          mime: file.type,
          type: 'file',
          document_type: documentType || undefined,
          data: fileBase64,
          original_name: file.name,
        })
        .then(async () => {
          props.onSave();
          setFile(undefined);
          setSaved(true);
        })
        .catch((err) => {
          if (usersApi.isCancel(err)) {
            return;
          }
          setSaved(true);
        });
    } catch (err) {
      console.error(err);
      setSaved(true);
    }
  };

  const handleDocumentTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    setDocumentType(itemValue.value);
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined = e.target.files !== null ? e.target.files[0] : undefined;
    if (!!file) {
      setFile(file);
    }
  };

  const handleAddClick = (e: React.MouseEvent) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  const handleRemoveFile = () => {
    setFile(undefined);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <div className="add-document">
      <div className="item-input">
        <div className="add-document-wrapper">
          <div className="block-title">Přidat dokument:</div>
          {file ? (
            <div className="document-preview">
              {file.name}
              <img
                style={{ cursor: 'pointer', marginLeft: '8px' }}
                src={icoDelRed}
                alt="refused"
                onClick={handleRemoveFile}
              />
            </div>
          ) : (
            <a href="/" className="item-add" onClick={handleAddClick}>
              Vyberte soubor z počítače
            </a>
          )}

          {saved && <input type="file" accept="application/pdf" ref={inputRef} className="d-none" onChange={handleSelectFile} />}
        </div>
        <div className="item-type-select">
          <div className="select-label">Typ dokumentu:</div>
          <div className="select-container">
            <Select
              options={documentTypeOptions}
              onChange={handleDocumentTypeChange}
              value={documentTypeOptions.find((i) => i.value === documentType) || null}
            />
          </div>
        </div>
      </div>
      <div className="item-submit">
        {saved ? (
          <>
            {!!file && (
              <Button type="button" onClick={() => saveFile()}>
                <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
                <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
                Přidat dokument
              </Button>
            )}
          </>
        ) : (
          <BasePreloader className="file-preloader" size={25} />
        )}
        <div className="clearfix" />
      </div>
      <div className="clearfix" />
    </div>
  );
};

export default AddDocument;
