import React from 'react';
import clx from 'classnames';

import { useApplicationStore } from '@stores';
import AdminSidebar from '../AdminSidebar/AdminSidebar';
import LayoutHeader from '../LayoutHeader/LayoutHeader';
import LayoutFooter from '../LayoutFooter/LayoutFooter';

const BaseAdmin: React.FC = (props) => {
  const applicationState = useApplicationStore();

  return (
    <div className={clx(['page-admin', { 'zoom-enabled': applicationState.zoomEnabled }])}>
      <div className="page-content">
        <LayoutHeader />
        <div className="admin-page-wrapper d-flex">
          <AdminSidebar />
          <div className="admin-page-content min-vh-100">{props.children}</div>
          <div className="clearfix" />
        </div>
      </div>
      <LayoutFooter />
    </div>
  );
};

export default BaseAdmin;
