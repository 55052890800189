import React from 'react';
import { Table } from 'react-bootstrap';

import { Visible } from '@components';
import { AuctionInformedConsent, PermissionGroup, UserPermission } from '@types';

import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';

interface Props {
  title?: string;
  data: AuctionInformedConsent[];
  onDeleteClick?: (item: AuctionInformedConsent) => void;
  onEditClick?: (item: AuctionInformedConsent) => void;
}

const InformedConsentsTable: React.FC<Props> = (props) => {
  const handleEditClick = (e: React.MouseEvent, item: AuctionInformedConsent) => {
    e.preventDefault();
    if (!!props.onEditClick) {
      props.onEditClick(item);
    }
  };

  const handleDeleteClick = (e: React.MouseEvent, item: AuctionInformedConsent) => {
    e.preventDefault();
    if (!!props.onDeleteClick) {
      props.onDeleteClick(item);
    }
  };

  return (
    <div className="mt-4">
      {!!props.title && <h3 className="f-size-19 mb-20">{props.title}</h3>}
      <div className="responsive-table-content">
        <Table className="text-left table-middle border-bottom-0" striped>
          <thead>
            <tr>
              <th className="text-left" style={{ width: '5%' }}>
                #
              </th>
              <th className="text-left" style={{ width: '90%' }}>
                Název
              </th>
              <th style={{ width: '5%' }} />
            </tr>
          </thead>
          <tbody>
            {props.data.length < 1 && (
              <tr>
                <td className="text-left" colSpan={100}>
                  Nebyla nalezena žádná data.
                </td>
              </tr>
            )}
            {props.data.map((item, key) => (
              <tr key={`informed-consent-${key}`}>
                <td>{item.id}</td>
                <td>{item.title}</td>
                <td className="text-right">
                  <Visible
                    permissionName={UserPermission.canEdit}
                    permissionGroupName={PermissionGroup.auctionInformation}
                  >
                    <a
                      href="/"
                      className="d-inline-flex align-items-center mr-4"
                      onClick={(e) => handleEditClick(e, item)}
                    >
                      <img src={colorIcoConf} alt="ico" className="mr-2" />
                      Upravit
                    </a>
                    <a
                      href="/"
                      className="d-inline-flex align-items-center"
                      onClick={(e) => handleDeleteClick(e, item)}
                    >
                      <img src={colorIcoDel} alt="ico" className="mr-2" />
                      Smazat
                    </a>
                  </Visible>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default InformedConsentsTable;
