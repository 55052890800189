import { omit } from 'lodash';

import { API, APIResponse, useApi } from '@api/api';
import { getRequestDateFormat } from '@helpers/datetime';
import {
  BuyerCreateResponse,
  BuyerFormValues,
  BuyerIndividual,
  BuyerLegal,
  BuyerSJMPV,
  UserAuctionChangeAuthorizationData,
  UserAuctionSecurityData,
  BuyerAuctionResponse,
  AuctionBuyerListResponse,
  AdminDocumentsUsersFilter,
} from '@types';
import { useGridFilter } from '@stores';

export const changePreEmptionPath = (userId: string | number, auctionId: string | number) =>
  `/admin/user/${userId}/auction/${auctionId}/pre-emption`;
export const updatePath = () => `/buyer`;
export const adminUpdatePath = (id: string | number) => `/admin/buyer/${id}`;
export const createPath = () => `/buyer/new`;
export const adminCreatePath = () => `/admin/buyer/new`;
export const detailsPath = (id: string | number) => `/buyer/${id}`;
export const sendSecurityInstructionsPath = (userId: string | number, auctionId: string | number) =>
  `/admin/user/${userId}/auction/${auctionId}/auction-security-instructions`;
export const changeAuctionAuthorizationPath = (userId: string | number, auctionId: string | number) =>
  `/admin/user/${userId}/auction/${auctionId}/authorization`;
export const changeAuctionSecurityPath = (userId: string | number, auctionId: string | number) =>
  `/admin/user/${userId}/auction/${auctionId}/security`;
export const buyerAuctionPath = (auctionId: string | number) => `/admin/auction/${auctionId}/buyer`;
export const getAuctionsBuyersPath = () => `/admin/auction/buyer`;
export const getGenerateRandomIdentifierPath = () => `/buyer/generate/random-personal-identification-number`;

export interface BuyerAPI extends API {
  create(values: BuyerFormValues, isAdmin?: boolean): APIResponse<BuyerCreateResponse>;
  update(values: BuyerFormValues, id?: string | number): APIResponse;
  detail(id: string | number, isAdmin?: boolean): APIResponse<BuyerIndividual | BuyerLegal | BuyerSJMPV>;
  detail(id: string | number, isAdmin?: boolean): APIResponse<BuyerIndividual | BuyerLegal | BuyerSJMPV>;
  buyerAuction(buyerId: string | number, auctionId: string | number): APIResponse<BuyerAuctionResponse[]>;
  sendSecurityInstructions(userId: string | number, auctionId: string | number): APIResponse;
  changeAuctionAuthorization(
    userId: string | number,
    auctionId: string | number,
    data: UserAuctionChangeAuthorizationData
  ): APIResponse;
  changeAuctionSecurity(
    userId: string | number,
    auctionId: string | number,
    data: UserAuctionSecurityData
  ): APIResponse;
  changePreEmption(userId: string | number, auctionId: string | number, preEmption: boolean): APIResponse;
  auctionsBuyers(params?: AdminDocumentsUsersFilter): APIResponse<AuctionBuyerListResponse[]>;
  getRandomIdentifier(): APIResponse<{ personalIdentificationNumber: string }>;
}

export const useBuyerApi = (): BuyerAPI => {
  const api = useApi();

  const adminAuctionBuyersFilter = useGridFilter<AdminDocumentsUsersFilter>([
    {
      key: 'name',
      operator: 'like',
      property: 'user.fullName',
      value: (val) => (val ? `%${val}%` : undefined),
    },
    {
      key: 'number',
      operator: 'like',
      property: 'auction.number',
      value: (val) => (val ? `%${val.replaceAll(' ', '')}%` : undefined),
    },
    {
      key: 'status',
      group: 'status',
      property: 'auction.status',
    },
    {
      key: 'startDtFrom',
      operator: 'gte',
      property: 'auction.startDt',
    },
    {
      key: 'startDtTo',
      operator: 'lte',
      property: 'auction.startDt',
    },
    {
      key: 'endDtFrom',
      operator: 'gte',
      property: 'auction.endDt',
    },
    {
      key: 'endDtTo',
      operator: 'lte',
      property: 'auction.endDt',
    },
    {
      key: 'mainCategory',
      property: 'auctionCategory.parent',
    },
    {
      key: 'subCategory',
      property: 'auctionCategory.id',
    },
    {
      key: 'variableSymbol',
      property: 'userAuction.variableSymbol',
    },
    {
      key: 'specificSymbol',
      property: 'userAuction.specificSymbol',
    },
    {
      key: 'authorizationState',
      property: 'userAuction.authorizationState',
    },
    {
      key: 'cautionState',
      property: 'userAuction.cautionState'
    },
    {
      key: 'admitted',
      property: 'userAuction.admitted',
      value: (val) => (val !== undefined ? val === true : undefined),
    }
  ]);

  const getData = (values: BuyerFormValues) => {
    let address = new Array(3);

    const residence = {
      type: 'residence',
      id: values.addressId,
      address: values.address || '',
      city: values.city || '',
      zipCode: values.zip || '',
      country: values.country || '',
      mainAddress: true,
    };
    if (values.addressIndex !== undefined) {
      address[values.addressIndex] = residence;
    } else {
      address.push(residence);
    }

    if (values.hasDifferentDeliveryAddress) {
      const delivery = {
        type: 'delivery',
        id: values.deliveryAddressId,
        address: values.deliveryAddress || '',
        city: values.deliveryCity || '',
        zipCode: values.deliveryZip || '',
        country: values.deliveryCountry || '',
        mainAddress: true,
      };
      if (values.deliveryAddressIndex !== undefined) {
        address[values.deliveryAddressIndex] = delivery;
      } else {
        address.push(delivery);
      }
    }

    if (values.hasDifferentInvoiceAddress) {
      const invoice = {
        type: 'invoice',
        id: values.invoiceAddressId,
        address: values.invoiceAddress || '',
        city: values.invoiceCity || '',
        zipCode: values.invoiceZip || '',
        country: values.invoiceCountry || '',
        mainAddress: true,
      };
      if (values.invoiceAddressIndex !== undefined) {
        address[values.invoiceAddressIndex] = invoice;
      } else {
        address.push(invoice);
      }
    }

    // Remove empty indexes from array of addresses
    address = address.filter((v) => !!v);

    switch (values.type) {
      case 'individual':
        return {
          buyerType: values.type,
          degreePre: values.degreePre || '',
          degreePost: values.degreePost || '',
          firstName: values.firstName || '',
          lastName: values.lastName || '',
          email: values.email || '',
          citizenship: values.citizenship || '',
          phone: values.phone?.replaceAll(' ', '') || '',
          dataBox: values.eBox || '',
          accountNumber: `${values.bankAccountNumber}${!!values.bankAccountCode ? '/' : ''}${values.bankAccountCode}`,
          birthdate: !!values.birthDate ? getRequestDateFormat(values.birthDate) : '',
          personalIdentificationNumber: values.personalIdentificationNumber || '',
          identityCardNumber: values.identityCardNumber || '',
          newsletter: !!values.newsletter,
          userAddresses: address,
        };
      case 'own_account_worker':
        return {
          buyerType: values.type,
          degreePre: values.degreePre || '',
          degreePost: values.degreePost || '',
          firstName: values.firstName || '',
          lastName: values.lastName || '',
          ico: values.ico || '',
          dic: values.dic || '',
          email: values.email || '',
          phone: values.phone?.replaceAll(' ', '') || '',
          dataBox: values.eBox || '',
          citizenship: values.citizenship || '',
          accountNumber: `${values.bankAccountNumber}${!!values.bankAccountCode ? '/' : ''}${values.bankAccountCode}`,
          birthdate: !!values.birthDate ? getRequestDateFormat(values.birthDate) : '',
          personalIdentificationNumber: values.personalIdentificationNumber || '',
          identityCardNumber: values.identityCardNumber || '',
          newsletter: !!values.newsletter,
          userAddresses: address,
        };
      case 'legal':
        return {
          buyerType: values.type,
          email: values.email || '',
          phone: values.phone?.replaceAll(' ', '') || '',
          dataBox: values.eBox || '',
          company: values.companyName || '',
          ico: values.ico || '',
          dic: values.dic || '',
          citizenship: values.citizenship || '',
          register: values.issuer || '',
          accountNumber: `${values.bankAccountNumber}${!!values.bankAccountCode ? '/' : ''}${values.bankAccountCode}`,
          degreePre: values.degreePre || '',
          degreePost: values.degreePost || '',
          firstName: values.firstName || '',
          lastName: values.lastName || '',
          birthdate: !!values.birthDate ? getRequestDateFormat(values.birthDate) : '',
          personalIdentificationNumber: values.personalIdentificationNumber || '',
          identityCardNumber: values.identityCardNumber || '',
          position: values.position || '',
          newsletter: !!values.newsletter,
          userAddresses: address,
        };
      case 'joint_ownership':
        return {
          buyerType: values.type,
          degreePre: values.degreePre || '',
          degreePost: values.degreePost || '',
          firstName: values.firstName || '',
          lastName: values.lastName || '',
          email: values.email || '',
          phone: values.phone?.replaceAll(' ', '') || '',
          dataBox: values.eBox || '',
          citizenship: values.citizenship || '',
          accountNumber: `${values.bankAccountNumber}${!!values.bankAccountCode ? '/' : ''}${values.bankAccountCode}`,
          birthdate: !!values.birthDate ? getRequestDateFormat(values.birthDate) : '',
          personalIdentificationNumber: values.personalIdentificationNumber || '',
          identityCardNumber: values.identityCardNumber || '',
          share: values.share || '',
          newsletter: !!values.newsletter,
          userAddresses: address,
        };
      default:
        return {
          buyerType: values.type,
          degreePre: values.degreePre || '',
          degreePost: values.degreePost || '',
          firstName: values.firstName || '',
          lastName: values.lastName || '',
          email: values.email || '',
          phone: values.phone?.replaceAll(' ', '') || '',
          dataBox: values.eBox || '',
          citizenship: values.citizenship || '',
          accountNumber: `${values.bankAccountNumber}${!!values.bankAccountCode ? '/' : ''}${values.bankAccountCode}`,
          birthdate: !!values.birthDate ? getRequestDateFormat(values.birthDate) : '',
          personalIdentificationNumber: values.personalIdentificationNumber || '',
          identityCardNumber: values.identityCardNumber || '',
          share: values.share || '',
          newsletter: !!values.newsletter,
          userAddresses: address,
        };
    }
  };

  return {
    ...api,
    create(values: BuyerFormValues, isAdmin?: boolean) {
      const cancelToken = api.prepareCancelToken('create');
      let data: any = getData(values);
      return (isAdmin ? api.authRequest : api.request).post(isAdmin ? adminCreatePath() : createPath(), data, {
        cancelToken,
      });
    },
    update(values: BuyerFormValues, id?: string | number) {
      const cancelToken = api.prepareCancelToken('update');
      let data: any = omit(getData(values), [
        'email',
        'addressId',
        'addressIndex',
        'deliveryAddressId',
        'deliveryAddressIndex',
        'invoiceAddressId',
        'invoiceAddressIndex',
      ]);
      return (!!id ? api.authRequest : api.authFrontRequest).put(
        !!id ? adminUpdatePath(id || '') : updatePath(),
        data,
        { cancelToken }
      );
    },
    detail(id: string | number, isAdmin?: boolean) {
      const cancelToken = api.prepareCancelToken('detail');
      return (isAdmin ? api.authRequest : api.authFrontRequest).get(detailsPath(id), { cancelToken });
    },
    buyerAuction(userId: string | number, auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('buyerAuction');
      return api.authRequest.get(buyerAuctionPath(auctionId), {
        cancelToken,
        params: {
          filter: JSON.stringify([{ property: 'userAuction.user', operator: 'eq', value: userId, type: 'int' }]),
        },
      });
    },
    sendSecurityInstructions(userId: string | number, auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('sendSecurityInstructions');
      return api.authRequest.get(sendSecurityInstructionsPath(userId, auctionId), { cancelToken });
    },
    changeAuctionAuthorization(
      userId: string | number,
      auctionId: string | number,
      data: UserAuctionChangeAuthorizationData
    ) {
      const cancelToken = api.prepareCancelToken('changeAuctionAuthorization');
      return api.authRequest.put(changeAuctionAuthorizationPath(userId, auctionId), data, { cancelToken });
    },
    changeAuctionSecurity(userId: string | number, auctionId: string | number, data: UserAuctionSecurityData) {
      const cancelToken = api.prepareCancelToken('changeAuctionSecurity');
      return api.authRequest.put(changeAuctionSecurityPath(userId, auctionId), data, { cancelToken });
    },
    changePreEmption(userId: string | number, auctionId: string | number, preEmption: boolean) {
      const cancelToken = api.prepareCancelToken('changePreEmption');
      return api.authRequest.put(changePreEmptionPath(userId, auctionId), { preEmption }, { cancelToken });
    },
    auctionsBuyers(params?: AdminDocumentsUsersFilter) {
      const cancelToken = api.prepareCancelToken('auctionsBuyers');
      return api.authRequest.get(getAuctionsBuyersPath(), {
        cancelToken,
        params: {
          page: params?.page,
          perPage: params?.perPage,
          sort: params?.sort,
          filter: !adminAuctionBuyersFilter.isEmpty(params || {})
            ? adminAuctionBuyersFilter.toString(params || {})
            : undefined,
        },
      });
    },
    getRandomIdentifier() {
      const cancelToken = api.prepareCancelToken('getRandomIdentifier');
      return api.request.get(getGenerateRandomIdentifierPath(), { cancelToken });
    },
  };
};
