import React from 'react';
import { Table } from 'react-bootstrap';
import fileDownload from 'js-file-download';

import { useAuctionsApi } from '@api/auctions';
import { FileIcon, Visible } from '@components';
import AuctionDocumentEdit from './AuctionDocumentEdit';
import { AuctionDocumentResponse, PermissionGroup, UserPermission } from '@types';

import colorIcoDel from '@assets/images/color-ico-del.svg';

interface Props {
  auctionId?: string;
  isPublic: boolean;
  data: Array<AuctionDocumentResponse>;
  onDeleteClick: (item: AuctionDocumentResponse) => void;
}

const DocumentsTable: React.FC<Props> = (props) => {
  const auctionsApi = useAuctionsApi();

  const handleFileClick = (e: React.MouseEvent<HTMLAnchorElement>, item: AuctionDocumentResponse) => {
    e.preventDefault();
    auctionsApi
      .getDocument(props.auctionId || '', item.id)
      .then((res) => {
        fileDownload(res.data, item.media.originalName);
      })
      .catch((err) => {
        if (auctionsApi.isCancel(err)) {
          return;
        }
      });
  };

  const handleDeleteClick = (e: React.MouseEvent, item: AuctionDocumentResponse) => {
    e.preventDefault();
    props.onDeleteClick(item);
  };

  return (
    <div>
      <div className="mt-4">
        <div className="responsive-table-content">
          <Table className="table-middle border-bottom-0" striped>
            <thead>
              <tr>
                <th>Originální název dokumentu</th>
                {props.isPublic && <th>Typ dokumentu</th>}
                {props.isPublic && <th>Název dokumentu</th>}
                <Visible
                  permissionName={UserPermission.canDelete}
                  permissionGroupName={PermissionGroup.auctionDocuments}
                >
                  <th />
                </Visible>
              </tr>
            </thead>
            <tbody>
              {props.data.length < 1 && (
                <tr>
                  <td className="text-left" colSpan={100}>
                    Nebyla nalezena žádná data.
                  </td>
                </tr>
              )}
              {props.data.map((item, index) => (
                <tr key={`document-${index}`}>
                  <td>
                    <FileIcon name={item.media.originalName} />
                    <a href="/" onClick={(e) => handleFileClick(e, item)}>
                      {item.media.originalName}
                    </a>
                  </td>
                  {props.isPublic && <td>{item.documentTypeTranslation || item.documentType}</td>}
                  {props.isPublic && (
                    <td>
                      <AuctionDocumentEdit
                        auctionId={props.auctionId as string}
                        documentId={item.id}
                        name={item.media.title || item.documentTypeTranslation}
                      />
                    </td>
                  )}
                  <Visible
                    permissionName={UserPermission.canDelete}
                    permissionGroupName={PermissionGroup.auctionDocuments}
                  >
                    <td className="text-right">
                      <a
                        href="/"
                        onClick={(e) => handleDeleteClick(e, item)}
                        className="d-inline-flex align-items-center"
                      >
                        <img src={colorIcoDel} alt="ico" className="mr-2" />
                        Smazat
                      </a>
                    </td>
                  </Visible>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default DocumentsTable;
