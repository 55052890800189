import React from 'react';
import { Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import * as routes from '@routes';
import {useAuthStore, usePageStore, userRoleChecks} from '@stores';
import {AuctionResponse, AuctionStatus, User} from '@types';
import { getDateTimeFormat } from '@helpers/datetime';

import colorIcoEye from '@assets/images/color-ico-eye.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';
import colorIcoGavel from '@assets/images/color-ico-gavel.svg';
import closeIco from '@assets/images/front/icons/remove-filter.svg';
import icoTimesRed from '@assets/images/front/icons/times-red.svg';
import icoCheckGreen from '@assets/images/ico-check-green.svg';
import TableColumn, { TableColumnSortProps } from '@components/Table/TableColumn';

interface Props {
  type?: string;
  sort: TableColumnSortProps;
  data: Array<AuctionResponse>;
  stateToggle: (item: AuctionResponse) => void;
  exportToWebToggle: (item: AuctionResponse, server: string) => Promise<void>;
  exportToPDToggle: (item: AuctionResponse, server: string) => Promise<void>;
  renderTranslation: (text: string) => string;
  onSortChange: (sort: TableColumnSortProps) => void;
}

const ItemsTable: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  const authState = useAuthStore();
  const { isOfRoleSuperAdmin } = userRoleChecks(authState.user as User);

  const renderAuctionExportSummary = (item: AuctionResponse) => {
    if (item.auctionExportSummaryState !== null) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`export-state-${item.id}`}>
              <div>{item.auctionExportSummary}</div>
            </Tooltip>
          }
        >
          <img src={item.auctionExportSummaryState ? icoCheckGreen : icoTimesRed} alt="export-state" />
        </OverlayTrigger>
      );
    }
    return item.auctionExportSummary;
  };

  return (
    <div className="component-admin-auctions-table">
      <Table className="table-middle" striped>
        <thead>
          <tr>
            <TableColumn
              sort={props.sort}
              sortField="auction.id"
              className="w-1 text-nowrap"
              title={props.renderTranslation('table_head_id')}
              onSortChange={(val) => props.onSortChange(val)}
            />
            <TableColumn
              sort={props.sort}
              className="text-nowrap"
              sortField="auction.statusTranslated"
              title={props.renderTranslation('table_head_status')}
              onSortChange={(val) => props.onSortChange(val)}
            />
            <TableColumn
              sort={props.sort}
              className="text-nowrap"
              sortField="auction.title"
              title={props.renderTranslation('table_head_name')}
              onSortChange={(val) => props.onSortChange(val)}
            />
            <TableColumn
              sort={props.sort}
              className="text-nowrap"
              sortField="auction.number"
              title={props.renderTranslation('table_head_number')}
              onSortChange={(val) => props.onSortChange(val)}
            />
            <TableColumn
              sort={props.sort}
              sortField="auction.startDt"
              className="text-center text-nowrap"
              title={props.renderTranslation('table_head_start_dt')}
              onSortChange={(val) => props.onSortChange(val)}
            />
            <TableColumn
              sort={props.sort}
              sortField="auction.endDt"
              className="text-center text-nowrap"
              title={props.renderTranslation('table_head_end_dt')}
              onSortChange={(val) => props.onSortChange(val)}
            />
            <TableColumn title={props.renderTranslation('table_head_logged_count')} className="text-center" />
            <TableColumn title={props.renderTranslation('table_head_accept_count')} className="text-center" />
            <TableColumn title={props.renderTranslation('table_head_security')} className="text-center" />
            <TableColumn title={props.renderTranslation('table_head_public')} className="text-center" />
            <TableColumn title={props.renderTranslation('table_head_pd')} className="text-center" />
            <TableColumn title={props.renderTranslation('table_head_export')} className="text-center" />
            <TableColumn title={props.renderTranslation('table_head_online')} className="text-center" />
          </tr>
        </thead>
        <tbody>
          {props.data.map((item, index) => (
            <tr key={`list-item-${index}`} id={`auction-${item.id}`}>
              <td>{item.id}</td>
              <td>{item.statusTranslation}</td>
              <td className="auction-column-title">
                <Link to={pageState.getPagePath(routes.admin.AUCTIONS_UPDATE, { ':id': item.id })}>{item.title}</Link>
              </td>
              <td className="auction-column-title">{item.number}</td>
              <td className="text-center">{!!item.startDt ? getDateTimeFormat(item.startDt) : '-'}</td>
              <td className="text-center">{!!item.endDt ? getDateTimeFormat(item.endDt) : '-'}</td>
              <td className="text-center">{isNaN(item.numberOfJoinedUsers) ? '-' : item.numberOfJoinedUsers}</td>
              <td className="text-center">{isNaN(item.numberOfAdmittedUsers) ? '-' : item.numberOfAdmittedUsers}</td>
              <td className="text-center">
                {isNaN(item.numberOfAuctionSecurityPaid) ? '-' : item.numberOfAuctionSecurityPaid}
              </td>
              <td className="text-center">
                {item.state === 'active' ? (
                  <Form.Check
                    custom
                    type="checkbox"
                    className="mt-checkbox"
                    id={`auction-exportedToWeb-${index}`}
                    checked={item.exportedToWeb}
                    onChange={() => !isOfRoleSuperAdmin() && item.status !== AuctionStatus.prepared ? undefined : props.exportToWebToggle(item, 'exdrazby')}
                  />
                ) : (
                  <img className="close-ico" src={closeIco} alt="disabled" />
                )}
              </td>
              <td className="text-center">
                {item.state === 'active' && item.auctionType?.startsWith('executor_') ? (
                  <>
                    {item.exportedToPD ? (
                      <img src={icoCheckGreen} alt="icon" />
                    ) : (
                      <>
                        {item.parentStatus === 'prepared' ? (
                          <Form.Check
                            custom
                            type="checkbox"
                            className="mt-checkbox"
                            id={`auction-exportedToPD-${index}`}
                            checked={item.exportedToPD}
                            onChange={() => !isOfRoleSuperAdmin() && item.status !== AuctionStatus.prepared ? undefined : props.exportToPDToggle(item, 'portaldrazeb')}
                          />
                        ) : (
                          <img className="close-ico" src={icoTimesRed} alt="disabled" />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <img className="close-ico" src={closeIco} alt="disabled" />
                )}
              </td>
              <td className="text-center">
                <Link to={`${pageState.getPagePath(routes.admin.AUCTIONS_UPDATE, { ':id': item.id })}?section=5`}>
                  {renderAuctionExportSummary(item)}
                </Link>
              </td>
              <td className="text-center">
                <Form.Check
                  custom
                  type="checkbox"
                  className="mt-checkbox"
                  id={`auction-state-${index}`}
                  checked={item.state === 'active'}
                  onChange={() => !isOfRoleSuperAdmin() && item.status !== AuctionStatus.prepared ? undefined : props.stateToggle(item)}
                />
              </td>
              <td className="text-right">
                {item.state === 'active' ? (
                  <Link
                    target="_blank"
                    className={'d-inline-flex align-items-center'}
                    to={pageState.getPagePath(
                      props.type === 'auction' ? routes.front.AUCTION_DETAIL : routes.front.AUCTION_PUBLIC_DETAIL,
                      { ':id': item.hash }
                    )}
                  >
                    <OverlayTrigger
                      key="table-permission"
                      placement="top"
                      overlay={
                        <Tooltip id="table-permission">
                          <div>Zobrazit na webu</div>
                        </Tooltip>
                      }
                    >
                      <img src={colorIcoEye} alt="ico" />
                    </OverlayTrigger>
                  </Link>
                ) : (
                  <Link
                    target="_blank"
                    className={'d-inline-flex align-items-center disabled-action'}
                    to="/"
                    onClick={(event) => event.preventDefault()}
                  >
                    <OverlayTrigger
                      key="table-permission"
                      placement="top"
                      overlay={
                        <Tooltip id="table-permission">
                          <div>Zobrazit na webu</div>
                        </Tooltip>
                      }
                    >
                      <img src={colorIcoEye} alt="ico" />
                    </OverlayTrigger>
                  </Link>
                )}
                <Link
                  className={'d-inline-flex align-items-center ml-4'}
                  to={pageState.getPagePath(routes.admin.AUCTIONS_ACTIVE_DETAIL, { ':id': item.id })}
                >
                  <OverlayTrigger
                    key="table-permission"
                    placement="top"
                    overlay={
                      <Tooltip id="table-permission">
                        <div>{props.renderTranslation('table_body_state_tooltip')}</div>
                      </Tooltip>
                    }
                  >
                    <img src={colorIcoGavel} alt="ico" />
                  </OverlayTrigger>
                </Link>
                <Link
                  className="d-inline-flex align-items-center ml-4"
                  to={pageState.getPagePath(routes.admin.AUCTIONS_UPDATE, { ':id': item.id })}
                >
                  <OverlayTrigger
                    key="table-permission"
                    placement="top"
                    overlay={
                      <Tooltip id="table-permission">
                        <div>Upravit</div>
                      </Tooltip>
                    }
                  >
                    <img src={colorIcoConf} alt="ico" />
                  </OverlayTrigger>
                </Link>
              </td>
            </tr>
          ))}
          {props.data.length < 1 && (
            <tr>
              <td className="text-left" colSpan={100}>
                Nebyla nalezena žádná data.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ItemsTable;
