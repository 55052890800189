import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import { isArray } from 'lodash';
import { FormikErrors } from 'formik';
import { Form } from 'react-bootstrap';

import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';

import { useEnumApi } from '@api/enum';
import { useBuyerApi } from '@api/buyer';
import { BuyerFormValues, EnumType } from '@types';
import { getRequestDateFormat } from '@helpers/datetime';
import { ControlFeedback, DatePickerInput, FormGroup, Select } from '@components';

interface Props {
  noResponsive?: boolean;
  readOnly?: boolean;
  values: BuyerFormValues;
  errors: FormikErrors<BuyerFormValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

const PersonalDetails: React.FC<Props> = (props) => {
  const enumApi = useEnumApi();
  const buyerApi = useBuyerApi();
  const [citizenship, setCitizenship] = useState<EnumType[]>([]);

  const citizenshipOptions = citizenship.map((i) => ({ value: i.type, label: i.translated }));

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const res = await enumApi.getCitizenship();
      setCitizenship(res.data.data);
      if (!props.values.citizenship && res.data.data.length > 0) {
        handleCitizenChange({ value: res.data.data[0].type, label: res.data.data[0].translated });
      }
    } catch (err) {
      if (enumApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleBirthDateChange = (values: Date | Array<Date> | null) => {
    if (values !== null) {
      const value = isArray(values) ? values[0] : values;
      props.setFieldValue('birthDate', getRequestDateFormat(value));
    }
  };

  const handleCitizenChange = async (val: { value: string; label: string } | null) => {
    props.setFieldValue('citizenship', val?.value || '');
    if (val?.value !== 'other') {
      props.setFieldValue('country', val?.label);
      props.setFieldValue('invoiceCountry', val?.label);
      props.setFieldValue('deliveryCountry', val?.label);
    }
    if (val?.value === 'other') {
      try {
        const res = await buyerApi.getRandomIdentifier();
        props.setFieldValue('personalIdentificationNumber', res.data.data.personalIdentificationNumber);
      } catch (err) {
        if (buyerApi.isCancel(err)) {
          return;
        }
      }
    }
  };

  return (
    <div className="step-buyer-section">
      <div className={cx('section-title')}>Osobní údaje</div>
      <div className={cx({ 'responsive-table-content': !props.noResponsive })}>
        {props.values.type === 'legal' && (
          <FormGroup
            type="text"
            name="position"
            label="Pracovní pozice"
            readOnly={props.readOnly}
            value={props.values.position}
            error={_.get(props.errors, 'position')}
            onChange={props.handleChange}
            required
          />
        )}
        <FormGroup
          type="text"
          name="degreePre"
          label="Titul před"
          readOnly={props.readOnly}
          value={props.values.degreePre}
          onChange={props.handleChange}
        />
        <FormGroup
          type="text"
          name="firstName"
          label="Jméno"
          readOnly={props.readOnly}
          value={props.values.firstName}
          onChange={props.handleChange}
          error={props.errors.firstName || _.get(props.errors, 'firstName')}
          required
        />
        <FormGroup
          type="text"
          name="lastName"
          label="Příjmení"
          readOnly={props.readOnly}
          value={props.values.lastName}
          onChange={props.handleChange}
          error={props.errors.lastName || _.get(props.errors, 'lastName')}
          required
        />
        <FormGroup
          type="text"
          name="degreePost"
          label="Titul po"
          readOnly={props.readOnly}
          value={props.values.degreePost}
          onChange={props.handleChange}
        />
        {props.values.type === 'own_account_worker' && (
          <>
            <FormGroup
              type="text"
              name="ico"
              label="IČ"
              readOnly={props.readOnly}
              value={props.values.ico}
              error={props.errors.ico}
              onChange={props.handleChange}
              required
            />
            <FormGroup
              type="text"
              name="dic"
              label="DIČ"
              error={props.errors.dic}
              value={props.values.dic}
              readOnly={props.readOnly}
              onChange={props.handleChange}
            />
          </>
        )}

        <Form.Group className="f-inline-group">
          <Form.Label className="f-inline-label text-right">Státní občanství *</Form.Label>
          <div className="f-inline-control">
            <div className="w-max-500">
              <Select
                size="md"
                isDisabled={props.readOnly}
                options={citizenshipOptions}
                isInvalid={!!props.errors.citizenship}
                value={citizenshipOptions.find((i) => i.value === props.values.citizenship) || null}
                onChange={handleCitizenChange}
              />
              {!!props.errors.citizenship && (
                <ControlFeedback type="invalid">{props.errors.citizenship}</ControlFeedback>
              )}
            </div>
          </div>
        </Form.Group>

        <Form.Group className="f-inline-group">
          <Form.Label className="f-inline-label text-right">Datum narození *</Form.Label>
          <div className="f-inline-control d-flex align-items-center">
            <Form.Group className="mb-2 inline-label mr-3 input-ico input-ico-md">
              <div className="w-135 position-relative">
                <DatePickerInput
                  readOnly={props.readOnly}
                  className={cx(['form-control', { 'is-invalid': !!_.get(props.errors, 'birthdate') }])}
                  calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                  clearIcon={null}
                  onChange={handleBirthDateChange}
                  value={!!props.values.birthDate ? moment(props.values.birthDate || '').toDate() : undefined}
                />
                {!!_.get(props.errors, 'birthdate') && (
                  <ControlFeedback type="invalid" style={{ left: 0, bottom: -25 }}>
                    {_.get(props.errors, 'birthdate')}
                  </ControlFeedback>
                )}
              </div>
            </Form.Group>
          </div>
        </Form.Group>
        <FormGroup
          type="text"
          label="Rodné číslo"
          name="personalIdentificationNumber"
          readOnly={props.readOnly || props.values.citizenship === 'other'}
          error={props.errors.personalIdentificationNumber || _.get(props.errors, 'personalIdentificationNumber')}
          value={props.values.personalIdentificationNumber}
          onChange={props.handleChange}
          required
        />
        <FormGroup
          type="text"
          name="identityCardNumber"
          label="Číslo dokladu totožnosti"
          readOnly={props.readOnly}
          error={props.errors.identityCardNumber || _.get(props.errors, 'identityCardNumber')}
          value={props.values.identityCardNumber}
          onChange={props.handleChange}
        />
        <FormGroup
          type="text"
          name="eBox"
          label="Datová schránka"
          readOnly={props.readOnly}
          value={props.values.eBox}
          onChange={props.handleChange}
        />
        {props.values.type === 'joint_assets' && (
          <div style={{ minWidth: 750 }}>
            <FormGroup
              required
              type="number"
              name="share"
              label="Podíl"
              helpIcon="%"
              readOnly={props.readOnly}
              value={props.values.share}
              onChange={props.handleChange}
              error={props.errors.share}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonalDetails;
