import React from 'react';
import { Link } from 'react-router-dom';

import * as routes from '@routes';
import { usePageStore } from '@stores';

import icoLock from '@assets/images/front/header/color-ico-lock.svg';
import icoRegistration from '@assets/images/front/header/color-ico-registration.svg';

interface Props {
  onLoginClick: () => void;
}

const GuestItems: React.FC<Props> = (props) => {
  const pageState = usePageStore();

  const handleLoginClick = (e: React.MouseEvent) => {
    e.preventDefault();
    props.onLoginClick();
  };

  return (
    <>
      <Link to={pageState.getPagePath(routes.front.REGISTRATION)} className="header-item">
        <img src={icoRegistration} alt="ico" />
        <span>Registrace</span>
      </Link>
      <a href="/" onClick={handleLoginClick} className="header-item">
        <img src={icoLock} alt="ico" />
        <span>Přihlášení</span>
      </a>
    </>
  );
};

export default GuestItems;
