import React from 'react';
import _, { get, set } from 'lodash';
import moment from 'moment';
import { useFormik } from 'formik';
import classNames from 'classnames';
import fileDownload from 'js-file-download';
import { Link, useHistory, useParams } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import * as routes from '@routes';
import { useConcept } from './concept';
import { useAuctionsApi } from '@api/auctions';
import { useTranslatorApi } from '@api/translator';
import { useAuctioneersApi } from '@api/auctioneers';
import { useAuctionInformationApi } from '@api/auctionInformation';
import { useInformedConsentApi } from '@api/informedConsent';
import { usePageStore, useUrlSearchStore } from '@stores';
import { getRequestDateTimeFormat } from '@helpers/datetime';
import { useAuctionCategoriesApi } from '@api/auctionCategories';
import { AdminBoxContent, AdminProgressFormMenu, BasePage, BasePreloader, Button } from '@components';
import {
  AdminProgressFormMenuItemType,
  Auctioneer,
  AuctionFieldsEnabled,
  AuctionInformedConsent,
  AuctionResponse,
  BaseObjectType,
  HistoryAuthorType,
} from '@types';

import Documents from './components/Documents';
import AuctionSection from './components/AuctionSection';
import SubjectSection from './components/SubjectSection';
import DetailsSection from './components/DetailsSection';
import AuctionActions from './components/AuctionActions';
import LocationSection from './components/LocationSection';
import ReceiptsSection from './components/ReceiptsSection';
import AuctionExport from './components/AuctionExport';
import SettingsSection from './components/SettingsSection';
import AuctionHistory from './components/AuctionHistory';
import InformationSection from './components/InformationSection';
import { useCopyAttachments } from './copyAttachments';

import eyeColorIco from '@assets/images/color-ico-eye.svg';
import icoAuctioneer from '@assets/images/color-ico-gavel.svg';
import icoDocument from '@assets/images/color-ico-document.svg';
import auctioneerIcoActive from '@assets/images/admin-sidebar/auctioneer-active-small.svg';

const auctionParameterTypes = [
  {
    type: 'real_estate_house',
    value: 'auctionParameterRealEstateHouse',
  },
  {
    type: 'real_estate_flat',
    value: 'auctionParameterRealEstateFlat',
  },
  {
    type: 'real_estate_land',
    value: 'auctionParameterRealEstateLand',
  },
  {
    type: 'real_estate_commercial',
    value: 'auctionParameterRealEstateCommercial',
  },
  {
    type: 'real_estate_other',
    value: 'auctionParameterRealEstateOther',
  },
  {
    type: 'vehicle_car',
    value: 'auctionParameterVehicleCar',
  },
  {
    type: 'vehicle_motorcycle',
    value: 'auctionParameterVehicleMotorcycle',
  },
  {
    type: 'vehicle_other',
    value: 'auctionParameterVehicleOther',
  },
];

export enum AuctionSections {
  auction,
  subject,
  details,
  location,
  attachments,
  export,
  receipts,
  settings,
  history,
  information,
}

interface ParamsType {
  id: string;
}

let auctionTimeout: NodeJS.Timer | undefined = undefined;

const AuctionsUpdatePage: React.FC = () => {
  const translationsApi = useTranslatorApi();
  const informedConsentsApi = useInformedConsentApi(false, true);
  const auctionCategoriesApi = useAuctionCategoriesApi();
  const auctionInformationApi = useAuctionInformationApi();
  const { id } = useParams<ParamsType>();
  const history = useHistory();
  const pageState = usePageStore();
  const [fieldsEnabled, setFieldsEnabled] = React.useState<AuctionFieldsEnabled | undefined>();
  const [isTranslationsLoaded, setIsTranslationsLoaded] = React.useState(false);
  const [domainTranslations, setDomainTranslations] = React.useState<BaseObjectType<string>>({});
  const urlSearchState = useUrlSearchStore();
  const [showActions, setShowActions] = React.useState<boolean>(true);
  const [author, setAuthor] = React.useState<HistoryAuthorType | undefined>(undefined);
  const [currentStatus, setCurrentStatus] = React.useState('');
  const [dateCreated, setDateCreated] = React.useState<string | undefined>(undefined);
  const [errorFields, setErrorFields] = React.useState<string[]>([]);
  const { copyAttachments, isCopying, copyStatus } = useCopyAttachments();
  const [query, setQuery] = useQueryParams({
    back: withDefault(StringParam, undefined),
    section: withDefault(NumberParam, AuctionSections.auction),
    copyFrom: withDefault(StringParam, null),
    concept: withDefault(StringParam, undefined),
    type: withDefault(StringParam, undefined),
    auctioneerId: withDefault(StringParam, null),
  });
  const [loaded, setLoaded] = React.useState(id === undefined);
  const [notFound, setNotFound] = React.useState(false);
  const auctionsApi = useAuctionsApi(!id && query.section < AuctionSections.location);
  const auctioneersApi = useAuctioneersApi();
  const [copiedFromId, setCopiedFromId] = React.useState<string | null>(null);
  const [templateId, setTemplateId] = React.useState<string | null>(null);
  const [currentCategoryType, setCurrentCategoryType] = React.useState<string>('');
  const [currentAuctioneer, setCurrentAuctioneer] = React.useState<Auctioneer | undefined>(undefined);
  const [mainCategory, setMainCategory] = React.useState(false);
  const [isEnded, setIsEnded] = React.useState(false);
  const [validSections, setValidSections] = React.useState({
    [AuctionSections.auction]: !!id,
    [AuctionSections.subject]: !!id,
    [AuctionSections.details]: !!id,
    [AuctionSections.location]: !!id,
    [AuctionSections.attachments]: !!id,
    [AuctionSections.export]: !!id,
    [AuctionSections.receipts]: !!id,
    [AuctionSections.settings]: !!id,
    [AuctionSections.history]: !!id,
    [AuctionSections.information]: !!id,
  });
  let filteredAuctionParameterTypes = [];
  const initialFormValues = {
    auctioneer: '',
    state: '',
    status: '',
    statusTranslation: null as string | null,
    startDt: '',
    startDtHour: 0,
    startDtMinutes: 0,
    endDt: '',
    endDtHour: 0,
    endDtMinutes: 0,
    novel: true,
    canProtest: false,
    auctionType: query.type === 'auction' ? 'auction' : '',
    _auctionCategory: {},
    auctionCategory: '',
    auctionRootCategory: '',
    auctionRootCategoryMachineName: '',
    title: '',
    generateTitle: false,
    hash: '',
    urlOffice: '',
    number: '',
    numberPrefix: '',
    numberSuffix: '',
    minimalBid: '',
    minimalThrow: '',
    minPurchasePrice: '',
    estimatedPrice: '',
    cautionDeposit: '',
    placeAuctionDescription: 'exdrazby.cz',
    description: '',
    printDescription: '',
    webUrl: '',
    part: '',
    visitationDescription: '',
    internalDescription: '',
    auctionMedia: [] as any,
    auctioneerAddress: 0,
    addressLatitude: 0,
    addressLongitude: 0,
    addressCadastralArea: '',
    addressCountry: '',
    _addressRegion: {},
    addressRegion: '',
    _addressDistrict: {},
    addressDistrict: '',
    addressCity: '',
    addressZipCode: '',
    addressStreet: '',
    addressLandNumber: '',
    addressHouseNumber: '',
    addressRuian: '',
    addressGps: '',
    auctionAuthorizationsType: [] as string[],
    auctionParameterRealEstateHouse: {},
    auctionParameterRealEstateFlat: {},
    auctionParameterRealEstateLand: {},
    auctionParameterRealEstateCommercial: {},
    auctionParameterRealEstateOther: {},
    auctionParameterVehicleCar: {},
    auctionParameterVehicleMotorcycle: {},
    auctionParameterVehicleOther: {},
    auctionCautionCash: { enabled: false },
    auctionCautionBankGuarantee: { enabled: false },
    auctionCautionBankAccount: { enabled: false, buyerIdentifier: 'vs' },
    contactPerson: '',
    repeated: false,
    overcharge: true,
  };
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: initialFormValues,
    onSubmit: () => handleSubmit(),
  });
  const { isConceptLoaded, conceptData } = useConcept(
    formik.values,
    formik.setValues,
    validSections,
    setValidSections,
    setCurrentCategoryType,
    id
  );

  React.useEffect(() => {
    if (!!id) {
      setLoaded(false);
      loadDetail();
    } else {
      setFieldsEnabled(undefined);
    }
    Object.getOwnPropertyNames(validSections).map((prop) => set(validSections, prop, !!id));
    setValidSections(validSections);
    return () => {
      auctionsApi.cancelAllRequests();
      if (!!auctionTimeout) {
        clearTimeout(auctionTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    setIsTranslationsLoaded(false);
    if (!id) {
      loadTranslations(query.type === 'public' ? 'admin_auction_public_form' : 'admin_auction_form');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.type]);

  React.useEffect(() => {
    copyAuction(query.copyFrom);
    setFieldsEnabled(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.copyFrom]);

  React.useEffect(() => {
    if (templateId && formik.values.auctioneer) copyTemplate(templateId, formik.values.auctioneer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  React.useEffect(() => {
    if (!!id && loaded && !!auctionTimeout) {
      clearTimeout(auctionTimeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.status]);

  const loadTranslations = async (domain: string) => {
    try {
      setDomainTranslations((await translationsApi.domainTranslations(domain)).data.data);
      setIsTranslationsLoaded(true);
    } catch (err) {
      if (translationsApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const loadDetail = async (isTimeout?: boolean) => {
    if (!!auctionTimeout) {
      clearTimeout(auctionTimeout);
    }
    try {
      const response = await auctionsApi.getAuction(id);
      const data = response.data.data;
      setIsEnded(data.isEnded || data.status === 'ongoing');
      setCurrentStatus(data.status);
      setFieldsEnabled(response.data.fieldsEnabled);
      loadTranslations(data.auctionType === 'auction' ? 'admin_auction_form' : 'admin_auction_public_form');
      setAuthor(
        data.createdUserId && data.createdUserFullName
          ? {
              id: parseInt(data.createdUserId),
              email: data.createdUserFullName,
            }
          : undefined
      );
      setDateCreated(data.timeCreated);
      if (isTimeout) {
        formik.setFieldValue('status', data.status || '');
        auctionTimeout = setTimeout(() => loadDetail(true), 10000);
        return;
      } else {
        setQuery(
          {
            ...query,
            type: data.auctionType === 'auction' ? 'auction' : 'public',
            back: query.back,
          },
          'replace'
        );
      }
      setCurrentAuctioneer(response.data.data.auctioneer);
      setCurrentCategoryType(data.auctionCategory.auctionParameterType);
      formik.setValues({
        auctioneer: !!data.auctioneer ? data.auctioneer.id.toString() : '',
        state: data.state || '',
        status: data.status || '',
        statusTranslation: data.statusTranslation || '',
        startDt: !!data.startDt ? moment(data.startDt).format('YYYY-MM-DD') : '',
        startDtHour: !!data.startDt ? parseInt(moment(data.startDt).format('H')) : 0,
        startDtMinutes: !!data.startDt ? parseInt(moment(data.startDt).format('m')) : 0,
        endDt: !!data.endDt ? moment(data.endDt).format('YYYY-MM-DD') : '',
        endDtHour: !!data.endDt ? parseInt(moment(data.endDt).format('H')) : 0,
        endDtMinutes: !!data.endDt ? parseInt(moment(data.endDt).format('m')) : 0,
        novel: data.afterNovel,
        canProtest: data.canProtest,
        auctionType: data.auctionType || '',
        _auctionCategory: data.auctionCategory,
        auctionCategory: data.auctionCategory.id.toString(),
        auctionRootCategory: data.auctionCategory?.parentId?.toString() || data.auctionCategory.id.toString(),
        auctionRootCategoryMachineName: data.auctionCategory?.parentMachineName || '',
        title: data.title || '',
        generateTitle: data.generateTitle || false,
        part: data.part || '',
        hash: data.hash || '',
        urlOffice: data.urlOffice || '',
        number: data.number || '',
        numberPrefix: data.numberPrefix || '',
        numberSuffix: data.numberSuffix || '',
        minimalBid: data.minimalBid || '',
        minimalThrow: data.minimalThrow || '',
        minPurchasePrice: data.minPurchasePrice || '',
        estimatedPrice: data.estimatedPrice || '',
        cautionDeposit: data.cautionDeposit || '',
        placeAuctionDescription: data.placeAuctionDescription || '',
        description: data.description || '',
        printDescription: data.printDescription || '',
        webUrl: data.webUrl || '',
        visitationDescription: data.visitationDescription || '',
        internalDescription: data.internalDescription || '',
        auctionMedia: data.auctionMedia || [],
        auctioneerAddress: 0,
        addressLatitude: data.auctionAddress?.latitude || 0,
        addressLongitude: data.auctionAddress?.longitude || 0,
        addressCadastralArea: data.auctionAddress?.cadastralArea || '',
        addressCountry: get(data, 'auctionAddress.country.id') || '',
        _addressRegion: get(data, 'auctionAddress.region') || '',
        addressRegion: get(data, 'auctionAddress.region.id') || '',
        _addressDistrict: get(data, 'auctionAddress.district') || '',
        addressDistrict: get(data, 'auctionAddress.district.id') || '',
        addressCity: data.auctionAddress?.city || '',
        addressZipCode: data.auctionAddress?.zipCode || '',
        addressStreet: data.auctionAddress?.street || '',
        addressLandNumber: data.auctionAddress?.landNumber || '',
        addressHouseNumber: data.auctionAddress?.houseNumber || '',
        addressRuian: data.auctionAddress?.ruian || '',
        addressGps: `${data.auctionAddress?.latitude || 0},${data.auctionAddress?.longitude || 0}`,
        auctionAuthorizationsType: !!data.auctionAuthorizationsType
          ? data.auctionAuthorizationsType.map((i) => i.type)
          : [],
        auctionParameterRealEstateHouse: data.auctionParameterRealEstateHouse || {},
        auctionParameterRealEstateFlat: data.auctionParameterRealEstateFlat || {},
        auctionParameterRealEstateLand: data.auctionParameterRealEstateLand || {},
        auctionParameterRealEstateCommercial: data.auctionParameterRealEstateCommercial || {},
        auctionParameterRealEstateOther: data.auctionParameterRealEstateOther || {},
        auctionParameterVehicleCar: data.auctionParameterVehicleCar
          ? {
              ...data.auctionParameterVehicleCar,
              parameterVehicleCategory: data.auctionParameterVehicleCar.parameterVehicleCategory.id,
            }
          : {},
        auctionParameterVehicleMotorcycle: data.auctionParameterVehicleMotorcycle
          ? {
              ...data.auctionParameterVehicleMotorcycle,
              parameterVehicleCategory: data.auctionParameterVehicleMotorcycle.parameterVehicleCategory.id,
            }
          : {},
        auctionParameterVehicleOther: data.auctionParameterVehicleOther
          ? {
              ...data.auctionParameterVehicleOther,
              parameterVehicleCategory: data.auctionParameterVehicleOther.parameterVehicleCategory.id,
            }
          : {},
        auctionCautionCash: data.auctionCautionCash || { enabled: false },
        auctionCautionBankAccount: data.auctionCautionBankAccount || { enabled: false, buyerIdentifier: 'vs' },
        auctionCautionBankGuarantee: data.auctionCautionBankGuarantee || { enabled: false },
        contactPerson: !!data.contactPerson ? data.contactPerson.id.toString() : '',
        repeated: data.repeated || false,
        overcharge: data.overcharge || true,
      });

      if (!!data.auctionParameterRealEstateLand?.coordinateX && !!data.auctionParameterRealEstateLand?.coordinateY) {
        formik.setFieldValue(
          'auctionParameterRealEstateLand.gps',
          `${data.auctionParameterRealEstateLand?.coordinateX},${data.auctionParameterRealEstateLand?.coordinateY}`
        );
      }
      setLoaded(true);
      setNotFound(false);

      auctionTimeout = setTimeout(() => loadDetail(true), 10000);
    } catch (err) {
      if (!err.response) {
        return;
      }

      if (err.response.status === 404 || err.response.status === 403) {
        setLoaded(true);
        setNotFound(true);
      }
    }
  };

  const copyTemplate = async (templateId: any, auctioneerId: any) => {
    if (templateId && auctioneerId) {
      setLoaded(false);
      try {
        const response = await auctioneersApi.detailTemplate(auctioneerId, templateId);
        const { data, auctioneer, auctionType } = response.data.data;
        const values = JSON.parse(data);
        formik.setValues({
          auctioneer: !!auctioneer ? auctioneer.id.toString() : '',
          state: '',
          status: 'prepared',
          statusTranslation: '',
          startDt: '',
          startDtHour: 0,
          startDtMinutes: 0,
          endDt: '',
          endDtHour: 0,
          endDtMinutes: 0,
          novel: true,
          canProtest: false,
          auctionType: auctionType === 'auction' ? 'auction' : '',
          _auctionCategory: {},
          auctionCategory: '',
          auctionRootCategory: '',
          auctionRootCategoryMachineName: '',
          title: '',
          generateTitle: false,
          hash: '',
          urlOffice: '',
          number: '',
          numberPrefix: '',
          numberSuffix: '',
          minimalBid: '',
          minimalThrow: '',
          minPurchasePrice: '',
          estimatedPrice: '',
          cautionDeposit: '',
          placeAuctionDescription: 'exdrazby.cz',
          description: '',
          printDescription: '',
          webUrl: '',
          part: '',
          visitationDescription: '',
          internalDescription: '',
          auctionMedia: [] as any,
          auctioneerAddress: 0,
          addressLatitude: 0,
          addressLongitude: 0,
          addressCadastralArea: values.addressCadastralArea || '',
          addressCountry: values.addressCountry || '',
          _addressRegion: {},
          addressRegion: values.addressRegion || '',
          _addressDistrict: {},
          addressDistrict: values.addressDistrict || '',
          addressCity: values.addressCity || '',
          addressZipCode: values.addressZipCode || '',
          addressStreet: values.addressStreet || '',
          addressLandNumber: values.addressLandNumber || '',
          addressHouseNumber: values.addressHouseNumber || '',
          addressRuian: values.addressRuian || '',
          addressGps: values.addressGps || '',
          auctionAuthorizationsType: values.auctionAuthorizationsType || ([] as string[]),
          auctionParameterRealEstateHouse: {},
          auctionParameterRealEstateFlat: {},
          auctionParameterRealEstateLand: {},
          auctionParameterRealEstateCommercial: {},
          auctionParameterRealEstateOther: {},
          auctionParameterVehicleCar: {},
          auctionParameterVehicleMotorcycle: {},
          auctionParameterVehicleOther: {},
          auctionCautionCash: values.auctionCautionCash || { enabled: false },
          auctionCautionBankGuarantee: values.auctionCautionBankGuarantee || { enabled: false },
          auctionCautionBankAccount: values.auctionCautionBankAccount || { enabled: false, buyerIdentifier: 'vs' },
          contactPerson: '',
          repeated: false,
          overcharge: true,
        });
        setLoaded(true);
      } catch (err) {
        if (!err.response) {
          return;
        }
      }
    }
  };

  const copyAuction = async (auctionId: any) => {
    if (auctionId && copiedFromId !== auctionId) {
      setLoaded(false);
      try {
        const response = await auctionsApi.getAuction(auctionId);
        const data = response.data.data;

        setCurrentCategoryType(data.auctionCategory.auctionParameterType);
        formik.setValues({
          auctioneer: !!data.auctioneer ? data.auctioneer.id.toString() : '',
          state: data.state || '',
          status: 'prepared',
          statusTranslation: data.statusTranslation || '',
          startDt: '',
          startDtHour: 0,
          startDtMinutes: 0,
          endDt: '',
          endDtHour: 0,
          endDtMinutes: 0,
          novel: data.afterNovel,
          canProtest: data.canProtest,
          auctionType: data.auctionType || '',
          _auctionCategory: data.auctionCategory,
          auctionCategory: data.auctionCategory.id.toString(),
          auctionRootCategory: data.auctionCategory?.parentId?.toString() || data.auctionCategory.id.toString(),
          auctionRootCategoryMachineName: data.auctionCategory?.parentMachineName || '',
          title: data.rawTitle || '',
          generateTitle: data.generateTitle || false,
          part: data.part || '',
          hash: data.hash || '',
          urlOffice: data.urlOffice || '',
          number: '',
          numberPrefix: data.numberPrefix || '',
          numberSuffix: data.repeated ? data.numberSuffix : '',
          minimalBid: data.minimalBid || '',
          minimalThrow: data.minimalThrow || '',
          minPurchasePrice: data.minPurchasePrice || '',
          estimatedPrice: data.estimatedPrice || '',
          cautionDeposit: data.cautionDeposit || '',
          placeAuctionDescription: data.placeAuctionDescription || '',
          description: data.description || '',
          printDescription: data.printDescription || '',
          webUrl: data.webUrl || '',
          visitationDescription: data.visitationDescription || '',
          internalDescription: data.internalDescription || '',
          auctionMedia: data.auctionMedia || [],
          auctioneerAddress: 0,
          addressLatitude: data.auctionAddress?.latitude || 0,
          addressLongitude: data.auctionAddress?.longitude || 0,
          addressCadastralArea: data.auctionAddress?.cadastralArea || '',
          addressCountry: get(data, 'auctionAddress.country.id') || '',
          _addressRegion: get(data, 'auctionAddress.region') || '',
          addressRegion: get(data, 'auctionAddress.region.id') || '',
          _addressDistrict: get(data, 'auctionAddress.district') || '',
          addressDistrict: get(data, 'auctionAddress.district.id') || '',
          addressCity: data.auctionAddress?.city || '',
          addressZipCode: data.auctionAddress?.zipCode || '',
          addressStreet: data.auctionAddress?.street || '',
          addressLandNumber: data.auctionAddress?.landNumber || '',
          addressHouseNumber: data.auctionAddress?.houseNumber || '',
          addressRuian: data.auctionAddress?.ruian || '',
          addressGps: `${data.auctionAddress?.latitude || 0},${data.auctionAddress?.longitude || 0}`,
          auctionAuthorizationsType: !!data.auctionAuthorizationsType
            ? data.auctionAuthorizationsType.map((i) => i.type)
            : [],
          auctionParameterRealEstateHouse: data.auctionParameterRealEstateHouse
            ? {
                ...data.auctionParameterRealEstateHouse,
                auctionParameterRealEstateHouseHeatings: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateHouse',
                  'auctionParameterRealEstateHouseHeatings',
                  'parameterRealEstateHeating'
                ),
                auctionParameterRealEstateHouseWaters: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateHouse',
                  'auctionParameterRealEstateHouseWaters',
                  'parameterRealEstateWater'
                ),
                auctionParameterRealEstateHouseElectricities: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateHouse',
                  'auctionParameterRealEstateHouseElectricities',
                  'parameterRealEstateElectricity'
                ),
                auctionParameterRealEstateHouseWastes: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateHouse',
                  'auctionParameterRealEstateHouseWastes',
                  'parameterRealEstateWaste'
                ),
                auctionParameterRealEstateHouseGases: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateHouse',
                  'auctionParameterRealEstateHouseGases',
                  'parameterRealEstateGas'
                ),
                auctionParameterRealEstateHouseTransports: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateHouse',
                  'auctionParameterRealEstateHouseTransports',
                  'parameterRealEstateTransport'
                ),
                auctionParameterRealEstateHouseRoadTypes: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateHouse',
                  'auctionParameterRealEstateHouseRoadTypes',
                  'parameterRealEstateRoadType'
                ),
                auctionParameterRealEstateHouseTelecommunications: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateHouse',
                  'auctionParameterRealEstateHouseTelecommunications',
                  'parameterRealEstateTelecommunication'
                ),
              }
            : {},
          auctionParameterRealEstateFlat: data.auctionParameterRealEstateFlat
            ? {
                ...data.auctionParameterRealEstateFlat,
                auctionParameterRealEstateFlatHeatings: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateFlat',
                  'auctionParameterRealEstateFlatHeatings',
                  'parameterRealEstateHeating'
                ),
                auctionParameterRealEstateFlatGases: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateFlat',
                  'auctionParameterRealEstateFlatGases',
                  'parameterRealEstateGas'
                ),
                auctionParameterRealEstateFlatElectricities: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateFlat',
                  'auctionParameterRealEstateFlatElectricities',
                  'parameterRealEstateElectricity'
                ),
                auctionParameterRealEstateFlatRoadTypes: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateFlat',
                  'auctionParameterRealEstateFlatRoadTypes',
                  'parameterRealEstateRoadType'
                ),
                auctionParameterRealEstateFlatTransports: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateFlat',
                  'auctionParameterRealEstateFlatTransports',
                  'parameterRealEstateTransport'
                ),
                auctionParameterRealEstateFlatWastes: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateFlat',
                  'auctionParameterRealEstateFlatWastes',
                  'parameterRealEstateWaste'
                ),
                auctionParameterRealEstateFlatWaters: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateFlat',
                  'auctionParameterRealEstateFlatWaters',
                  'parameterRealEstateWater'
                ),
                auctionParameterRealEstateFlatTelecommunications: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateFlat',
                  'auctionParameterRealEstateFlatTelecommunications',
                  'parameterRealEstateTelecommunication'
                ),
              }
            : {},
          auctionParameterRealEstateLand: data.auctionParameterRealEstateLand
            ? {
                ...data.auctionParameterRealEstateLand,
                auctionParameterRealEstateLandWaters: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateLand',
                  'auctionParameterRealEstateLandWaters',
                  'parameterRealEstateWater'
                ),
                auctionParameterRealEstateLandElectricities: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateLand',
                  'auctionParameterRealEstateLandElectricities',
                  'parameterRealEstateElectricity'
                ),
                auctionParameterRealEstateLandWastes: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateLand',
                  'auctionParameterRealEstateLandWastes',
                  'parameterRealEstateWaste'
                ),
                auctionParameterRealEstateLandGases: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateLand',
                  'auctionParameterRealEstateLandGases',
                  'parameterRealEstateGas'
                ),
                auctionParameterRealEstateLandRoadTypes: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateLand',
                  'auctionParameterRealEstateLandRoadTypes',
                  'parameterRealEstateRoadType'
                ),
                auctionParameterRealEstateLandHeatings: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateLand',
                  'auctionParameterRealEstateLandHeatings',
                  'parameterRealEstateHeating'
                ),
                auctionParameterRealEstateLandTransports: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateLand',
                  'auctionParameterRealEstateLandTransports',
                  'parameterRealEstateTransport'
                ),
                auctionParameterRealEstateLandTelecommunications: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateLand',
                  'auctionParameterRealEstateLandTelecommunications',
                  'parameterRealEstateTelecommunication'
                ),
              }
            : {},
          auctionParameterRealEstateCommercial: data.auctionParameterRealEstateCommercial
            ? {
                ...data.auctionParameterRealEstateCommercial,
                auctionParameterRealEstateCommercialHeatings: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateCommercial',
                  'auctionParameterRealEstateCommercialHeatings',
                  'parameterRealEstateHeating'
                ),
                auctionParameterRealEstateCommercialWaters: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateCommercial',
                  'auctionParameterRealEstateCommercialWaters',
                  'parameterRealEstateWater'
                ),
                auctionParameterRealEstateCommercialElectricities: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateCommercial',
                  'auctionParameterRealEstateCommercialElectricities',
                  'parameterRealEstateElectricity'
                ),
                auctionParameterRealEstateCommercialWastes: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateCommercial',
                  'auctionParameterRealEstateCommercialWastes',
                  'parameterRealEstateWaste'
                ),
                auctionParameterRealEstateCommercialGases: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateCommercial',
                  'auctionParameterRealEstateCommercialGases',
                  'parameterRealEstateGas'
                ),
                auctionParameterRealEstateCommercialTransports: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateCommercial',
                  'auctionParameterRealEstateCommercialTransports',
                  'parameterRealEstateTransport'
                ),
                auctionParameterRealEstateCommercialRoadTypes: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateCommercial',
                  'auctionParameterRealEstateCommercialRoadTypes',
                  'parameterRealEstateRoadType'
                ),
                auctionParameterRealEstateCommercialTelecommunications: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateCommercial',
                  'auctionParameterRealEstateCommercialTelecommunications',
                  'parameterRealEstateTelecommunication'
                ),
              }
            : {},
          auctionParameterRealEstateOther: data.auctionParameterRealEstateOther
            ? {
                ...data.auctionParameterRealEstateOther,
                auctionParameterRealEstateOtherHeatings: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateOther',
                  'auctionParameterRealEstateOtherHeatings',
                  'parameterRealEstateHeating'
                ),
                auctionParameterRealEstateOtherWaters: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateOther',
                  'auctionParameterRealEstateOtherWaters',
                  'parameterRealEstateWater'
                ),
                auctionParameterRealEstateOtherElectricities: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateOther',
                  'auctionParameterRealEstateOtherElectricities',
                  'parameterRealEstateElectricity'
                ),
                auctionParameterRealEstateOtherWastes: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateOther',
                  'auctionParameterRealEstateOtherWastes',
                  'parameterRealEstateWaste'
                ),
                auctionParameterRealEstateOtherGases: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateOther',
                  'auctionParameterRealEstateOtherGases',
                  'parameterRealEstateGas'
                ),
                auctionParameterRealEstateOtherTransports: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateOther',
                  'auctionParameterRealEstateOtherTransports',
                  'parameterRealEstateTransport'
                ),
                auctionParameterRealEstateOtherRoadTypes: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateOther',
                  'auctionParameterRealEstateOtherRoadTypes',
                  'parameterRealEstateRoadType'
                ),
                auctionParameterRealEstateOtherTelecommunications: getCopyMultiSelectParameterValue(
                  data,
                  'auctionParameterRealEstateOther',
                  'auctionParameterRealEstateOtherTelecommunications',
                  'parameterRealEstateTelecommunication'
                ),
              }
            : {},
          auctionParameterVehicleCar: data.auctionParameterVehicleCar
            ? {
                ...data.auctionParameterVehicleCar,
                parameterVehicleCategory: data.auctionParameterVehicleCar.parameterVehicleCategory.id,
              }
            : {},
          auctionParameterVehicleMotorcycle: data.auctionParameterVehicleMotorcycle
            ? {
                ...data.auctionParameterVehicleMotorcycle,
                parameterVehicleCategory: data.auctionParameterVehicleMotorcycle.parameterVehicleCategory.id,
              }
            : {},
          auctionParameterVehicleOther: data.auctionParameterVehicleOther
            ? {
                ...data.auctionParameterVehicleOther,
                parameterVehicleCategory: data.auctionParameterVehicleOther.parameterVehicleCategory.id,
              }
            : {},
          auctionCautionCash: data.auctionCautionCash,
          auctionCautionBankGuarantee: data.auctionCautionBankGuarantee,
          auctionCautionBankAccount: data.auctionCautionBankAccount,
          contactPerson: !!data.contactPerson ? data.contactPerson.id.toString() : '',
          repeated: data.repeated || false,
          overcharge: data.overcharge || true,
        });

        if (!!data.auctionParameterRealEstateLand?.coordinateX && !!data.auctionParameterRealEstateLand?.coordinateY) {
          formik.setFieldValue(
            'auctionParameterRealEstateLand.gps',
            `${data.auctionParameterRealEstateLand?.coordinateX},${data.auctionParameterRealEstateLand?.coordinateY}`
          );
        }
        setLoaded(true);
        setCopiedFromId(auctionId);
      } catch (err) {
        if (!err.response) {
          return;
        }
      }
    }
  };

  const handleSubmit = async () => {
    if (!!auctionTimeout) {
      clearTimeout(auctionTimeout);
    }

    const inputs = { ...formik.values };
    try {
      const [addressLatitude, addressLongitude] = inputs.addressGps.trim().split(',');

      const categoriesResponse = await auctionCategoriesApi.getCategories(formik.values.auctionRootCategory);
      const [activeCategory] = categoriesResponse.data.data.filter(
        (category) => `${category.id}` === inputs.auctionCategory
      );

      const auctionParameterType = activeCategory?.auctionParameterType;

      // Fix start and end date
      let startDtHour = inputs.startDtHour.toString();
      if (parseInt(startDtHour) < 10) {
        startDtHour = `0${inputs.startDtHour}`;
      }
      let startDtMinutes = inputs.startDtMinutes.toString();
      if (parseInt(startDtMinutes) < 10) {
        startDtMinutes = `0${inputs.startDtMinutes}`;
      }
      let endDtHour = inputs.endDtHour.toString();
      if (parseInt(endDtHour) < 10) {
        endDtHour = `0${inputs.endDtHour}`;
      }
      let endDtMinutes = inputs.endDtMinutes.toString();
      if (parseInt(endDtMinutes) < 10) {
        endDtMinutes = `0${inputs.endDtMinutes}`;
      }

      const data = {
        auctioneer: formik.values.auctioneer,
        numberPrefix: formik.values.numberPrefix,
        numberSuffix: formik.values.numberSuffix,
        startDt: !!inputs.startDt ? getRequestDateTimeFormat(`${inputs.startDt} ${startDtHour}:${startDtMinutes}`) : '',
        endDt: !!inputs.endDt ? getRequestDateTimeFormat(`${inputs.endDt} ${endDtHour}:${endDtMinutes}`) : '',
        afterNovel:
          (inputs.auctionType === 'executor_auction_voluntary' ||
            inputs.auctionType === 'executor_auction_involuntary') &&
          inputs.novel,
        canProtest: inputs.canProtest,
        status: inputs.status,
        auctionType: inputs.auctionType,
        auctionCategory: mainCategory ? inputs.auctionRootCategory : inputs.auctionCategory,
        title: inputs.title,
        generateTitle: inputs.generateTitle || false,
        part: inputs.part || '',
        urlOffice: inputs.urlOffice,
        minimalBid: inputs.minimalBid,
        minimalThrow: inputs.minimalThrow,
        minPurchasePrice: inputs.minPurchasePrice,
        estimatedPrice: inputs.estimatedPrice,
        cautionDeposit: inputs.cautionDeposit,
        placeAuctionDescription: inputs.placeAuctionDescription,
        description: inputs.description,
        printDescription: inputs.printDescription,
        webUrl: inputs.webUrl || '',
        internalDescription: inputs.internalDescription,
        visitationDescription: inputs.visitationDescription,
        auctionAddress: {
          latitude: addressLatitude,
          longitude: addressLongitude,
          cadastralArea: inputs.addressCadastralArea,
          country: inputs.addressCountry,
          region: inputs.addressRegion,
          district: inputs.addressDistrict,
          city: inputs.addressCity,
          zipCode: inputs.addressZipCode,
          street: inputs.addressStreet,
          houseNumber: inputs.addressHouseNumber,
          ruian: inputs.addressRuian,
          landNumber: inputs.addressLandNumber,
        },
        auctionAuthorizationsType: inputs.auctionAuthorizationsType,
        auctionParameterRealEstateHouse: inputs.auctionParameterRealEstateHouse
          ? {
              ...inputs.auctionParameterRealEstateHouse,
              balconyArea: getParameterCheckboxValue('auctionParameterRealEstateHouse', 'balcony', 'balconyArea'),
              loggiaArea: getParameterCheckboxValue('auctionParameterRealEstateHouse', 'loggia', 'loggiaArea'),
              mortgagePercent: getParameterCheckboxValue(
                'auctionParameterRealEstateHouse',
                'mortgage',
                'mortgagePercent'
              ),
              basinArea: getParameterCheckboxValue('auctionParameterRealEstateHouse', 'basin', 'basinArea'),
              cellarArea: getParameterCheckboxValue('auctionParameterRealEstateHouse', 'cellar', 'cellarArea'),
              terraceArea: getParameterCheckboxValue('auctionParameterRealEstateHouse', 'terrace', 'terraceArea'),
              parameterRealEstateParking: getParameterCheckboxValue(
                'auctionParameterRealEstateHouse',
                'parkingExists',
                'parameterRealEstateParking'
              ),
            }
          : undefined,
        auctionParameterRealEstateFlat: inputs.auctionParameterRealEstateFlat
          ? {
              ...inputs.auctionParameterRealEstateFlat,
              balconyArea: getParameterCheckboxValue('auctionParameterRealEstateFlat', 'balcony', 'balconyArea'),
              loggiaArea: getParameterCheckboxValue('auctionParameterRealEstateFlat', 'loggia', 'loggiaArea'),
              mortgagePercent: getParameterCheckboxValue(
                'auctionParameterRealEstateFlat',
                'mortgage',
                'mortgagePercent'
              ),
              basinArea: getParameterCheckboxValue('auctionParameterRealEstateFlat', 'basin', 'basinArea'),
              cellarArea: getParameterCheckboxValue('auctionParameterRealEstateFlat', 'cellar', 'cellarArea'),
              terraceArea: getParameterCheckboxValue('auctionParameterRealEstateFlat', 'terrace', 'terraceArea'),
              parameterRealEstateParking: getParameterCheckboxValue(
                'auctionParameterRealEstateFlat',
                'parkingExists',
                'parameterRealEstateParking'
              ),
            }
          : undefined,
        auctionParameterRealEstateLand: inputs.auctionParameterRealEstateLand
          ? {
              ...inputs.auctionParameterRealEstateLand,
              mortgagePercent: getParameterCheckboxValue(
                'auctionParameterRealEstateLand',
                'mortgage',
                'mortgagePercent'
              ),
            }
          : undefined,
        auctionParameterRealEstateCommercial: inputs.auctionParameterRealEstateCommercial
          ? {
              ...inputs.auctionParameterRealEstateCommercial,
              parameterRealEstateParking: getParameterCheckboxValue(
                'auctionParameterRealEstateCommercial',
                'parkingExists',
                'parameterRealEstateParking'
              ),
            }
          : undefined,
        auctionParameterRealEstateOther: inputs.auctionParameterRealEstateOther
          ? {
              ...inputs.auctionParameterRealEstateOther,
              parameterRealEstateParking: getParameterCheckboxValue(
                'auctionParameterRealEstateOther',
                'parkingExists',
                'parameterRealEstateParking'
              ),
            }
          : undefined,
        auctionParameterVehicleCar: inputs.auctionParameterVehicleCar
          ? {
              ...inputs.auctionParameterVehicleCar,
              parameterVehicleCarModel:
                !_.get(inputs, 'auctionParameterVehicleCar.parameterVehicleCarModel') ||
                !_.get(inputs, 'auctionParameterVehicleCar.parameterVehicleCarBrand')
                  ? undefined
                  : _.get(inputs, 'auctionParameterVehicleCar.parameterVehicleCarModel'),
            }
          : undefined,
        auctionParameterVehicleMotorcycle: inputs.auctionParameterVehicleMotorcycle,
        auctionParameterVehicleOther: inputs.auctionParameterVehicleOther
          ? {
              ...inputs.auctionParameterVehicleOther,
              parameterVehicleCarModel:
                !_.get(inputs, 'auctionParameterVehicleCar.parameterVehicleOtherModel') ||
                !_.get(inputs, 'auctionParameterVehicleCar.parameterVehicleOtherBrand')
                  ? undefined
                  : _.get(inputs, 'auctionParameterVehicleCar.parameterVehicleOtherModel'),
            }
          : undefined,
        auctionCautionCash: inputs.auctionCautionCash,
        auctionCautionBankGuarantee: inputs.auctionCautionBankGuarantee,
        auctionCautionBankAccount: inputs.auctionCautionBankAccount,
        contactPerson: inputs.contactPerson,
        repeated: inputs.repeated,
        overcharge: inputs.overcharge,
      };
      filteredAuctionParameterTypes = auctionParameterTypes.filter(
        (auctionType) => auctionType.type !== auctionParameterType
      );

      let dataToRemove = filteredAuctionParameterTypes
        .map((item) => item.value)
        .concat([
          'auctionParameterRealEstateHouse.id',
          'auctionParameterRealEstateHouse.auction',
          'auctionParameterRealEstateFlat.id',
          'auctionParameterRealEstateFlat.auction',
          'auctionParameterRealEstateLand.id',
          'auctionParameterRealEstateLand.auction',
          'auctionParameterRealEstateLand.gps',
          'auctionParameterVehicleCar.id',
          'auctionParameterVehicleCar.auction',
          'auctionParameterVehicleMotorcycle.id',
          'auctionParameterVehicleMotorcycle.auction',
          'auctionParameterVehicleOther.id',
          'auctionParameterVehicleOther.auction',
        ]);

      // Remove caution types from create request
      if (!id) {
        dataToRemove = dataToRemove.concat(['auctionCautionsType']);
      }

      let dataToSend: any = _.omit(data, dataToRemove);

      if (!id) {
        dataToSend.createAuctionInformationTypeC = !query.copyFrom && !templateId;
      }

      dataToSend['auctionAuthorizationsType'] = inputs.auctionAuthorizationsType.map((i) => ({ type: i }));
      if (!id && query.section < AuctionSections.location) {
        dataToSend.auctionAddress = {};
      }

      [
        'auctionParameterRealEstateHouse',
        'auctionParameterRealEstateFlat',
        'auctionParameterRealEstateLand',
        'auctionParameterRealEstateCommercial',
        'auctionParameterRealEstateOther',
        'auctionParameterVehicleCar',
        'auctionParameterVehicleMotorcycle',
        'auctionParameterVehicleOther',
      ].forEach((parameterName) => {
        Object.getOwnPropertyNames(dataToSend[parameterName] || {}).forEach((prop: string) => {
          if (dataToSend[parameterName][prop] !== null && typeof dataToSend[parameterName][prop] === 'object') {
            if (_.isArray(dataToSend[parameterName][prop])) {
              dataToSend[parameterName][prop] = dataToSend[parameterName][prop].map((i: BaseObjectType) => {
                const res = {};
                Object.keys(i).forEach((p) => {
                  _.set(res, p, _.isObject(i[p]) ? i[p].id : i[p]);
                });
                return res;
              });
            } else {
              dataToSend[parameterName][prop] = dataToSend[parameterName][prop].id;
            }
          }
        });
      });

      if (currentCategoryType === 'real_estate_land') {
        dataToSend = _.omit(dataToSend, ['auctionAddress.houseNumber', 'auctionAddress.street']);
      } else {
        dataToSend = _.omit(dataToSend, ['auctionAddress.landNumber']);
      }

      if (data.generateTitle) {
        dataToSend = _.omit(dataToSend, ['title']);
      }

      if (data.status === 'adjourned') {
        dataToSend = _.omit(dataToSend, ['startDt', 'endDt']);
      }

      // Set concept ID
      if (!!query.concept) {
        dataToSend['auctionConceptId'] = query.concept;
      }

      // After novel only for executor auctions
      if (
        inputs.auctionType !== 'executor_auction_voluntary' &&
        inputs.auctionType !== 'executor_auction_involuntary'
      ) {
        dataToSend = _.omit(dataToSend, ['afterNovel']);
      }

      // Can protest only for after novel auctions
      if (
        (inputs.auctionType !== 'executor_auction_voluntary' &&
          inputs.auctionType !== 'executor_auction_involuntary') ||
        dataToSend.afterNovel
      ) {
        dataToSend = _.omit(dataToSend, ['canProtest']);
      }

      // Min purchase price only for auction

      if (inputs.auctionType !== 'auction') {
        dataToSend = _.omit(dataToSend, ['minPurchasePrice']);
      }

      // Overcharge
      if (
        !(
          (formik.values.auctionType === 'executor_auction_voluntary' ||
            formik.values.auctionType === 'executor_auction_involuntary') &&
          formik.values.auctionRootCategoryMachineName === 'real_estate'
        ) ||
        !formik.values.novel
      ) {
        dataToSend = _.omit(dataToSend, ['overcharge']);
      }

      Object.keys(dataToSend).forEach((key) => {
        if (!_.get(fieldsEnabled, key, true)) {
          dataToSend = _.omit(dataToSend, key);
        }
      });

      if (!_.get(fieldsEnabled, 'title', true)) {
        dataToSend = _.omit(dataToSend, 'generateTitle');
      }

      if (!!id) {
        await auctionsApi.auctionUpdate(id, dataToSend as any);
        setErrorFields([]);
        loadDetail();
      } else {
        const response = await auctionsApi.auctionCreate(dataToSend as any);
        if (copiedFromId !== null) {
          await copyAttachments(copiedFromId, response.data.data.id);
        }

        // Save data from template
        if (templateId && formik.values.auctioneer) {
          const templateResponse = await auctioneersApi.detailTemplate(formik.values.auctioneer, templateId);
          const data = JSON.parse(templateResponse.data.data.data);
          const informations = data.informations;
          const informedConsents = (data.informedConsents || []).filter((ic: AuctionInformedConsent) => ic.enabled);

          // Save information
          for (const index in informations) {
            await auctionInformationApi.adminCreate(response.data.data.id, {
              title: informations[index].title,
              content: informations[index].text,
              category: 'c',
              categoryKey: informations[index].type,
            });
          }

          for (const index in informedConsents) {
            const informedConsent = informedConsents[index];
            await informedConsentsApi.create(response.data.data.id, {
              title: informedConsent.title,
              text: informedConsent.text,
            });
          }
        }

        history.push(
          `${pageState.getPagePath(routes.admin.AUCTIONS_UPDATE, { ':id': response.data.data.id })}?section=${
            AuctionSections.settings
          }`
        );
      }
      setErrorFields([]);
      setCurrentStatus(formik.values.status);
    } catch (err) {
      if (!err.response) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      setErrorFields([]);
      if (!!id) {
        setErrorFields(Object.getOwnPropertyNames(errors));
        Object.getOwnPropertyNames(errors).map((prop) => {
          let _prop = prop;
          let errorMessage = errors[prop][0];

          if (prop === 'auctionAddress_latitude') {
            _prop = 'auctionAddress_gps';
            errorMessage = errors['auctionAddress_latitude'][0];
          } else if (prop === 'auctionAddress_longitude') {
            _prop = 'auctionAddress_gps';
            errorMessage = errors['auctionAddress_longitude'][0];
          }

          formik.setFieldError(_prop, errorMessage);
          return prop;
        });
      } else {
        const inputs = getInputs();
        let result = true;
        Object.getOwnPropertyNames(errors).forEach((prop) => {
          if (!!inputs.find((i) => i === prop)) {
            result = false;
            formik.setFieldError(prop, errors[prop][0]);
          }
        });
        formik.setSubmitting(false);
        const activeSection = getActiveSection(query.section);
        if (activeSection === AuctionSections.subject) {
          Object.getOwnPropertyNames(errors).forEach((prop) => {
            if (prop.search('auctionParameter') > -1) {
              result = false;
              formik.setFieldError(prop, errors[prop][0]);
            }
          });
        }
        if (result) {
          setValidSections({ ...validSections, [activeSection]: true });
          if (activeSection < AuctionSections.settings) {
            if (activeSection === 3) {
              setQuery({ ...query, section: 7 }, 'push');
              Object.getOwnPropertyNames(errors).forEach((prop) => {
                formik.setFieldError(prop, errors[prop][0]);
              });
            } else {
              setQuery({ ...query, section: activeSection + 1 }, 'push');
            }
          }
        } else {
          setValidSections({ ...validSections, [activeSection]: false });
        }
        if (activeSection >= AuctionSections.location) {
          setErrorFields(Object.getOwnPropertyNames(errors));
        }
      }
    }

    // Scroll to first error
    setTimeout(() => {
      const errorElements = document.querySelectorAll(`.invalid-feedback`);
      if (errorElements.length > 0 && !!errorElements[0].parentElement) {
        window.scroll({
          top: errorElements[0].parentElement.getBoundingClientRect().top + window.scrollY,
          behavior: 'smooth',
        });
      }
    }, 200);
  };

  const getInputs = (section?: AuctionSections): Array<string> => {
    switch (section !== undefined ? section : getActiveSection(query.section)) {
      case AuctionSections.auction:
        return [
          'auctioneer',
          'status',
          'auctionType',
          'auctionCategory',
          'numberSuffix',
          'numberPrefix',
          'contactPerson',
        ];
      case AuctionSections.subject:
        return ['title', 'placeAuctionDescription', 'description', 'printDescription', 'internalDescription'];
      case AuctionSections.details:
        return ['startDt', 'endDt', 'estimatedPrice', 'minimalBid', 'minimalThrow'];
      case AuctionSections.location:
        return currentCategoryType !== 'real_estate_land'
          ? [
              'auctionAddress_cadastralArea',
              'auctionAddress_city',
              'auctionAddress_houseNumber',
              'auctionAddress_latitude',
              'auctionAddress_longitude',
              'auctionAddress_ruian',
              'auctionAddress_street',
              'auctionAddress_region',
              'auctionAddress_district',
            ]
          : [
              'auctionAddress_cadastralArea',
              'auctionAddress_city',
              'auctionAddress_latitude',
              'auctionAddress_longitude',
              'auctionAddress_ruian',
              'auctionAddress_landNumber',
              'auctionAddress_region',
              'auctionAddress_district',
            ];
      case AuctionSections.settings:
        return [
          'userAuctionAuthorizationType',
          'auctionCautionsType',
          'auctionCautionBankAccount_variableSymbol',
          'auctionCautionBankAccount_specificSymbol',
        ];
      default:
        return [];
    }
  };

  const handleSectionChange = (url: number | string) => {
    setQuery({ ...query, section: url as number }, 'push');
  };

  const handleCreateButtonClick = () => {
    history.push(pageState.getPagePath(routes.admin.AUCTIONS_CREATE));
    formik.setValues(initialFormValues);
    Object.getOwnPropertyNames(validSections).map((prop) => set(validSections, prop, false));
    setValidSections(validSections);
  };

  const handleAuctionStateChange = (state: 'active' | 'deactive') => formik.setFieldValue('state', state);

  const handlePrint = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    try {
      const res = await auctionsApi.printAuction(id);
      fileDownload(res.data, `aution-${id}.pdf`);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const getParameterCheckboxValue = (section: string, checkboxName: string, inputName: string) => {
    if (_.get(formik.values, `${section}.${checkboxName}`)) {
      return _.get(formik.values, `${section}.${inputName}`);
    }
    return undefined;
  };

  const getCopyMultiSelectParameterValue = (data: AuctionResponse, group: string, parameter: string, key: string) => {
    const groupValues = _.get(data, group, {});
    const parameterValues = _.get(groupValues, parameter, []);
    return parameterValues.map((i: any) => ({ [key]: _.get(i, `${key}.id`) }));
  };

  const getActiveSection = (section: number): AuctionSections => {
    if (!!section && section === 7 && get(validSections, 3, false)) {
      return AuctionSections.settings;
    }
    if (!!section && section > 0 && !get(validSections, section - 1, false)) {
      return AuctionSections.auction;
    }
    return section in AuctionSections ? section : AuctionSections.auction;
  };

  const getTitle = () => {
    if (!!id && loaded) {
      return (
        <h1>
          <strong className="f-weight-600 title f-size-20 mb-2 title">{formik.values.title}</strong>
          <div className="link-holder">
            <Link
              className="d-inline-flex align-items-center f-weight-400 f-size-14"
              to={`${pageState.getPagePath(routes.admin.AUCTIONS_ACTIVE_DETAIL, { ':id': id })}`}
            >
              <img src={icoAuctioneer} alt="ico" className="mr-1" />
              {getTranslation('auction_process_button')}
            </Link>
            {formik.values.state === 'active' && (
              <Link
                className="d-inline-flex align-items-center f-weight-400 f-size-14"
                to={pageState.getPagePath(
                  query.type === 'auction' ? routes.front.AUCTION_DETAIL : routes.front.AUCTION_PUBLIC_DETAIL,
                  { ':id': formik.values.hash }
                )}
                target="_blank"
              >
                <img src={eyeColorIco} alt="ico" className="mr-1" />
                {getTranslation('show_auction_button')}
              </Link>
            )}
            <Link to="/" className="d-inline-flex align-items-center f-weight-400 f-size-14" onClick={handlePrint}>
              <img src={icoDocument} alt="ico" className="mr-1" />
              Tisk
            </Link>
          </div>
        </h1>
      );
    }
    return <h1>{getTranslation('auction_create_title')}</h1>;
  };

  const getBackContent = () => {
    if (!!currentAuctioneer && !!id && loaded) {
      return (
        <span className="d-inline-flex align-items-center f-weight-400 f-size-14 back-content text-nowrap">
          <img src={auctioneerIcoActive} alt="ico" className="mr-2" />
          <span className="f-weight-700 mr-1">{getTranslation('top_auctioneer_label')}:</span>
          <Link
            className="d-inline-flex align-items-center f-weight-400 f-size-14"
            to={pageState.getPagePath(routes.admin.AUCTIONEER_UPDATE, { ':id': currentAuctioneer.id })}
          >
            {currentAuctioneer.auctioneerName}
          </Link>
        </span>
      );
    }
    return undefined;
  };

  const getTranslation = (key: string) => _.get(domainTranslations, key, key);

  const navItems: Array<AdminProgressFormMenuItemType> = [
    {
      label: getTranslation('tab_auction'),
      url: AuctionSections.auction.toString(),
      isActive: getActiveSection(query.section) === AuctionSections.auction,
      hasError: !!getInputs(AuctionSections.auction).find((e) => !!~errorFields.indexOf(e)),
    },
    {
      label: getTranslation('tab_subject'),
      url: AuctionSections.subject.toString(),
      disabled: !validSections[AuctionSections.auction],
      isActive: getActiveSection(query.section) === AuctionSections.subject,
      hasError:
        !!getInputs(AuctionSections.subject).find((e) => !!~errorFields.indexOf(e)) ||
        errorFields.some((e) => e.search('auction_parameter') > -1) ||
        errorFields.some((e) => e.search('auctionParameter') > -1),
    },
    {
      label: getTranslation('tab_detail'),
      url: AuctionSections.details.toString(),
      disabled: !validSections[AuctionSections.subject],
      isActive: getActiveSection(query.section) === AuctionSections.details,
      hasError: !!getInputs(AuctionSections.details).find((e) => !!~errorFields.indexOf(e)),
    },
    {
      label: getTranslation('tab_location'),
      url: AuctionSections.location.toString(),
      disabled: !validSections[AuctionSections.details],
      isActive: getActiveSection(query.section) === AuctionSections.location,
      hasError: !!getInputs(AuctionSections.location).find((e) => !!~errorFields.indexOf(e)),
    },
  ];

  if (!!id || templateId || copiedFromId) {
    navItems.push({
      label: getTranslation('tab_settings'),
      url: AuctionSections.settings.toString(),
      disabled: !validSections[AuctionSections.location],
      isActive: getActiveSection(query.section) === AuctionSections.settings,
      hasError: !!getInputs(AuctionSections.settings).find((e) => !!~errorFields.indexOf(e)),
    });
  }

  if (!!id) {
    navItems.push({
      label: getTranslation('tab_information'),
      url: AuctionSections.information.toString(),
      isActive: getActiveSection(query.section) === AuctionSections.information,
    });
    navItems.push({
      label: getTranslation('tab_attachments'),
      url: AuctionSections.attachments.toString(),
      isActive: getActiveSection(query.section) === AuctionSections.attachments,
      hasError: !!getInputs(AuctionSections.attachments).find((e) => !!~errorFields.indexOf(e)),
    });
    navItems.push({
      label: getTranslation('tab_export'),
      url: AuctionSections.export.toString(),
      isActive: getActiveSection(query.section) === AuctionSections.export,
      hasError: !!getInputs(AuctionSections.export).find((e) => !!~errorFields.indexOf(e)),
    });
    navItems.push({
      label: getTranslation('tab_history'),
      url: AuctionSections.history.toString(),
      hasError: false,
      isActive: getActiveSection(query.section) === AuctionSections.history,
    });
  }

  const renderSection = (section: AuctionSections, children: React.ReactNode, plain?: boolean) => {
    if (getActiveSection(query.section) !== section) {
      return null;
    }

    if (plain) {
      return children;
    }

    return (
      <AdminBoxContent className="p-0">
        <div className="w-min-500">
          <div className="component-admin-box-content overlow-x-auto">{children}</div>
        </div>
      </AdminBoxContent>
    );
  };

  const renderContent = () => {
    if (isCopying) {
      return (
        <AdminBoxContent className="p-0">
          <div className="pt-5 pb-4">
            <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
              <div style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}>
                <BasePreloader />
                <div style={{ marginTop: '12px', fontSize: '18px' }}>{copyStatus}</div>
              </div>
            </div>
          </div>
        </AdminBoxContent>
      );
    }

    if (!loaded) {
      return (
        <AdminBoxContent className="p-0">
          <div className="pt-5 pb-4">
            <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
              <BasePreloader />
            </div>
          </div>
        </AdminBoxContent>
      );
    }

    return (
      <>
        {renderSection(
          AuctionSections.auction,
          <AuctionSection
            id={id}
            fieldsEnabled={fieldsEnabled}
            values={formik.values}
            errors={formik.errors}
            copyFrom={query.copyFrom}
            conceptData={conceptData}
            isSubmitting={formik.isSubmitting}
            auctionType={query.type === 'public' ? 'auction_public' : 'auction'}
            getTranslation={getTranslation}
            handleChange={formik.handleChange}
            handleSubmit={formik.handleSubmit}
            setFieldValue={formik.setFieldValue}
            setMainCategory={setMainCategory}
            onSubcategoryChange={(type) => setCurrentCategoryType(type)}
            setTemplateId={setTemplateId}
            templateId={templateId}
          />
        )}
        {renderSection(
          AuctionSections.subject,
          <SubjectSection
            id={id}
            values={formik.values}
            errors={formik.errors}
            isSubmitting={formik.isSubmitting}
            fieldsEnabled={fieldsEnabled}
            auctionType={query.type === 'public' ? 'auction_public' : 'auction'}
            getTranslation={getTranslation}
            handleChange={formik.handleChange}
            handleSubmit={formik.handleSubmit}
            setFieldValue={formik.setFieldValue}
          />
        )}
        {renderSection(
          AuctionSections.details,
          <DetailsSection
            id={id}
            values={formik.values}
            errors={formik.errors}
            fieldsEnabled={fieldsEnabled}
            isSubmitting={formik.isSubmitting}
            auctionType={query.type === 'public' ? 'auction_public' : 'auction'}
            getTranslation={getTranslation}
            handleChange={formik.handleChange}
            handleSubmit={formik.handleSubmit}
            setFieldValue={formik.setFieldValue}
          />
        )}
        {renderSection(
          AuctionSections.location,
          <LocationSection
            id={id}
            values={formik.values}
            errors={formik.errors}
            fieldsEnabled={fieldsEnabled}
            isSubmitting={formik.isSubmitting}
            currentCategoryType={currentCategoryType}
            auctionType={query.type === 'public' ? 'auction_public' : 'auction'}
            getTranslation={getTranslation}
            handleChange={formik.handleChange}
            handleSubmit={formik.handleSubmit}
            setFieldValue={formik.setFieldValue}
          />
        )}
        {renderSection(
          AuctionSections.settings,
          <SettingsSection
            id={id}
            values={formik.values}
            errors={formik.errors}
            fieldsEnabled={fieldsEnabled}
            isSubmitting={formik.isSubmitting}
            auctionType={query.type === 'public' ? 'auction_public' : 'auction'}
            getTranslation={getTranslation}
            handleChange={formik.handleChange}
            handleSubmit={formik.handleSubmit}
            setFieldValue={formik.setFieldValue}
          />
        )}
        {renderSection(
          AuctionSections.information,
          <InformationSection
            id={id}
            showActionsChange={(val) => setShowActions(val)}
            auctionType={query.type === 'public' ? 'auction_public' : 'auction'}
          />
        )}
        {renderSection(
          AuctionSections.attachments,
          <Documents id={id} auctionType={formik.values.auctionType} getTranslation={getTranslation} />
        )}
        {renderSection(
          AuctionSections.export,
          <AuctionExport id={id} isEnded={isEnded} status={currentStatus} startDate={formik.values.startDt} />,
          true
        )}
        {renderSection(AuctionSections.receipts, <ReceiptsSection />)}
        {renderSection(
          AuctionSections.history,
          <AuctionHistory auctionId={id} author={author} dateCreated={dateCreated} />
        )}
      </>
    );
  };

  if (loaded && notFound) {
    return (
      <BasePage
        page={routes.admin.AUCTIONS_LIST}
        className={classNames(['page-auction-form', { 'auction-detail': !!id && loaded }])}
        backCustomContent={getBackContent()}
      >
        <div className="component-auction-404">
          <div className="component-auction-title">Chyba</div>
          <div className="w-min-500 bg-white">
            <div className="component-admin-box-content">
              Pro zobrazení této stránky anebo její části nemáte oprávnění.
            </div>
          </div>
        </div>
      </BasePage>
    );
  }

  if (!isTranslationsLoaded || !isConceptLoaded) {
    return null;
  }

  return (
    <BasePage
      customTitle={getTitle()}
      page={routes.admin.AUCTIONS_LIST}
      className={classNames(['page-auction-form', { 'auction-detail': !!id && loaded }])}
      backCustomContent={getBackContent()}
    >
      <AdminProgressFormMenu onClick={handleSectionChange} items={navItems} />
      {renderContent()}
      {!id && (
        <div>
          <Button grayBg to={`${pageState.getPagePath(routes.admin.AUCTIONS_LIST)}${urlSearchState.adminAuctionsList}`}>
            {getTranslation('back_button')}
          </Button>
        </div>
      )}
      {showActions && !!id && loaded && (
        <AuctionActions
          id={id}
          back={query.back}
          state={formik.values.state}
          status={formik.values.status}
          isSubmitting={formik.isSubmitting}
          handleSubmit={formik.handleSubmit}
          auctionType={query.type === 'public' ? 'auction_public' : 'auction'}
          getTranslation={getTranslation}
          onChangeState={handleAuctionStateChange}
          onCreateClick={handleCreateButtonClick}
        />
      )}
    </BasePage>
  );
};

export default AuctionsUpdatePage;
