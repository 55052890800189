import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import FacebookLogin from 'react-facebook-login';

import config from '@config';
import { useFacebookApi } from '@api/facebook';
import { getDateTimeFormat } from '@helpers/datetime';
import { BasePreloader, Button, FormGroup, Select } from '@components';
import { AllowedEmails, FacebookInfoResponse, FacebookPagesResponse, FacebookStatusResponse } from '@types';

import { useFormik } from 'formik';
import { useAllowedEmailsApi } from '@api/allowedEmails';
import _ from 'lodash';

const OtherSettings: React.FC = () => {
  const facebookApi = useFacebookApi();
  const allowedEmailsApi = useAllowedEmailsApi();
  const [facebookPages, setFacebookPages] = useState<FacebookPagesResponse | undefined>();
  const [facebookSaved, setFacebookSaved] = useState(true);
  const [facebookInfo, setFacebookInfo] = useState<FacebookInfoResponse | undefined>();
  const fbPagesOptions = Object.values(facebookPages || {}).map((val) => ({ value: val.id, label: val.name }));
  const [isLoaded, setIsLoaded] = useState(false);
  const formik = useFormik({
    onSubmit: () => handleSubmit(),
    validateOnChange: false,
    initialValues: {
      allowedEmails: '',
      allowedInSystem: false,
    },
  });

  useEffect(() => {
    loadFacebookConnection();
    loadAllowedPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadFacebookConnection = async () => {
    try {
      const res = await facebookApi.info();
      if (!!res.data.data.expirationDt) {
        setFacebookInfo(res.data.data);
        await loadFacebookPages();
      } else {
        setFacebookInfo(undefined);
      }
    } catch (err) {
      if (facebookApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadFacebookPages = async () => {
    try {
      const res = await facebookApi.getPages();
      setFacebookPages(res.data);
    } catch (err) {
      if (facebookApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadAllowedPages = async () => {
    try {
      const res = await allowedEmailsApi.detail();
      await formik.setValues({
        allowedEmails: res.data.data?.allowedEmails || '',
        allowedInSystem: res.data.data?.allowedInSystem,
      });
    } catch (err) {
      if (facebookApi.isCancel(err)) {
        return;
      }
    }

    setIsLoaded(true);
  };

  const handleFacebookConnect = async (userInfo: FacebookStatusResponse) => {
    if (!userInfo.accessToken) {
      return;
    }
    try {
      await facebookApi.connect({ ...userInfo });
      await loadFacebookConnection();
    } catch (err) {
      if (facebookApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleFacebookSubmit = async () => {
    if (!facebookInfo?.pageId) {
      return;
    }
    setFacebookSaved(false);
    try {
      await facebookApi.updatePage({ pageId: facebookInfo.pageId });
      await loadFacebookConnection();
    } catch (err) {
      if (facebookApi.isCancel(err)) {
        return;
      }
    } finally {
      setFacebookSaved(true);
    }
  };

  const handleInstagramConnect = async () => {
    try {
      await facebookApi.connectInstagram();
      await loadFacebookConnection();
    } catch (err) {
      if (facebookApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleSubmit = async () => {
    try {
      let values = formik.values;
      const inputs = _.omit(values, 'allowedInSystem') as AllowedEmails;
      await allowedEmailsApi.update(inputs);
      formik.setSubmitting(false);
    } catch (err: any) {
      if (allowedEmailsApi.isCancel(err)) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          <div>
            <h2>Facebook</h2>
          </div>
          <div className="mt-4">
            <div className="mb-3">
              {!!facebookInfo?.expirationDt && (
                <p className="mb-0">Expiruje: {getDateTimeFormat(facebookInfo.expirationDt)}</p>
              )}
              {!!facebookInfo?.instagramPageId && (
                <span className="badge badge-success px-2 py-1 mt-1">Instagram propojen</span>
              )}
            </div>

            <div className="mb-4 d-flex flex-wrap align-items-center">
              <div className="pb-2 mr-3">
                <FacebookLogin
                  size="small"
                  fields="email"
                  autoLoad={false}
                  appId={config.fbAppId}
                  scope="pages_show_list,pages_manage_posts"
                  textButton="Přihlášení k facebooku"
                  buttonStyle={{ fontSize: 13, borderRadius: 30 }}
                  callback={handleFacebookConnect}
                />
              </div>

              {!facebookInfo?.instagramPageId && (
                <div className="pb-2">
                  <Button onClick={() => handleInstagramConnect()}>Propojit s instagramem</Button>
                </div>
              )}
            </div>

            {fbPagesOptions.length > 0 && !!facebookInfo && (
              <div>
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-left">Stránka *</Form.Label>
                  <div className="f-inline-control">
                    <div className="w-max-500">
                      <Select
                        size="md"
                        options={fbPagesOptions}
                        value={fbPagesOptions.find((o) => o.value.toString() === facebookInfo?.pageId?.toString())}
                        onChange={(val) => setFacebookInfo({ ...facebookInfo, pageId: val?.value || null })}
                      />
                    </div>
                  </div>
                </Form.Group>

                <div className="text-right">
                  {facebookSaved ? (
                    <Button variant="btn-outline-primary" type="button" onClick={handleFacebookSubmit}>
                      Uložit
                    </Button>
                  ) : (
                    <BasePreloader size={29} className="d-inline-block" />
                  )}
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <div>
        <h2>Odesílání emailů jen na povolené adresy</h2>
      </div>
      <Row>
        <Col xs={12}>
          {isLoaded ? (
            <div>
              <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-4">
                {formik.values.allowedInSystem ? (
                  <span className="badge badge-success px-2 py-1 mt-1">Aktivní - povoleno v nastavení</span>
                ) : (
                  <span className="badge badge-danger px-2 py-2 mt-1">Neaktivní - zakázáno v nastavení</span>
                )}
                <div className="mt-3">Emaily se oddělují střeníkem: (test@test.cz;test@gmail.com)</div>
                <div className="responsive-table-content mt-3">
                  <div>
                    <FormGroup
                      dataTestId="allowedEmails"
                      type="text"
                      name="allowedEmails"
                      label="Povolené emaily:"
                      labelClassName="text-left"
                      value={formik.values.allowedEmails}
                      error={formik.errors.allowedEmails}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <Row>
                  <Col xs={12} className="mt-3 text-right">
                    {!formik.isSubmitting ? (
                      <Button
                        dataTestId="allowedEmailsSubmit"
                        variant="btn-outline-primary"
                        type="submit"
                        disabled={formik.isSubmitting}
                      >
                        Uložit
                      </Button>
                    ) : (
                      <BasePreloader size={29} className="d-inline-block" />
                    )}
                  </Col>
                </Row>
              </Form>
            </div>
          ) : (
            <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
              <BasePreloader />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default OtherSettings;
