import React from 'react';
import { Form } from 'react-bootstrap';

import icoError from '@assets/images/ico-error.svg';

interface Props {
  type: 'invalid';
  style?: React.CSSProperties;
}

const ControlFeedback: React.FC<Props> = (props) => (
  <Form.Control.Feedback type={props.type} className="custom" style={props.style}>
    {props.type === 'invalid' && <img src={icoError} alt="ico" />}
    {props.children}
  </Form.Control.Feedback>
);

export default ControlFeedback;
