import React from 'react';
import { Table } from 'react-bootstrap';
import fileDownload from 'js-file-download';
import { useToasts } from 'react-toast-notifications';

import { useUsersApi } from '@api/users';
import { getName } from '@helpers/string';
import { applicationStore } from '@stores';
import { fileToBase64 } from '@helpers/files';
import { getFormatBytes } from '@helpers/formats';
import { BasePreloader, Button, FileIcon, Visible } from '@components';
import { User, BuyerDocument, UserPermission, DocumentState } from '@types';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';
import RejectedModal from '../../DocumentsPage/components/RejectedModal';
import infoColorIco from '@assets/images/info-color.svg';

interface Props {
  user?: User;
  readOnly?: boolean;
  userId: string | number;
}

const UserDocuments: React.FC<Props> = (props) => {
  const usersApi = useUsersApi();
  const { addToast } = useToasts();
  const [documents, setDocuments] = React.useState<Array<BuyerDocument>>([]);
  const [loaded, setLoaded] = React.useState(false);
  const inputRef = React.createRef<HTMLInputElement>();
  const [saved, setSaved] = React.useState(true);
  const [reason, setReason] = React.useState<React.ReactNode | null>(null);

  React.useEffect(() => {
    if (props.user && props.user.id) {
      loadDocuments(props.user.id);
    }
    // eslint-disable-next-line
  }, [props.user]);

  const loadDocuments = (id: number) => {
    setLoaded(false);
    usersApi
      .getDocuments(id)
      .then((res) => {
        setDocuments(res.data.data);
        setLoaded(true);
      })
      .catch((err) => {
        if (usersApi.isCancel(err)) {
          return;
        }
      });
  };

  const handleFileClick = (e: React.MouseEvent<HTMLAnchorElement>, item: BuyerDocument) => {
    e.preventDefault();
    usersApi
      .getAdminDocument(props.userId, item.id)
      .then((res) => {
        fileDownload(res.data, item.media.originalName);
      })
      .catch((err) => {
        if (usersApi.isCancel(err)) {
          return;
        }
      });
  };

  const saveFile = async (file: File) => {
    const maxPostBodySize = applicationStore.getState().systemInfo?.post_max_size;
    if (!!file && !!maxPostBodySize && maxPostBodySize < file.size) {
      addToast(`Soubor ${file.name} nesmí být větší než ${getFormatBytes(maxPostBodySize)}`, { appearance: 'error' });
      return;
    }

    setSaved(false);
    try {
      const fileBase64 = await fileToBase64(file);
      usersApi
        .uploadDocument(props.user?.id as number, {
          mime: file.type,
          type: 'file',
          document_type: 'identity',
          data: fileBase64,
          original_name: file.name,
        })
        .then(async () => {
          await loadDocuments(props.user?.id as number);
          setSaved(true);
        })
        .catch((err) => {
          if (usersApi.isCancel(err)) {
            return;
          }
          setSaved(true);
        });
    } catch (err) {
      console.error(err);
      setSaved(true);
    }
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined = e.target.files !== null ? e.target.files[0] : undefined;
    if (!!file) {
      saveFile(file);
    }
  };

  const renderStatus = (item: BuyerDocument) => {
    if (item.state === DocumentState.rejected) {
      return (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setReason(item.reason ? item.reason : <i>Bez udání důvodu</i>);
          }}
        >
          {item.translatedState}
          <img src={infoColorIco} alt="rejected" style={{ marginLeft: '8px' }} />
        </a>
      );
    }

    return item.translatedState;
  };

  if (!loaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div>
      <RejectedModal title="Důvod zamítnutí" isOpen={reason !== null} reason={reason} onClose={() => setReason(null)} />
      <div>
        <h2>Dokumenty</h2>
        <Visible permissionName={UserPermission.canCreate}>
          <div className="d-flex align-items-center">
            <Button
              onClick={() => inputRef.current?.click()}
              variant="btn-outline-primary"
              className="f-size-12 f-weight-400 mt-3"
            >
              <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
              <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
              Přidat dokument
            </Button>
            {!saved && <BasePreloader className="ml-4 mt-2" size={25} />}
            {saved && (
              <input
                type="file"
                accept="application/pdf"
                ref={inputRef}
                className="d-none"
                onChange={handleSelectFile}
              />
            )}
          </div>
        </Visible>
      </div>
      <div className="mt-4">
        <div className="responsive-table-content">
          <Table className="text-center table-middle border-bottom-0" striped>
            <thead>
              <tr>
                <th className="text-left">Název dokumentu</th>
                <th className="text-left">Typ dokumentu</th>
                <th className="text-left">Stav</th>
                <th className="text-right">Dokument zpracoval</th>
              </tr>
            </thead>
            <tbody>
              {documents.length < 1 && (
                <tr>
                  <td className="text-left" colSpan={100}>
                    Nebyla nalezena žádná data.
                  </td>
                </tr>
              )}
              {documents.map((item, index) => (
                <tr key={`document-${index}`}>
                  <td className="text-left">
                    <FileIcon name={item.media.originalName} />
                    <a href="/" onClick={(e) => handleFileClick(e, item)}>
                      {item.media.originalName}
                    </a>
                  </td>
                  <td className="text-left">{item.translatedType || '-'}</td>
                  <td className="text-left">{renderStatus(item)}</td>
                  <td className="text-right">{item.approvedUser ? getName(item.approvedUser) : '-'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default UserDocuments;
