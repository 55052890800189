import React from 'react';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { BooleanParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import * as routes from '@routes';
import { UserRole } from '@types';
import { useAuthApi } from '@api/auth';
import { useAuctioneersApi } from '@api/auctioneers';
import { BasePage, BoxCentered, Button, FormGroup } from '@components';
import { useAuthStore, usePageStore, useAuthFrontStore } from '@stores';

const AuthActivationPage: React.FC = () => {
  const history = useHistory();
  const pageState = usePageStore();
  const authApi = useAuthApi();
  const authState = useAuthStore();
  const auctioneersApi = useAuctioneersApi();
  const authFrontState = useAuthFrontStore();
  const [query] = useQueryParams({
    ssr: withDefault(BooleanParam, undefined),
    token: withDefault(StringParam, undefined),
  });
  const formik = useFormik({
    onSubmit: () => handleSubmit(),
    validateOnChange: false,
    initialValues: {
      newPassword: '',
      newPassword1: '',
    },
  });

  React.useEffect(() => {
    if (!query.token && !query.ssr) {
      history.push('/');
    }
    return () => authApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.token]);

  const handleSubmit = async () => {
    authState.unsetUser();
    authFrontState.unsetUser();
    await pageState.reload();

    formik.setSubmitting(true);
    authApi
      .confirm(query.token || '', formik.values)
      .then((res) => {
        loadUser(
          res.data.data.token,
          res.data.data.refresh_token,
          res.data.data.role === UserRole.user || res.data.data.role === UserRole.buyer
        );
        formik.setSubmitting(false);
      })
      .catch((err) => {
        if (authApi.isCancel(err)) {
          return;
        }
        const errors = err.response?.data?.errors || {};
        Object.getOwnPropertyNames(errors).map((prop) => {
          formik.setFieldError(prop, errors[prop][0]);
          return prop;
        });
        formik.setSubmitting(false);
      });
  };

  const loadUser = async (token: string, refreshToken: string, isFront: boolean) => {
    try {
      if (isFront) {
        authFrontState.setAuthCookies(token, refreshToken);
      } else {
        authState.setAuthCookies(token, refreshToken);
      }
      const response = await authApi.logged(isFront);
      if (isFront) {
        authFrontState.setUser(response.data.data);
        const pages = await pageState.reload();
        history.push(pages.find((i) => i.routeName === routes.front.ACCOUNT)?.url || '/');
      } else {
        authState.setUser(response.data.data);

        if (response.data.data.role.startsWith('ROLE_AUCTIONEER')) {
          const auctioneerResponse = await auctioneersApi.list({});
          if (auctioneerResponse.data.data.length > 0) {
            authState.setAuctioneer(auctioneerResponse.data.data[0]);
          } else {
            authState.setAuctioneer(undefined);
          }
        } else {
          authState.setAuctioneer(undefined);
        }

        const pages = await pageState.reload();
        history.push(pages.find((i) => i.routeName === routes.admin.BASE)?.url || '/');
      }
    } catch (err) {
      if (!err.response) {
        return;
      }
      if (isFront) {
        authFrontState.unsetUser();
      } else {
        authState.unsetUser();
      }
      await pageState.reload();
      history.push('/');
    }
  };

  if (!query.token) {
    history.push('/');
    return null;
  }

  return (
    <BasePage className="d-flex justify-content-center align-items-center min-vh-100 p-2">
      <BoxCentered>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <Form.Label>Heslo</Form.Label>
            <FormGroup
              controlOnly
              type="password"
              name="newPassword"
              autoComplete="new-password"
              readOnly={formik.isSubmitting}
              value={formik.values.newPassword}
              error={formik.errors.newPassword}
              onChange={formik.handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Kontrola hesla</Form.Label>
            <FormGroup
              controlOnly
              type="password"
              name="newPassword1"
              autoComplete="new-password"
              readOnly={formik.isSubmitting}
              value={formik.values.newPassword1}
              error={formik.errors.newPassword1}
              onChange={formik.handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-0">
            <Row className="align-items-center mt-4 pt-2">
              <Col xs={12} md={6}>
                <Button disabled={formik.isSubmitting} variant="btn-outline-primary" type="submit">
                  Aktivovat účet
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </BoxCentered>
    </BasePage>
  );
};

export default AuthActivationPage;
