import moment from 'moment';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { ValueType } from 'react-select';
import React, { FC, useEffect, useState } from 'react';

import { getRequestDateFormat } from '@helpers/datetime';
import { AuctionParameterForm } from './AuctionParameterForm';
import { useAuctionParametersApi } from '@api/auctionParameters';
import { AuctionParametersResponse, BaseAuctionParam } from '@types';
import { ControlFeedback, DatePickerInput, FormGroup, Select } from '@components';

import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';

interface SelectOptionType {
  value: string;
  label: string;
}

export const VehicleMotorcycleForm: FC<AuctionParameterForm> = (props) => {
  const auctionParameterApi = useAuctionParametersApi();
  const [brandTypes, setBrandTypes] = useState<BaseAuctionParam[]>([]);
  const [bodyTypes, setBodyTypes] = useState<AuctionParametersResponse[]>([]);
  const [colorTypes, setColorTypes] = useState<AuctionParametersResponse[]>([]);
  const [categoryTypes, setCategoryTypes] = useState<AuctionParametersResponse[]>([]);
  const [stateTypes, setStateTypes] = useState<AuctionParametersResponse[]>([]);
  const [fuelTypes, setFuelTypes] = useState<AuctionParametersResponse[]>([]);
  const [originCountryTypes, setOriginCountryTypes] = useState<AuctionParametersResponse[]>([]);

  const brandTypeOptions = brandTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const bodyTypeOptions = bodyTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const colorTypeOptions = colorTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const categoryTypeOptions = categoryTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const stateTypeOptions = stateTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const fuelTypeOptions = fuelTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const originCountryTypeOptions = originCountryTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  useEffect(() => {
    loadData();
    return () => {
      auctionParameterApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.values.auctionParameterVehicleMotorcycle.parameterVehicleCategory) {
      loadStates(props.values.auctionParameterVehicleMotorcycle.parameterVehicleCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.auctionParameterVehicleMotorcycle.parameterVehicleCategory]);

  const loadStates = async (category: string) => {
    try {
      const stateTypesResponse = await auctionParameterApi.getVehicleState(category);
      setStateTypes(stateTypesResponse.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadData = async () => {
    try {
      const vehicleRes = await auctionParameterApi.getVehicleAll();
      const motorcycleRes = await auctionParameterApi.getVehicleMotorcycleAll();

      setBrandTypes(motorcycleRes.data.data.parameterVehicleMotorcycleBrand);
      setBodyTypes(motorcycleRes.data.data.parameterVehicleMotorcycleBrand);

      setColorTypes(vehicleRes.data.data.parameterVehicleColor);
      setCategoryTypes(vehicleRes.data.data.parameterVehicleCategory);
      setFuelTypes(vehicleRes.data.data.parameterVehicleFuel);
      setOriginCountryTypes(vehicleRes.data.data.parameterVehicleOriginCountry);
    } catch (e) {
      if (!e.response) {
        return;
      }
    }
  };

  const handleMotorcycleBrandChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleMotorcycle.parameterVehicleMotorcycleBrand', itemValue?.value);
  };

  const handleMotorcycleBodyChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleMotorcycle.parameterVehicleMotorcycleBody', itemValue?.value);
  };

  const handleMotorcycleColorChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleMotorcycle.parameterVehicleColor', itemValue?.value);
  };

  const handleMotorcycleCategoryChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleMotorcycle.parameterVehicleCategory', itemValue?.value);
  };

  const handleMotorcycleStateChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleMotorcycle.parameterVehicleState', itemValue?.value);
  };

  const handleDateOfTechnicalInspectionChange = (value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      props.setFieldValue(
        'auctionParameterVehicleMotorcycle.dateOfTechnicalInspection',
        !!inputValue ? getRequestDateFormat(inputValue) : ''
      );
    }
  };

  const handleMotorcycleFuelChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleMotorcycle.parameterVehicleFuel', itemValue?.value);
  };

  const handleMotorcycleOriginCountryChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleMotorcycle.parameterVehicleOriginCountry', itemValue?.value);
  };

  const getSelectValue = (val: any) => {
    if (val !== null && typeof val === 'object') {
      return val.id;
    }
    return val;
  };

  return (
    <>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Značka *</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleMotorcycleBrand">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleMotorcycleBrand"
              isInvalid={!!props.errors.auctionParameterVehicleMotorcycle_parameterVehicleMotorcycleBrand}
              options={brandTypeOptions}
              onChange={handleMotorcycleBrandChange}
              value={
                brandTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterVehicleMotorcycle.parameterVehicleMotorcycleBrand)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleMotorcycle_parameterVehicleMotorcycleBrand && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleMotorcycle_parameterVehicleMotorcycleBrand}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Kategorie *</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleCategory">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleCategory"
              options={categoryTypeOptions}
              onChange={handleMotorcycleCategoryChange}
              value={
                categoryTypeOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterVehicleMotorcycle.parameterVehicleCategory)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleMotorcycle_parameterVehicleCategory && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleMotorcycle_parameterVehicleCategory}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <FormGroup
        readOnly={props.readOnly}
        label="Další charakteristiky"
        labelClassName="text-left"
        name="auctionParameterVehicleMotorcycle.description"
        error={props.errors.auctionParameterVehicleMotorcycle_description as string}
        onChange={props.handleChange}
        value={props.values.auctionParameterVehicleMotorcycle.description}
        dataTestId="auctionParameterVehicleMotorcycle.description"
      />
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Karoserie *</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleMotorcycleBody">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleMotorcycleBody"
              isInvalid={!!props.errors.auctionParameterVehicleMotorcycle_parameterVehicleMotorcycleBody}
              options={bodyTypeOptions}
              onChange={handleMotorcycleBodyChange}
              value={
                bodyTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterVehicleMotorcycle.parameterVehicleMotorcycleBody)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleMotorcycle_parameterVehicleMotorcycleBody && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleMotorcycle_parameterVehicleMotorcycleBody}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Barva</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleColor">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleColor"
              isInvalid={!!props.errors.auctionParameterVehicleMotorcycle_parameterVehicleColor}
              options={colorTypeOptions}
              onChange={handleMotorcycleColorChange}
              value={
                colorTypeOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterVehicleMotorcycle.parameterVehicleColor)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleMotorcycle_parameterVehicleColor && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleMotorcycle_parameterVehicleColor}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <FormGroup
        required={!!props.values.auctionParameterVehicleMotorcycle.vinPublic}
        readOnly={props.readOnly}
        label="VIN"
        labelClassName="text-left"
        name="auctionParameterVehicleMotorcycle.vin"
        error={props.errors.auctionParameterVehicleMotorcycle_vin as string}
        onChange={props.handleChange}
        value={props.values.auctionParameterVehicleMotorcycle.vin}
        dataTestId="auctionParameterVehicleMotorcycle.vin"
      />
      <Form.Group className="d-flex align-items-center">
        <div className="mr-2" data-test-id="auctionParameterVehicleMotorcycle.vinPublic">
          <Form.Check
            disabled={props.readOnly}
            custom
            type="checkbox"
            label="VIN veřejný (bude zobrazen v inzerátu)"
            className="mr-4"
            id="vinPublic"
            name="auctionParameterVehicleMotorcycle.vinPublic"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setFieldValue('auctionParameterVehicleMotorcycle.vinPublic', e.target.checked);
            }}
            checked={props.values.auctionParameterVehicleMotorcycle.vinPublic}
          />
        </div>
      </Form.Group>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Stav</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleState">
            <Select
              isClearable
              isDisabled={!props.values.auctionParameterVehicleMotorcycle.parameterVehicleCategory || props.readOnly}
              size="md"
              name="parameterVehicleState"
              isInvalid={!!props.errors.auctionParameterVehicleMotorcycle_parameterVehicleState}
              options={stateTypeOptions}
              onChange={handleMotorcycleStateChange}
              value={
                stateTypeOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterVehicleMotorcycle.parameterVehicleState)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleMotorcycle_parameterVehicleState && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleMotorcycle_parameterVehicleState}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          thousandSeparator
          type="number"
          label="Stav tachometru"
          className="mt-0 mb-0"
          labelClassName="text-left"
          name="auctionParameterVehicleMotorcycle.tachometer"
          value={props.values.auctionParameterVehicleMotorcycle.tachometer}
          error={props.errors.auctionParameterVehicleMotorcycle_tachometer as string}
          onValueChange={(val) => props.setFieldValue('auctionParameterVehicleMotorcycle.tachometer', val.value)}
          dataTestId="auctionParameterVehicleMotorcycle.tachometer"
        />
        <span className="f-size-12 f-weight-400">km</span>
      </div>
      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          type="number"
          label="Rok výroby"
          className="mt-0 mb-0"
          labelClassName="text-left"
          name="auctionParameterVehicleMotorcycle.dateOfManufacture"
          value={props.values.auctionParameterVehicleMotorcycle.dateOfManufacture}
          error={props.errors.auctionParameterVehicleMotorcycle_dateOfManufacture as string}
          onChange={props.handleChange}
          dataTestId="auctionParameterVehicleMotorcycle.dateOfManufacture"
        />
      </div>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label">STK platná do</Form.Label>
        <div className="f-inline-control d-flex align-items-center">
          <div>
            <DatePickerInput
              readOnly={props.readOnly}
              className={classNames([
                'form-control',
                'w-max-140',
                { 'is-invalid': !!props.errors.auctionParameterVehicleMotorcycle_dateOfTechnicalInspection },
              ])}
              calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
              clearIcon={null}
              name="dateOfTechnicalInspection"
              onChange={(val) => handleDateOfTechnicalInspectionChange(val)}
              value={
                !!props.values.auctionParameterVehicleMotorcycle.dateOfTechnicalInspection
                  ? moment(props.values.auctionParameterVehicleMotorcycle.dateOfTechnicalInspection || '').toDate()
                  : undefined
              }
              data-test-id="dateOfTechnicalInspection"
            />
            {!!props.errors.auctionParameterVehicleMotorcycle_dateOfTechnicalInspection && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleMotorcycle_dateOfTechnicalInspection}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Palivo</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleFuel">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleFuel"
              isInvalid={!!props.errors.auctionParameterVehicleMotorcycle_parameterVehicleFuel}
              options={fuelTypeOptions}
              onChange={handleMotorcycleFuelChange}
              value={
                fuelTypeOptions.find(
                  (i) => i.value === getSelectValue(props.values.auctionParameterVehicleMotorcycle.parameterVehicleFuel)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleMotorcycle_parameterVehicleFuel && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleMotorcycle_parameterVehicleFuel}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          type="number"
          label="Výkon motoru"
          className="mt-0 mb-0"
          labelClassName="text-left"
          name="auctionParameterVehicleMotorcycle.enginePower"
          error={props.errors.auctionParameterVehicleMotorcycle_enginePower as string}
          onChange={props.handleChange}
          value={props.values.auctionParameterVehicleMotorcycle.enginePower}
          dataTestId="auctionParameterVehicleMotorcycle.enginePower"
        />
        <span className="f-size-12 f-weight-400">kW</span>
      </div>
      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          type="number"
          label="Objem motoru"
          className="mt-0 mb-0"
          labelClassName="text-left"
          name="auctionParameterVehicleMotorcycle.engineCapacity"
          error={props.errors.auctionParameterVehicleMotorcycle_engineCapacity as string}
          onChange={props.handleChange}
          value={props.values.auctionParameterVehicleMotorcycle.engineCapacity}
          dataTestId="auctionParameterVehicleMotorcycle.engineCapacity"
        />
        <span className="f-size-12 f-weight-400">ccm</span>
      </div>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Stát původu</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleOriginCountry">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleOriginCountry"
              isInvalid={!!props.errors.auctionParameterVehicleMotorcycle_parameterVehicleOriginCountry}
              options={originCountryTypeOptions}
              onChange={handleMotorcycleOriginCountryChange}
              value={
                originCountryTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterVehicleMotorcycle.parameterVehicleOriginCountry)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleMotorcycle_parameterVehicleOriginCountry && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleMotorcycle_parameterVehicleOriginCountry}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <Form.Group className="d-flex align-items-center">
        <div className="mr-2" data-test-id="auctionParameterVehicleMotorcycle.firstOwner">
          <Form.Check
            disabled={props.readOnly}
            custom
            type="checkbox"
            label="První majitel"
            className="mr-4"
            id="firstOwner"
            name="auctionParameterVehicleMotorcycle.firstOwner"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setFieldValue('auctionParameterVehicleMotorcycle.firstOwner', e.target.checked);
            }}
            checked={props.values.auctionParameterVehicleMotorcycle.firstOwner}
          />
        </div>
      </Form.Group>
      <Form.Group className="d-flex align-items-center">
        <div className="mr-2" data-test-id="auctionParameterVehicleMotorcycle.serviceBook">
          <Form.Check
            disabled={props.readOnly}
            custom
            type="checkbox"
            label="Servisní knížka"
            className="mr-4"
            id="serviceBook"
            name="auctionParameterVehicleMotorcycle.serviceBook"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setFieldValue('auctionParameterVehicleMotorcycle.serviceBook', e.target.checked);
            }}
            checked={props.values.auctionParameterVehicleMotorcycle.serviceBook}
          />
        </div>
      </Form.Group>
      <Form.Group className="d-flex align-items-center">
        <div className="mr-2" data-test-id="auctionParameterVehicleMotorcycle.undamaged">
          <Form.Check
            disabled={props.readOnly}
            custom
            type="checkbox"
            label="Nebourané"
            className="mr-4"
            id="undamaged"
            name="auctionParameterVehicleMotorcycle.undamaged"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setFieldValue('auctionParameterVehicleMotorcycle.undamaged', e.target.checked);
            }}
            checked={props.values.auctionParameterVehicleMotorcycle.undamaged}
          />
        </div>
      </Form.Group>
    </>
  );
};
