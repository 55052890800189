import React from 'react';
import _ from 'lodash';
import { FormikValues, useFormik } from 'formik';
import { Form } from 'react-bootstrap';

import { AuctionService, BaseSelectType } from '@types';
import { useServicesApi } from '@api/services';
import { BasePreloader, FormGroup, Button, Select, ControlFeedback } from '@components';
import { ValueType } from 'react-select';
import { useAuctioneersApi } from '@api/auctioneers';
import { useState } from 'react';

interface Props {
  data: AuctionService;
  auctioneerId: string;
  onClose: () => void;
  onSave: () => void;
}

interface TypeSelectOptionType {
  value: string;
  label: string;
}

const ServiceForm: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const servicesApi = useServicesApi();
  const [vatOptions, setVatOptions] = React.useState<BaseSelectType[]>([]);
  const [loaded, setLoaded] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: props.data.price || undefined,
      state: props.data.state || 'active',
      vat: props.data.vat || '',
      auctioneerServiceElectronicAuction: {
        realEstate15000: _.get(props.data, 'auctioneerServiceElectronicAuction.realEstate15000', ''),
        realEstate150000: _.get(props.data, 'auctioneerServiceElectronicAuction.realEstate150000', ''),
        realEstate750000: _.get(props.data, 'auctioneerServiceElectronicAuction.realEstate750000', ''),
        realEstate999999999: _.get(props.data, 'auctioneerServiceElectronicAuction.realEstate999999999', ''),
        movablePercent15000: _.get(props.data, 'auctioneerServiceElectronicAuction.movablePercent15000', ''),
        movablePlus15000: _.get(props.data, 'auctioneerServiceElectronicAuction.movablePlus15000', ''),
        movableMinimum15000: _.get(props.data, 'auctioneerServiceElectronicAuction.movableMinimum15000', ''),
        movablePercent150000: _.get(props.data, 'auctioneerServiceElectronicAuction.movablePercent150000', ''),
        movablePlus150000: _.get(props.data, 'auctioneerServiceElectronicAuction.movablePlus150000', ''),
        movableMinimum150000: _.get(props.data, 'auctioneerServiceElectronicAuction.movableMinimum150000', ''),
        movablePercent999999999: _.get(props.data, 'auctioneerServiceElectronicAuction.movablePercent999999999', ''),
        movablePlus999999999: _.get(props.data, 'auctioneerServiceElectronicAuction.movablePlus999999999', ''),
        movableMinimum999999999: _.get(props.data, 'auctioneerServiceElectronicAuction.movableMinimum999999999', ''),
      },
    },
    onSubmit: (values) => handleSubmit(values),
  });

  React.useEffect(() => {
    loadVatOptions();
    return () => servicesApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadVatOptions = async () => {
    try {
      const response = await servicesApi.vatEnum();
      setVatOptions(
        response.data.data.map((item) => ({
          value: item.type,
          label: item.translated,
        }))
      );
    } catch (err) {
      if (servicesApi.isCancel(err)) {
        return;
      }
    }
    setLoaded(true);
  };

  const handleSubmit = (values: FormikValues) => {
    const omitInputs = ['auctioneerServiceElectronicAuction'];
    const omitElectronicInputs = ['price'];
    const inputs =
      props.data.serviceForAuctioneer.serviceType === 'electronic_auction'
        ? _.omit(values, omitElectronicInputs)
        : _.omit(values, omitInputs);
    auctioneersApi
      .servicesUpdate(props.auctioneerId, props.data.id.toString(), inputs)
      .then(() => {
        props.onSave();
      })
      .catch((err) => {
        if (servicesApi.isCancel(err)) {
          return;
        }
        const errors = err.response?.data?.errors || {};
        Object.getOwnPropertyNames(errors).map((prop) => {
          formik.setFieldError(prop, errors[prop][0]);
          return prop;
        });
        formik.setSubmitting(false);
      });
  };

  const handleVatChange = (value: ValueType<TypeSelectOptionType, boolean>) => {
    const itemValue = value as TypeSelectOptionType;
    formik.setFieldValue('vat', itemValue?.value || '');
  };

  function handleAuctionType(value: string) {
    formik.setFieldValue('state', value);
  }

  return (
    <div>
      <h2>Upravit službu</h2>
      {loaded ? (
        <div>
          <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-5">
            <div className="responsive-table-content admin-services ">
              <div className=" pt-1">
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label mb-0 text-right">Stav</Form.Label>
                  <div className="f-inline-control d-flex align-items-center settings-group">
                    <Form.Check
                      custom
                      type="radio"
                      label="Aktivní"
                      className="mr-4 radio-point"
                      id="active"
                      name="state"
                      checked={formik.values.state === 'active'}
                      onChange={() => handleAuctionType('active')}
                    />
                    <Form.Check
                      custom
                      type="radio"
                      label="Neaktivní"
                      className="mr-4 radio-point"
                      id="deactive"
                      name="state"
                      checked={formik.values.state === 'deactive'}
                      onChange={() => handleAuctionType('deactive')}
                    />
                  </div>
                </Form.Group>
                {props.data.serviceForAuctioneer.serviceType !== 'electronic_auction' && (
                  <div className="d-flex align-items-center mb-35">
                    <FormGroup
                      required
                      thousandSeparator
                      name="price"
                      label="Cena"
                      className="mt-0 mb-0"
                      controlClassName="w-max-205"
                      value={formik.values.price}
                      error={formik.errors.price}
                      onValueChange={(v) => formik.setFieldValue('price', v?.value || '')}
                    />
                    <span className="f-size-12 f-weight-400">Kč</span>
                  </div>
                )}
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-right">DPH *</Form.Label>
                  <div className="f-inline-control">
                    <div className="w-max-500">
                      <Select
                        size="md"
                        options={vatOptions}
                        isInvalid={!!_.get(formik.errors, 'vat')}
                        value={vatOptions.find((i) => i.value === formik.values.vat) || null}
                        onChange={handleVatChange}
                      />
                      {!!_.get(formik.errors, 'vat') && (
                        <ControlFeedback type="invalid">{_.get(formik.errors, 'vat')}</ControlFeedback>
                      )}
                    </div>
                  </div>
                </Form.Group>
                {props.data.serviceForAuctioneer.serviceType === 'electronic_auction' && (
                  <>
                    <h5>Nemovitosti</h5>
                    <div className="d-flex align-items-center mb-35">
                      <FormGroup
                        thousandSeparator
                        name="auctioneerServiceElectronicAuction.realEstate15000"
                        label="0 - 15 000:"
                        className="mt-0 mb-0"
                        controlClassName="w-max-205"
                        value={formik.values.auctioneerServiceElectronicAuction.realEstate15000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_realEstate15000')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.realEstate15000', v?.value || '')
                        }
                      />
                      <span className="f-size-12 f-weight-400">Kč</span>
                    </div>
                    <div className="d-flex align-items-center mb-35">
                      <FormGroup
                        thousandSeparator
                        name="auctioneerServiceElectronicAuction.realEstate150000"
                        label="15 001 - 150 000:"
                        className="mt-0 mb-0"
                        controlClassName="w-max-205"
                        value={formik.values.auctioneerServiceElectronicAuction.realEstate150000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_realEstate150000')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.realEstate150000', v?.value || '')
                        }
                      />
                      <span className="f-size-12 f-weight-400">Kč</span>
                    </div>
                    <div className="d-flex align-items-center mb-35">
                      <FormGroup
                        thousandSeparator
                        name="auctioneerServiceElectronicAuction.realEstate750000"
                        label="150 001 - 750 000:"
                        className="mt-0 mb-0"
                        controlClassName="w-max-205"
                        value={formik.values.auctioneerServiceElectronicAuction.realEstate750000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_realEstate750000')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.realEstate750000', v?.value || '')
                        }
                      />
                      <span className="f-size-12 f-weight-400">Kč</span>
                    </div>
                    <div className="d-flex align-items-center mb-35">
                      <FormGroup
                        thousandSeparator
                        name="auctioneerServiceElectronicAuction.realEstate999999999"
                        label="750 001 - 999 999 999:"
                        className="mt-0 mb-0"
                        controlClassName="w-max-205"
                        value={formik.values.auctioneerServiceElectronicAuction.realEstate999999999}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_realEstate999999999')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.realEstate999999999', v?.value || '')
                        }
                      />
                      <span className="f-size-12 f-weight-400">Kč</span>
                    </div>
                    <h5>Movité a ostatní</h5>
                    <Form.Group className="f-inline-group multiline-input">
                      <Form.Label className="f-inline-label text-right main-label">0 - 15 000:</Form.Label>
                      <FormGroup
                        thousandSeparator
                        name="auctioneerServiceElectronicAuction.movablePercent15000"
                        label="Procenta:"
                        value={formik.values.auctioneerServiceElectronicAuction.movablePercent15000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_movablePercent15000')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.movablePercent15000', v?.value || '')
                        }
                      />
                      <FormGroup
                        thousandSeparator
                        name="auctioneerServiceElectronicAuction.movablePlus15000"
                        label="Plus:"
                        value={formik.values.auctioneerServiceElectronicAuction.movablePlus15000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_movablePlus15000')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.movablePlus15000', v?.value || '')
                        }
                      />
                      <FormGroup
                        thousandSeparator
                        name="auctioneerServiceElectronicAuction.movableMinimum15000"
                        label="Minimum:"
                        value={formik.values.auctioneerServiceElectronicAuction.movableMinimum15000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_movableMinimum15000')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.movableMinimum15000', v?.value || '')
                        }
                      />
                    </Form.Group>
                    <Form.Group className="f-inline-group multiline-input">
                      <Form.Label className="f-inline-label text-right main-label">15 001 - 150 000:</Form.Label>
                      <FormGroup
                        thousandSeparator
                        name="auctioneerServiceElectronicAuction.movablePercent150000"
                        label="Procenta:"
                        value={formik.values.auctioneerServiceElectronicAuction.movablePercent150000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_movablePercent150000')}
                        onValueChange={(v) =>
                          formik.setFieldValue(
                            'auctioneerServiceElectronicAuction.movablePercent150000',
                            v?.value || ''
                          )
                        }
                      />
                      <FormGroup
                        thousandSeparator
                        name="auctioneerServiceElectronicAuction.movablePlus150000"
                        label="Plus:"
                        value={formik.values.auctioneerServiceElectronicAuction.movablePlus150000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_movablePlus150000')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.movablePlus150000', v?.value || '')
                        }
                      />
                      <FormGroup
                        thousandSeparator
                        name="auctioneerServiceElectronicAuction.movableMinimum150000"
                        label="Minimum:"
                        value={formik.values.auctioneerServiceElectronicAuction.movableMinimum150000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_movableMinimum150000')}
                        onValueChange={(v) =>
                          formik.setFieldValue(
                            'auctioneerServiceElectronicAuction.movableMinimum150000',
                            v?.value || ''
                          )
                        }
                      />
                    </Form.Group>
                    <Form.Group className="f-inline-group multiline-input">
                      <Form.Label className="f-inline-label text-right main-label">150 001 - 999 999 999:</Form.Label>
                      <FormGroup
                        thousandSeparator
                        name="auctioneerServiceElectronicAuction.movablePercent999999999"
                        label="Procenta:"
                        value={formik.values.auctioneerServiceElectronicAuction.movablePercent999999999}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_movablePercent999999999')}
                        onValueChange={(v) =>
                          formik.setFieldValue(
                            'auctioneerServiceElectronicAuction.movablePercent999999999',
                            v?.value || ''
                          )
                        }
                      />
                      <FormGroup
                        thousandSeparator
                        name="auctioneerServiceElectronicAuction.movablePlus999999999"
                        label="Plus:"
                        value={formik.values.auctioneerServiceElectronicAuction.movablePlus999999999}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_movablePlus999999999')}
                        onValueChange={(v) =>
                          formik.setFieldValue(
                            'auctioneerServiceElectronicAuction.movablePlus999999999',
                            v?.value || ''
                          )
                        }
                      />
                      <FormGroup
                        thousandSeparator
                        name="auctioneerServiceElectronicAuction.movableMinimum999999999"
                        label="Minimum:"
                        value={formik.values.auctioneerServiceElectronicAuction.movableMinimum999999999}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_movableMinimum999999999')}
                        onValueChange={(v) =>
                          formik.setFieldValue(
                            'auctioneerServiceElectronicAuction.movableMinimum999999999',
                            v?.value || ''
                          )
                        }
                      />
                    </Form.Group>
                  </>
                )}
              </div>
            </div>
            <div className="mt-5">
              <Button
                variant="btn-outline-default"
                className="float-left"
                type="button"
                onClick={() => props.onClose()}
              >
                Zrušit
              </Button>
              {!formik.isSubmitting ? (
                <Button
                  type="submit"
                  variant="btn-outline-primary"
                  className="float-right"
                  disabled={formik.isSubmitting}
                >
                  Uložit službu
                </Button>
              ) : (
                <BasePreloader size={29} className="d-inline-block float-right" />
              )}
              <div className="clearfix" />
            </div>
          </Form>
        </div>
      ) : (
        <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
          <BasePreloader />
        </div>
      )}
    </div>
  );
};

export default ServiceForm;
