import React from 'react';

import { ServiceForAuctioneer } from '@types';
import { useServicesApi } from '@api/services';
import { BasePreloader } from '@components';
import { closeMessage, confirmMessage } from '@helpers/messages';
import ServiceForm from './ServiceForm';
import ServicesTable from './ServicesTable';

interface Props {}

const Services: React.FC<Props> = (props) => {
  const servicesApi = useServicesApi();
  const [loaded, setLoaded] = React.useState(false);
  const [updateItem, setUpdateItem] = React.useState(undefined as ServiceForAuctioneer | undefined);
  const [showForm, setShowForm] = React.useState(false);
  const [servicesData, setServicesData] = React.useState([] as Array<ServiceForAuctioneer>);

  React.useEffect(() => {
    loadData();
    return () => servicesApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    servicesApi
      .list()
      .then((res) => {
        setServicesData(res.data.data);
        setLoaded(true);
      })
      .catch((err) => {
        if (servicesApi.isCancel(err)) {
          return;
        }
      });
  };

  const handleAddClick = () => {
    setUpdateItem(undefined);
    setShowForm(true);
  };

  const handleUpdateClick = (item: ServiceForAuctioneer) => {
    setUpdateItem(item);
    setShowForm(true);
  };

  const handleActivateForAllClick = (item: ServiceForAuctioneer) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete tuto službu aktivovat všem?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return servicesApi.activateForAll(item.id).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  const handleDeleteClick = (item: ServiceForAuctioneer) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete smazat tuto službu?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return servicesApi.delete(item.id).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  const handleRestoreClick = (item: ServiceForAuctioneer) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete obnovit tuto službu?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return servicesApi.restore(item.id).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  const handleSave = () => {
    setShowForm(false);
    setUpdateItem(undefined);
    setLoaded(false);
    loadData();
  };

  if (!loaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div>
      {showForm ? (
        <ServiceForm
          data={updateItem}
          onClose={() => setShowForm(false)}
          onSubmit={() => setShowForm(false)}
          onSave={handleSave}
        />
      ) : (
        <ServicesTable
          data={servicesData}
          onClickAdd={handleAddClick}
          onUpdateClick={handleUpdateClick}
          onDeleteClick={handleDeleteClick}
          onRestoreClick={handleRestoreClick}
          onActivateForAllClick={handleActivateForAllClick}
        />
      )}
    </div>
  );
};

export default Services;
