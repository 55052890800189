import React from 'react';

import { Modal } from '@components';
import { AuctionService } from '@types';
import { ModalProps } from '@components/Modal/Modal';
import { getCurrencyFormat, getPercentageFormat } from '@helpers/formats';

interface Props extends ModalProps {
  data: AuctionService;
  actionText: string;
  onAction: () => void;
  onRequestClose: () => void;
}

const ServiceDetailModal: React.FC<Props> = (props) => {
  return (
    <Modal className="service-detail-modal-parent" {...props}>
      <div className="service-detail-modal">
        <h5>Podrobnosti</h5>
        <p>Daňová sazba: {getPercentageFormat(props.data.vat)}</p>
        <h5>Nemovitosti</h5>
        <div className="items-wrapper">
          <div className="items-holder">
            <p className="font-weight-bold">Cenové rozpětí</p>
            <p className="font-weight-bold">Cena</p>
            <p />
            <p />
          </div>
          <div className="items-holder">
            <p className="mr-2">0 - 15 000:</p>
            <p>{getCurrencyFormat(props.data.auctioneerServiceElectronicAuction?.realEstate15000)}</p>
            <p />
            <p />
          </div>
          <div className="items-holder">
            <p className="mr-2">15 001 - 150 000:</p>
            <p>{getCurrencyFormat(props.data.auctioneerServiceElectronicAuction?.realEstate150000)}</p>
            <p />
            <p />
          </div>
          <div className="items-holder">
            <p className="mr-2">150 001 - 750 000:</p>
            <p>{getCurrencyFormat(props.data.auctioneerServiceElectronicAuction?.realEstate750000)}</p>
            <p />
            <p />
          </div>
          <div className="items-holder">
            <p className="mr-2">750 001 - 999 999 999:</p>
            <p>{getCurrencyFormat(props.data.auctioneerServiceElectronicAuction?.realEstate999999999)}</p>
            <p />
            <p />
          </div>
        </div>
        <h5>Movité a ostatní</h5>
        <div className="items-wrapper">
          <div className="items-holder">
            <p className="font-weight-bold">Cenové rozpětí</p>
            <p className="font-weight-bold">Procenta</p>
            <p className="font-weight-bold">Plus</p>
            <p className="font-weight-bold">Minimum</p>
          </div>
          <div className="items-holder">
            <p className="mr-2">0 - 15 000:</p>
            <p>{getPercentageFormat(props.data.auctioneerServiceElectronicAuction?.movablePercent15000)}</p>
            <p>{getCurrencyFormat(props.data.auctioneerServiceElectronicAuction?.movablePlus15000)}</p>
            <p>{getCurrencyFormat(props.data.auctioneerServiceElectronicAuction?.movableMinimum15000)}</p>
          </div>
          <div className="items-holder">
            <p>15 001 - 150 000:</p>
            <p>{getPercentageFormat(props.data.auctioneerServiceElectronicAuction?.movablePercent150000)}</p>
            <p>{getCurrencyFormat(props.data.auctioneerServiceElectronicAuction?.movablePlus150000)}</p>
            <p>{getCurrencyFormat(props.data.auctioneerServiceElectronicAuction?.movableMinimum150000)}</p>
          </div>
          <div className="items-holder">
            <p>150 001 - 999 999 999:</p>
            <p>{getPercentageFormat(props.data.auctioneerServiceElectronicAuction?.movablePercent999999999)}</p>
            <p>{getCurrencyFormat(props.data.auctioneerServiceElectronicAuction?.movablePlus999999999)}</p>
            <p>{getCurrencyFormat(props.data.auctioneerServiceElectronicAuction?.movableMinimum999999999)}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ServiceDetailModal;
