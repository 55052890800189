import React from 'react';
import { Container, Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import { Button } from '@components';
import { useAuthApi } from '@api/auth';
import { getName } from '@helpers/string';
import { useApplicationStore, useAuthStore, usePageStore } from '@stores';

import MobileMenu from './components/MobileMenu';

import logo from '@assets/images/color-logo.svg';
import headerIcoUser from '@assets/images/header-ico-user-color.svg';
import headerIcoLogout from '@assets/images/header-ico-logout.svg';
import logoutIcoMobile from '@assets/images/logout-ico-mobile-color.svg';
import headerIcoLogoutColor from '@assets/images/header-logout-ico-color.svg';

const LayoutHeader: React.FC = () => {
  const authApi = useAuthApi();
  const history = useHistory();
  const authState = useAuthStore();
  const pageState = usePageStore();
  const applicationState = useApplicationStore();

  const handleLogoutClick = async (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    await authApi.logout(true);
    authState.unsetUser();
    await pageState.reload();
    history.push('/');
  };

  return (
    <div className="layout-header">
      <Container fluid className="bg-white">
        <Link to="/" className="header-logo">
          <img src={logo} alt="logo" />
        </Link>
        <div className="header-items">
          <div className="zoom-toggle">
            <Form.Check
              type="switch"
              id="header-zoom-toggle"
              checked={applicationState.zoomEnabled}
              onChange={() => applicationState.setZoomEnabled(!applicationState.zoomEnabled)}
            />
            <div
              className="action-label cursor-pointer"
              onClick={() => applicationState.setZoomEnabled(!applicationState.zoomEnabled)}
            >
              Zmenšené zobrazení
            </div>
          </div>

          <img src={headerIcoUser} alt="user" />
          <a href="/" onClick={handleLogoutClick} className="d-inline-block d-lg-none ml-25">
            <img src={logoutIcoMobile} alt="logout" />
          </a>
          <span className="d-none d-lg-inline user-name">{getName(authState.user) || authState.user?.email}</span>
          <Button
            onClick={handleLogoutClick}
            type="button"
            variant="btn-outline-primary"
            className="d-none d-lg-inline-flex"
          >
            <img src={headerIcoLogoutColor} alt="logout" className="hover-hide" />
            <img src={headerIcoLogout} alt="logout" className="hover-show" />
            Odhlásit se
          </Button>
        </div>
        <MobileMenu />
      </Container>
      <div className="divider" />
    </div>
  );
};

export default LayoutHeader;
