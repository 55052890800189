import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useAuctioneersApi } from '@api/auctioneers';
import { BasePreloader, Visible } from '@components';
import { getCurrencyFormat, getPercentageFormat } from '@helpers/formats';
import { AuctionService, PermissionGroup, UserPermission } from '@types';

import ServiceForm from './ServiceForm';
import ServiceDetailModal from './ServiceDetailModal';

import colorIcoConf from '@assets/images/color-ico-conf.svg';

interface Props {
  id: string;
  onNotFound: () => void;
}

const Services: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const [showForm, setShowForm] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [items, setItems] = React.useState([] as Array<AuctionService>);
  const [servicesLoaded, setServicesLoaded] = React.useState(false);
  const [updateItem, setUpdateItem] = React.useState(undefined as AuctionService | undefined);

  React.useEffect(() => {
    loadServices();
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadServices = (callback?: () => void) => {
    auctioneersApi
      .servicesList(props.id)
      .then((res) => {
        if (!!callback) {
          callback();
        }
        setItems(res.data.data);
        setServicesLoaded(true);
      })
      .catch((err) => {
        if (auctioneersApi.isCancel(err)) {
          return;
        }
        if (!!callback) {
          callback();
        }
        if (err?.response?.status === 404 || err?.response?.status === 403) {
          props.onNotFound();
          return;
        }
      });
  };

  const handleUpdateClick = (e: React.MouseEvent, item: AuctionService) => {
    e.preventDefault();
    setUpdateItem(item);
    setShowForm(true);
  };

  const handleSave = () => {
    setShowForm(false);
    setUpdateItem(undefined);
    loadServices();
  };

  if (!servicesLoaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div>
      <div>
        {showForm && updateItem ? (
          <ServiceForm
            data={updateItem}
            auctioneerId={props.id}
            onClose={() => setShowForm(false)}
            onSave={handleSave}
          />
        ) : (
          <>
            <h2>Ceník</h2>
            <div className="mt-4">
              <div className="responsive-table-content">
                <Table className="table-middle border-bottom-0" striped responsive>
                  <thead>
                    <tr className="b-bottom-1">
                      <th>Kód</th>
                      <th>Název</th>
                      <th className="text-center w-1">Cena</th>
                      <th className="text-center w-1">DPH</th>
                      <th className="text-center w-1">Stav</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => (
                      <tr key={`item-${index}`}>
                        <td>{item.serviceForAuctioneer.id}</td>
                        <td>{item.serviceForAuctioneer.serviceTypeTranslation}</td>
                        <td className="text-center nowrap">
                          <>
                            {item.serviceForAuctioneer.serviceType === 'electronic_auction' ? (
                              <span className="admin-service-link" onClick={() => setModalOpen(true)}>
                                Dle ceníku
                              </span>
                            ) : (
                              `${getCurrencyFormat(item.price)}`
                            )}
                          </>
                        </td>
                        <td className="text-center">{getPercentageFormat(item.vat)}</td>
                        <td className="text-center">{item.state === 'active' ? 'Aktivní' : 'Neaktivní'}</td>
                        <td className="text-right">
                          <Visible
                            permissionGroupName={PermissionGroup.auctioneerPriceList}
                            permissionName={UserPermission.canEdit}
                          >
                            <Link
                              to="/"
                              className="d-inline-flex align-items-center ml-4"
                              onClick={(e) => handleUpdateClick(e, item)}
                            >
                              <img src={colorIcoConf} alt="ico" className="mr-2" />
                              Upravit
                            </Link>
                          </Visible>
                        </td>
                        {item.serviceForAuctioneer.serviceType === 'electronic_auction' && (
                          <ServiceDetailModal
                            isOpen={modalOpen}
                            data={item}
                            actionText="Zavřít"
                            onAction={() => setModalOpen(false)}
                            title="Detail ceníku"
                            onRequestClose={() => setModalOpen(false)}
                          />
                        )}
                      </tr>
                    ))}
                    {items.length < 1 && (
                      <tr>
                        <td colSpan={100}>Nebyla nalezena žádná data.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Services;
