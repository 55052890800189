import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import * as routes from '@routes';
import { AuctionResponse } from '@types';
import { BasePreloader, Button } from '@components';
import { getCurrencyFormat } from '@helpers/formats';
import { getDateTimeFormat } from '@helpers/datetime';
import { useAuthFrontStore, usePageStore } from '@stores';
import AuctionImage from '@components/AuctionImage/AuctionImage';
import AuctionAlertBox from '@components/AuctionAlertBox/AuctionAlertBox';
import { isAuthorizedOptions, validCautionSecutiry } from '@helpers/definitions';

import bellGreenIco from '@assets/images/bell-green.svg';
import bellRedIco from '@assets/images/bell-red.svg';
import icoCloseGrey from '@assets/images/ico-close-grey.svg';
import icoCloseRed from '@assets/images/ico-close-red.svg';
import icoHeartGreen from '@assets/images/ico-heart-green.svg';
import auctionNoImage from '@assets/images/auction-no-image.svg';
import icoHeartGreyOutline from '@assets/images/ico-heart-grey-outline.svg';

interface Props {
  auction: AuctionResponse;
  primaryActionText?: React.ReactNode;
  primaryActionClassName?: string;
  primaryActionVariant?: 'btn-outline-primary' | 'btn-outline-default' | 'btn-light' | 'btn-outline-grey' | 'btn-none';
  disableFooter?: boolean;
  isFavorite?: boolean;
  isVerbose?: boolean;
  isPersonalAccount?: boolean;
  securityPaid?: boolean;
  authorizationState?: string;
  auctionSecurity?: string;
  auctionSecurityTranslation?: string;
  canLogout?: boolean;
  primaryActionLoader?: boolean;
  reload?: () => void;
  onDelete?(): any;
  onIsFavouriteChange?(isFavorite: boolean): any;
  onPrimaryActionClick?(event: React.MouseEvent): any;
}

const AuctionTile: React.FC<Props> = (props) => {
  const [isActionHovered, setIsActionHovered] = React.useState(false);
  const { isLogged } = useAuthFrontStore();
  const pageState = usePageStore();

  const renderAction = () => {
    if (typeof props.onDelete === 'function' && props.canLogout) {
      return (
        <div
          className="buyer-auction-action"
          onMouseEnter={() => setIsActionHovered(true)}
          onMouseLeave={() => setIsActionHovered(false)}
          onClick={props.onDelete}
        >
          <img src={isActionHovered ? icoCloseRed : icoCloseGrey} alt="delete" />
        </div>
      );
    }

    if (isLogged && typeof props.onIsFavouriteChange === 'function') {
      return (
        <div
          className="buyer-auction-action"
          onMouseEnter={() => setIsActionHovered(true)}
          onMouseLeave={() => setIsActionHovered(false)}
          onClick={() => (props.onIsFavouriteChange as any)(!props.auction.isFavorite)}
        >
          {props.auction.isFavorite || props.isFavorite ? (
            <img src={icoHeartGreen} alt="favourite" />
          ) : (
            <img src={icoHeartGreyOutline} alt="favourite" />
          )}
        </div>
      );
    }

    return null;
  };

  const renderContentItem = (label: string, value: React.ReactNode) => {
    return (
      <p className="item-info">
        <span className="f-weight-700 mr-2">{label}</span>
        {value}
      </p>
    );
  };

  const renderContentTimeframe = () => {
    return (
      <>
        {props.auction.startDt && (
          <p>
            <img src={bellGreenIco} style={{ marginRight: '8px' }} alt="" />
            {props.auction.startDt ? (
              <>
                {props.auction.translations?.auction_start || 'auction_start'}{' '}
                {getDateTimeFormat(props.auction.startDt)}
              </>
            ) : (
              <>{props.auction.translations?.auction_start || 'auction_start_null'}</>
            )}
          </p>
        )}
        {(props.auction.endDt || props.auction.realEndDt) && (
          <p>
            <img src={bellRedIco} style={{ marginRight: '8px' }} alt="" />
            {props.auction.translations?.auction_end || 'auction_end'}{' '}
            {getDateTimeFormat(props.auction.realEndDt || props.auction.endDt, {
              withSeconds: !!props.auction.realEndDt,
            })}
          </p>
        )}
      </>
    );
  };

  const getTypeText = () => {
    return props.auction.auctionCategory.parentTitle
      ? `${props.auction.auctionCategory.parentTitle} - ${props.auction.auctionCategory.title}:`
      : props.auction.auctionCategory.title;
  };

  const renderPrimaryAction = () => {
    if (!props.primaryActionText) {
      return null;
    }

    return (
      <div className="buyer-auction-more-info">
        {props.primaryActionLoader ? (
          <div className="mt-1 mb-1">
            <BasePreloader size={26} />
          </div>
        ) : (
          <Button
            onClick={props.onPrimaryActionClick}
            variant={props.primaryActionVariant ? props.primaryActionVariant : undefined}
            className={cx([{ 'is-active': props.auction.isJoined }, props.primaryActionClassName])}
          >
            {props.primaryActionText}
          </Button>
        )}
      </div>
    );
  };

  const renderContentPrices = () => {
    return (
      <div className="content-bottom">
        <div className="item-price">
          {getCurrencyFormat(props.auction.winnerBid?.bidPrice || props.auction.minimalBid)}
        </div>
        {props.isVerbose && (
          <div className="buyer-auction-guess-price">
            <div className="buyer-auction-guess-price-label">Cena určená:</div>
            <div className="buyer-auction-guess-price-value">{getCurrencyFormat(props.auction.estimatedPrice)}</div>
          </div>
        )}
        {renderPrimaryAction()}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div className="item-image">
          {!!props.auction.parentStatus && (
            <div className="auction-tag text-left">{props.auction.statusTranslation}</div>
          )}
          <div className="image-inner">
            <div
              className={cx([
                'item-centered',
                {
                  'no-image': !(
                    !!props.auction?.auctionMedia &&
                    props.auction.auctionMedia.length > 0 &&
                    !!props.auction.auctionMedia[0]?.media?.hash
                  ),
                },
              ])}
            >
              <Link
                className="text-d-none"
                to={pageState.getPagePath(
                  props.auction.auctionType === 'auction'
                    ? routes.front.AUCTION_DETAIL
                    : routes.front.AUCTION_PUBLIC_DETAIL,
                  { ':id': props.auction.id }
                )}
              >
                {!!props.auction?.auctionMedia &&
                props.auction.auctionMedia.length > 0 &&
                !!props.auction.auctionMedia[0]?.media?.hash ? (
                  <AuctionImage hash={props.auction.auctionMedia[0].media.hash} size="auction.crop" />
                ) : (
                  <img src={auctionNoImage} alt="auction" />
                )}
              </Link>
            </div>
          </div>
        </div>
        <div className="item-content">
          <div className="content-inner">
            <h2 className="item-title">
              <Link
                className="text-d-none"
                to={pageState.getPagePath(
                  props.auction.auctionType === 'auction'
                    ? routes.front.AUCTION_DETAIL
                    : routes.front.AUCTION_PUBLIC_DETAIL,
                  { ':id': props.auction.id }
                )}
              >
                {props.auction.title}
              </Link>
            </h2>
            {renderContentItem('Typ:', props.auction.typeTranslation || props.auction.auctionType)}
            {renderContentItem(getTypeText(), `čj: ${props.auction.number}`)}
            {renderContentItem(
              'Kraj:',
              `${props?.auction?.auctionAddress?.region?.name}, ${props.auction?.auctionAddress?.city}` || '-'
            )}
            {props.auction?.winnerBid?.bidPrice &&
              props.auction.minimalBid &&
              renderContentItem(
                `${props.auction.translations?.auction_start_price || 'auction_start_price'}:`,
                getCurrencyFormat(props.auction.minimalBid)
              )}
            {renderContentItem(
              `${props.auction.translations?.auction_security_pay || 'auction_security_pay'}:`,
              parseFloat(props.auction.cautionDeposit || '0')
                ? getCurrencyFormat(props.auction.cautionDeposit)
                : `Není vyžadována`
            )}
          </div>
          <div className="content-middle">{renderContentTimeframe()}</div>
          {renderContentPrices()}
        </div>
      </>
    );
  };

  const renderFooterStep = (text: string, isActive: boolean, index: number) => {
    return (
      <div className={cx('item-footer-step', { 'is-active': isActive })}>
        <div className="step-index">{index + 1}</div>
        <div className="step-text">{text}</div>
      </div>
    );
  };

  const renderFooter = () => {
    if (props.disableFooter) {
      return null;
    }

    return (
      <div className="item-footer">
        <div className="footer-title">{props.auction.translations?.auction_conditions || 'auction_conditions'}:</div>
        {renderFooterStep('Jste přihlášen', true, 0)}
        {renderFooterStep(
          isAuthorizedOptions.some((option) => option === props.authorizationState)
            ? 'Totožnost ověřena'
            : 'Nutno doložit svojí totožnost',
          isAuthorizedOptions.some((option) => option === props.authorizationState),
          1
        )}
        {parseInt(props.auction.cautionDeposit) > 0 &&
          renderFooterStep(
            validCautionSecutiry.some((option) => option === props.auctionSecurity)
              ? `${props.auctionSecurityTranslation}`
              : `${props.auction.translations?.missing_auction_security_pay}`,
            validCautionSecutiry.some((option) => option === props.auctionSecurity),
            2
          )}
      </div>
    );
  };

  return (
    <div className="list-item">
      <div className="item-wrapper">
        {renderContent()}
        {renderAction()}
      </div>

      {!!props.auction && props.isPersonalAccount && (
        <div className="informations-alert-box">
          <AuctionAlertBox auction={props.auction} reload={props.reload} />
        </div>
      )}
      {renderFooter()}
    </div>
  );
};

export default AuctionTile;
