import React, { useState, MouseEvent, useEffect } from 'react';
import _ from 'lodash';
import { Table } from 'react-bootstrap';

import { BasePreloader, Button } from '@components';
import { useAuctionProtestApi } from '@api/auctionProtest';
import { AuctionResponse, BaseObjectType, ProtestAdminResponse } from '@types';

import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorIcoCheck from '@assets/images/color-ico-check.svg';
import { ProtestRejectedModal } from './ProtestRejectedModal';
import { ProtestExtendModal } from './ProtestExtendModal';
import { getName } from '@helpers/string';

interface Props {
  auction: AuctionResponse;
  translations: BaseObjectType;
}

let protestsTimeout: NodeJS.Timer | undefined = undefined;

const ProtestTable: React.FC<Props> = (props) => {
  const auctionProtestApi = useAuctionProtestApi();
  const [data, setData] = useState<ProtestAdminResponse[]>([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showExtendModal, setShowExtendModal] = useState(false);
  const [rejectedModal, setRejectedModal] = useState<ProtestAdminResponse | undefined>();

  useEffect(() => {
    loadData();
    return () => {
      auctionProtestApi.cancelAllRequests();
      if (!!protestsTimeout) {
        clearTimeout(protestsTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTranslation = (key: string) => _.get(props.translations, key, key);

  const loadData = async () => {
    if (!!protestsTimeout) {
      clearTimeout(protestsTimeout);
    }
    try {
      const response = await auctionProtestApi.getAdminProtests(props.auction.id);
      setShowLoader(false);
      setData(response.data.data);
      protestsTimeout = setTimeout(() => loadData(), 5000);
    } catch (err) {
      if (auctionProtestApi.isCancel(err)) {
        return;
      }
      protestsTimeout = setTimeout(() => loadData(), 5000);
    }
  };

  const canShowExtendBtn = () => {
    let result = false;
    for (const index in data) {
      const item = data[index];
      if (item.status === 'accept' && !item.processed) {
        result = true;
      }
      if (item.status === 'waiting') {
        return false;
      }
    }
    return result;
  };

  const handleAcceptClick = async (e: MouseEvent<HTMLAnchorElement>, item: ProtestAdminResponse) => {
    e.preventDefault();
    try {
      await auctionProtestApi.acceptAdminProtest(props.auction.id, item.id);
      await loadData();
    } catch (err: any) {
      if (auctionProtestApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleRejectedClick = (e: MouseEvent<HTMLAnchorElement>, item: ProtestAdminResponse) => {
    e.preventDefault();
    setRejectedModal(item);
  };

  const handleRejectedModalClose = async (reload: boolean) => {
    if (reload) {
      await loadData();
    }
    setRejectedModal(undefined);
  };

  const handleExtendModalClose = async (reload: boolean) => {
    if (reload) {
      await loadData();
    }
    setShowExtendModal(false);
  };

  return (
    <div>
      {!!rejectedModal && (
        <ProtestRejectedModal
          item={rejectedModal}
          auction={props.auction}
          isOpen={!!rejectedModal}
          translations={props.translations}
          onClose={handleRejectedModalClose}
        />
      )}
      {showExtendModal && (
        <ProtestExtendModal
          auction={props.auction}
          isOpen={showExtendModal}
          translations={props.translations}
          onClose={handleExtendModalClose}
        />
      )}
      {!showLoader ? (
        <>
          {canShowExtendBtn() && (
            <Button type="button" className="mb-4" onClick={() => setShowExtendModal(true)}>
              {getTranslation('protect_extend_btn')}
            </Button>
          )}
          <div className="responsive-table-content">
            <Table className="table-middle b-bottom-0">
              <thead>
                <tr>
                  <th style={{ width: '10%' }}>{getTranslation('protest_column_id')}</th>
                  <th style={{ width: '30%' }}>{getTranslation('protest_column_message')}</th>
                  <th style={{ width: '30%' }}>{getTranslation('protest_column_user')}</th>
                  <th style={{ width: '30%' }}>{getTranslation('protest_column_reason')}</th>
                  <th style={{ width: '10%' }}>{getTranslation('protest_column_status')}</th>
                  <th style={{ width: '1%' }} />
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={`protest-${item.id}`} className="border-top">
                    <td>{item.id}</td>
                    <td>{item.message}</td>
                    <td>{item.userAuction.user?.company || getName(item.userAuction.user) || '-'}</td>
                    <td>{item.reason || '-'}</td>
                    <td>{item.statusTranslation || item.status}</td>
                    <td>
                      <div className="d-flex">
                        <a
                          href="/"
                          className="d-inline-flex align-items-center ml-4"
                          onClick={(e) => handleAcceptClick(e, item)}
                        >
                          <img src={colorIcoCheck} alt="ico" className="mr-2" />
                          {getTranslation('protest_btn_accept')}
                        </a>
                        <a
                          href="/"
                          className="d-inline-flex align-items-center ml-4"
                          onClick={(e) => handleRejectedClick(e, item)}
                        >
                          <img src={colorIcoDel} alt="ico" className="mr-2" />
                          {getTranslation('protest_btn_rejected')}
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
                {data.length < 1 && (
                  <tr>
                    <td colSpan={100}>{getTranslation('protest_table_empty')}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <BasePreloader size={20} />
      )}
    </div>
  );
};

export default ProtestTable;
