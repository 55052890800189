import React from 'react';

import { Modal } from '@components';
import { ModalProps } from '@components/Modal/Modal';
import { ServiceForAuctioneer } from '@types';

interface Props extends ModalProps {
  data: ServiceForAuctioneer;
  actionText: string;
  onAction: () => void;
  onRequestClose: () => void;
}

const ServiceDetailModal: React.FC<Props> = (props) => {
  return (
    <Modal className="service-detail-modal-parent" {...props}>
      <div className="service-detail-modal">
        <h5>Podrobnosti</h5>
        <p>Daňová sazba: {props.data.vat} %</p>
        <h5>Nemovitosti</h5>
        <div className="items-wrapper">
          <div className="items-holder">
            <p className="font-weight-bold">Cenové rozpětí</p>
            <p className="font-weight-bold">Cena</p>
            <p></p>
            <p></p>
          </div>
          <div className="items-holder">
            <p className="mr-2">0 - 15 000:</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.realEstate15000} Kč</p>
            <p></p>
            <p></p>
          </div>
          <div className="items-holder">
            <p className="mr-2">15 001 - 150 000:</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.realEstate150000} Kč</p>
            <p></p>
            <p></p>
          </div>
          <div className="items-holder">
            <p className="mr-2">150 001 - 750 000:</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.realEstate750000} Kč</p>
            <p></p>
            <p></p>
          </div>
          <div className="items-holder">
            <p className="mr-2">750 001 - 999 999 999:</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.realEstate999999999} Kč</p>
            <p></p>
            <p></p>
          </div>
        </div>
        <h5>Movité a ostatní</h5>
        <div className="items-wrapper">
          <div className="items-holder">
            <p className="font-weight-bold">Cenové rozpětí</p>
            <p className="font-weight-bold">Procenta</p>
            <p className="font-weight-bold">Plus</p>
            <p className="font-weight-bold">Minimum</p>
          </div>
          <div className="items-holder">
            <p className="mr-2">0 - 15 000:</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.movablePercent15000} %</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.movablePlus15000} Kč</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.movableMinimum15000} Kč</p>
          </div>
          <div className="items-holder">
            <p>15 001 - 150 000:</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.movablePercent150000} %</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.movablePlus150000} Kč</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.movableMinimum150000} Kč</p>
          </div>
          <div className="items-holder">
            <p>150 001 - 999 999 999:</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.movablePercent999999999} %</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.movablePlus999999999} Kč</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.movableMinimum999999999} Kč</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ServiceDetailModal;
