import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { AuctioneerUserResponse, PermissionGroup, UserPermission } from '@types';
import { useCurrentUserRoleChecks } from '@stores';
import { useAllowedUserStates, useAllowedUserRoles } from '@stores/auth';
import { BasePreloader, Button, useVisibility, Visible } from '@components';

import icoInfo from '@assets/images/ico-info.svg';
import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';
import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';
import colorIcoRestore from '@assets/images/color-ico-restore.svg';
import icoCheckGreen from '@assets/images/ico-check-green.svg';
import { getName } from '@helpers/string';

interface Props {
  data: Array<AuctioneerUserResponse>;
  onClickAdd: () => void;
  onUpdateClick: (item: AuctioneerUserResponse) => void;
  onDeleteClick: (item: AuctioneerUserResponse) => void;
  onRestoreClick: (item: AuctioneerUserResponse) => void;
}

const UsersTable: React.FC<Props> = (props) => {
  const { shouldBeVisible } = useVisibility();
  const currentUserRoleChecks = useCurrentUserRoleChecks();

  const { resolveUserStateText, isLoading: isLoadingAllowedUserStates } = useAllowedUserStates();
  const { resolveUserRoleText, isLoading: isLoadingAllowedUserRoles } = useAllowedUserRoles('auctioneer');

  const handleCreateButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    props.onClickAdd();
  };

  const handleUpdateClick = (e: React.MouseEvent, item: AuctioneerUserResponse) => {
    e.preventDefault();
    props.onUpdateClick(item);
  };

  const handleDeleteClick = (e: React.MouseEvent, item: AuctioneerUserResponse) => {
    e.preventDefault();
    props.onDeleteClick(item);
  };

  const handleRestoreClick = (e: React.MouseEvent, item: AuctioneerUserResponse) => {
    e.preventDefault();
    props.onRestoreClick(item);
  };

  const renderDeleteItem = (item: AuctioneerUserResponse): React.ReactNode => {
    if (
      !shouldBeVisible({
        or: [
          {
            permissionGroupName: PermissionGroup.auctioneerEmployees,
            permissionName: UserPermission.canDelete,
          },
          {
            permissionGroupName: PermissionGroup.myCompanyEmployees,
            permissionName: UserPermission.canDelete,
          },
        ],
      })
    ) {
      return null;
    }

    if (item.user.state !== 'deleted') {
      return (
        <Link to="/" className="d-inline-flex align-items-center ml-4" onClick={(e) => handleDeleteClick(e, item)}>
          <img src={colorIcoDel} alt="ico" className="mr-2" />
          Smazat
        </Link>
      );
    }

    if (item.user.state === 'deleted' && currentUserRoleChecks.isOfRoleSuperAdmin()) {
      return (
        <Link to="/" className="d-inline-flex align-items-center ml-4" onClick={(e) => handleRestoreClick(e, item)}>
          <img src={colorIcoRestore} alt="ico" className="mr-2" />
          Obnovit
        </Link>
      );
    }

    return null;
  };

  if (isLoadingAllowedUserRoles || isLoadingAllowedUserStates) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div>
      <div>
        <h2>Uživatelé</h2>
        <Visible
          or={[
            {
              permissionGroupName: PermissionGroup.auctioneerEmployees,
              permissionName: UserPermission.canCreate,
            },
            {
              permissionGroupName: PermissionGroup.myCompanyEmployees,
              permissionName: UserPermission.canCreate,
            },
          ]}
        >
          <Button onClick={handleCreateButtonClick} className="f-size-12 f-weight-400 mt-3">
            <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
            <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
            Přidat uživatele
          </Button>
        </Visible>
      </div>
      <div className="mt-4">
        <div className="responsive-table-content">
          <Table className="table-middle border-bottom-0" striped responsive>
            <thead>
              <tr>
                <th>Jméno a přijmení</th>
                <th>Tel.:</th>
                <th>E-mail</th>
                <th>Funkce</th>
                <th>
                  Oprávnění
                  <OverlayTrigger
                    key="table-permission"
                    placement="top"
                    overlay={
                      <Tooltip id="table-permission">
                        <div>Oprávnění uživatele</div>
                      </Tooltip>
                    }
                  >
                    <img src={icoInfo} alt="ico" className="ml-2" />
                  </OverlayTrigger>
                </th>
                <th>Stav</th>
                <th className="text-center">Kontakt pro komunikaci</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {props.data.length < 1 && (
                <tr>
                  <td className="text-left" colSpan={100}>
                    Nebyla nalezena žádná data.
                  </td>
                </tr>
              )}
              {props.data.map((item, index) => (
                <tr key={`item-${index}`}>
                  <td className="text-left">{getName(item.user) || '-'}</td>
                  <td>{item.user.phone || '-'}</td>
                  <td>{item.user.email || '-'}</td>
                  <td>{item.user.userFunction || '-'}</td>
                  <td>{resolveUserRoleText(item.user.role)}</td>
                  <td>{resolveUserStateText(item.user.state)}</td>
                  <td className="text-center">{item.user.mainEmail && <img src={icoCheckGreen} alt="ico" />}</td>
                  <td className="text-right">
                    <Visible
                      or={[
                        {
                          permissionGroupName: PermissionGroup.auctioneerEmployees,
                          permissionName: UserPermission.canEdit,
                        },
                        {
                          permissionGroupName: PermissionGroup.myCompanyEmployees,
                          permissionName: UserPermission.canEdit,
                        },
                      ]}
                    >
                      <Link
                        to="/"
                        className="d-inline-flex align-items-center"
                        onClick={(e) => handleUpdateClick(e, item)}
                      >
                        <img src={colorIcoConf} alt="ico" className="mr-2" />
                        Upravit
                      </Link>
                    </Visible>
                  </td>
                  <td>{renderDeleteItem(item)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default UsersTable;
