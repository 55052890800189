import React from 'react';
import { FormikValues, useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import _ from 'lodash';

import { BaseSelectType, ServiceForAuctioneer } from '@types';
import { useServicesApi } from '@api/services';
import { BasePreloader, FormGroup, Button, Select, ControlFeedback } from '@components';
import { ValueType } from 'react-select';

interface Props {
  data?: ServiceForAuctioneer;
  onSubmit: () => void;
  onClose: () => void;
  onSave: () => void;
}

interface TypeSelectOptionType {
  value: string;
  label: string;
}

const ServiceForm: React.FC<Props> = (props) => {
  const servicesApi = useServicesApi();

  const [servicesOptions, setServicesOptions] = React.useState<BaseSelectType[]>([]);
  const [vatOptions, setVatOptions] = React.useState<BaseSelectType[]>([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: props.data?.price || undefined,
      serviceType: props.data?.serviceType || '',
      vat: props.data?.vat || '',
      serviceForAuctioneerElectronicAuction: {
        realEstate15000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.realEstate15000', ''),
        realEstate150000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.realEstate150000', ''),
        realEstate750000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.realEstate750000', ''),
        realEstate999999999: _.get(props.data, 'serviceForAuctioneerElectronicAuction.realEstate999999999', ''),
        movablePercent15000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.movablePercent15000', ''),
        movablePlus15000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.movablePlus15000', ''),
        movableMinimum15000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.movableMinimum15000', ''),
        movablePercent150000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.movablePercent150000', ''),
        movablePlus150000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.movablePlus150000', ''),
        movableMinimum150000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.movableMinimum150000', ''),
        movablePercent999999999: _.get(props.data, 'serviceForAuctioneerElectronicAuction.movablePercent999999999', ''),
        movablePlus999999999: _.get(props.data, 'serviceForAuctioneerElectronicAuction.movablePlus999999999', ''),
        movableMinimum999999999: _.get(props.data, 'serviceForAuctioneerElectronicAuction.movableMinimum999999999', ''),
      },
    },
    onSubmit: (values) => handleSubmit(values),
  });

  React.useEffect(() => {
    loadServicesOptions();
    loadVatOptions();
    return () => servicesApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadServicesOptions = async () => {
    try {
      const response = await servicesApi.servicesEnum();
      setServicesOptions(
        response.data.data.map((item) => ({
          value: item.type,
          label: item.translated,
        }))
      );
    } catch (err) {
      if (servicesApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadVatOptions = async () => {
    try {
      const response = await servicesApi.vatEnum();
      setVatOptions(
        response.data.data.map((item) => ({
          value: item.type,
          label: item.translated,
        }))
      );
    } catch (err) {
      if (servicesApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleSubmit = (values: FormikValues) => {
    const omitInputs = ['serviceForAuctioneerElectronicAuction', 'validityToDt'];
    const omitElectronicInputs = ['price'];
    const inputs =
      values.serviceType === 'electronic_auction' ? _.omit(values, omitElectronicInputs) : _.omit(values, omitInputs);
    (!!props.data
      ? servicesApi.update(props.data.id, inputs as Partial<ServiceForAuctioneer>)
      : servicesApi.create(inputs as Partial<ServiceForAuctioneer>)
    )
      .then(() => {
        props.onSave();
      })
      .catch((err) => {
        if (servicesApi.isCancel(err)) {
          return;
        }
        const errors = err.response?.data?.errors || {};
        Object.getOwnPropertyNames(errors).map((prop) => {
          formik.setFieldError(prop, errors[prop][0]);
          return prop;
        });
        formik.setSubmitting(false);
      });
  };

  const handleTypeChange = (value: ValueType<TypeSelectOptionType, boolean>) => {
    const itemValue = value as TypeSelectOptionType;
    formik.setFieldValue('serviceType', itemValue?.value || '');
  };

  const handleVatChange = (value: ValueType<TypeSelectOptionType, boolean>) => {
    const itemValue = value as TypeSelectOptionType;
    formik.setFieldValue('vat', itemValue?.value || '');
  };

  const getSuffix = (type: string) => {
    switch (type) {
      case 'ad_reality_portal':
        return 'Kč / den';
      case 'processing_and_control_data':
        return 'Kč';
      case 'ad_car_week':
        return 'Kč / týden';
      default:
        return '';
    }
  };

  return (
    <div>
      <h2>{!!props.data ? 'Upravit službu' : 'Přidat službu'}</h2>
      <div>
        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-5">
          <div className="responsive-table-content admin-services ">
            <div className=" pt-1">
              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label text-right">Služba *</Form.Label>
                <div className="f-inline-control">
                  <div className="w-max-500">
                    <Select
                      size="md"
                      options={servicesOptions}
                      isInvalid={!!formik.errors.serviceType}
                      value={servicesOptions.find((i) => i.value === formik.values.serviceType) || null}
                      onChange={handleTypeChange}
                    />
                    {!!formik.errors.serviceType && (
                      <ControlFeedback type="invalid">{formik.errors.serviceType}</ControlFeedback>
                    )}
                  </div>
                </div>
              </Form.Group>
              {formik.values.serviceType !== 'electronic_auction' && (
                <div className="d-flex align-items-center mb-35 ">
                  <FormGroup
                    required
                    thousandSeparator
                    name="price"
                    label="Doporučená cena"
                    className="mt-0 mb-0"
                    controlClassName="w-max-205"
                    value={formik.values.price}
                    error={formik.errors.price}
                    onValueChange={(val) => formik.setFieldValue('price', val?.value || '')}
                  />
                  <span className="f-size-12 f-weight-400 flex-shrink-0 mt-4 ml-2 ml-md-0 mt-md-0">
                    {getSuffix(formik.values.serviceType)}
                  </span>
                </div>
              )}
              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label text-right">DPH</Form.Label>
                <div className="f-inline-control">
                  <div className="w-max-500">
                    <Select
                      size="md"
                      options={vatOptions}
                      isInvalid={!!_.get(formik.errors, 'vat')}
                      value={vatOptions.find((i) => i.value === formik.values?.vat) || null}
                      onChange={handleVatChange}
                    />
                    {!!_.get(formik.errors, 'vat') && (
                      <ControlFeedback type="invalid">{_.get(formik.errors, 'vat')}</ControlFeedback>
                    )}
                  </div>
                </div>
              </Form.Group>
              {formik.values.serviceType === 'electronic_auction' && (
                <>
                  <h5>Nemovitosti</h5>
                  <div className="d-flex align-items-center mb-35">
                    <FormGroup
                      thousandSeparator
                      name="serviceForAuctioneerElectronicAuction.realEstate15000"
                      label="0 - 15 000:"
                      className="mt-0 mb-0"
                      controlClassName="w-max-205"
                      value={formik.values.serviceForAuctioneerElectronicAuction.realEstate15000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_realEstate15000')}
                      onValueChange={(v) =>
                        formik.setFieldValue('serviceForAuctioneerElectronicAuction.realEstate15000', v?.value || '')
                      }
                    />
                    <span className="f-size-12 f-weight-400">Kč</span>
                  </div>
                  <div className="d-flex align-items-center mb-35">
                    <FormGroup
                      thousandSeparator
                      name="serviceForAuctioneerElectronicAuction.realEstate150000"
                      label="15 001 - 150 000:"
                      className="mt-0 mb-0"
                      controlClassName="w-max-205"
                      value={formik.values.serviceForAuctioneerElectronicAuction.realEstate150000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_realEstate150000')}
                      onValueChange={(v) =>
                        formik.setFieldValue('serviceForAuctioneerElectronicAuction.realEstate150000', v?.value || '')
                      }
                    />
                    <span className="f-size-12 f-weight-400">Kč</span>
                  </div>
                  <div className="d-flex align-items-center mb-35">
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.realEstate750000"
                      label="150 001 - 750 000:"
                      className="mt-0 mb-0"
                      controlClassName="w-max-205"
                      value={formik.values.serviceForAuctioneerElectronicAuction.realEstate750000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_realEstate750000')}
                      onValueChange={(v) =>
                        formik.setFieldValue('serviceForAuctioneerElectronicAuction.realEstate750000', v?.value || '')
                      }
                    />
                    <span className="f-size-12 f-weight-400">Kč</span>
                  </div>
                  <div className="d-flex align-items-center mb-35">
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.realEstate999999999"
                      label="750 001 - 999 999 999:"
                      className="mt-0 mb-0"
                      controlClassName="w-max-205"
                      value={formik.values.serviceForAuctioneerElectronicAuction.realEstate999999999}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_realEstate999999999')}
                      onValueChange={(v) =>
                        formik.setFieldValue(
                          'serviceForAuctioneerElectronicAuction.realEstate999999999',
                          v?.value || ''
                        )
                      }
                    />
                    <span className="f-size-12 f-weight-400">Kč</span>
                  </div>
                  <h5>Movité a ostatní</h5>
                  <Form.Group className="f-inline-group multiline-input">
                    <Form.Label className="f-inline-label text-right main-label">0 - 15 000:</Form.Label>
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.movablePercent15000"
                      label="Procenta:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.movablePercent15000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_movablePercent15000')}
                      onValueChange={(v) =>
                        formik.setFieldValue(
                          'serviceForAuctioneerElectronicAuction.movablePercent15000',
                          v?.value || ''
                        )
                      }
                    />
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.movablePlus15000"
                      label="Plus:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.movablePlus15000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_movablePlus15000')}
                      onValueChange={(v) =>
                        formik.setFieldValue('serviceForAuctioneerElectronicAuction.movablePlus15000', v?.value || '')
                      }
                    />
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.movableMinimum15000"
                      label="Minimum:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.movableMinimum15000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_movableMinimum15000')}
                      onValueChange={(v) =>
                        formik.setFieldValue(
                          'serviceForAuctioneerElectronicAuction.movableMinimum15000',
                          v?.value || ''
                        )
                      }
                    />
                  </Form.Group>
                  <Form.Group className="f-inline-group multiline-input">
                    <Form.Label className="f-inline-label text-right main-label">15 001 - 150 000:</Form.Label>
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.movablePercent150000"
                      label="Procenta:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.movablePercent150000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_movablePercent150000')}
                      onValueChange={(v) =>
                        formik.setFieldValue(
                          'serviceForAuctioneerElectronicAuction.movablePercent150000',
                          v?.value || ''
                        )
                      }
                    />
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.movablePlus150000"
                      label="Plus:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.movablePlus150000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_movablePlus150000')}
                      onValueChange={(v) =>
                        formik.setFieldValue('serviceForAuctioneerElectronicAuction.movablePlus150000', v?.value || '')
                      }
                    />
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.movableMinimum150000"
                      label="Minimum:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.movableMinimum150000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_movableMinimum150000')}
                      onValueChange={(v) =>
                        formik.setFieldValue(
                          'serviceForAuctioneerElectronicAuction.movableMinimum150000',
                          v?.value || ''
                        )
                      }
                    />
                  </Form.Group>
                  <Form.Group className="f-inline-group multiline-input">
                    <Form.Label className="f-inline-label text-right main-label">150 001 - 999 999 999:</Form.Label>
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.movablePercent999999999"
                      label="Procenta:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.movablePercent999999999}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_movablePercent999999999')}
                      onValueChange={(v) =>
                        formik.setFieldValue(
                          'serviceForAuctioneerElectronicAuction.movablePercent999999999',
                          v?.value || ''
                        )
                      }
                    />
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.movablePlus999999999"
                      label="Plus:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.movablePlus999999999}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_movablePlus999999999')}
                      onValueChange={(v) =>
                        formik.setFieldValue(
                          'serviceForAuctioneerElectronicAuction.movablePlus999999999',
                          v?.value || ''
                        )
                      }
                    />
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.movableMinimum999999999"
                      label="Minimum:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.movableMinimum999999999}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_movableMinimum999999999')}
                      onValueChange={(v) =>
                        formik.setFieldValue(
                          'serviceForAuctioneerElectronicAuction.movableMinimum999999999',
                          v?.value || ''
                        )
                      }
                    />
                  </Form.Group>
                </>
              )}
            </div>
          </div>
          <div className="mt-5">
            <Button variant="btn-outline-default" className="float-left" type="button" onClick={() => props.onClose()}>
              Zrušit
            </Button>
            {!formik.isSubmitting ? (
              <Button
                type="submit"
                variant="btn-outline-primary"
                className="float-right"
                disabled={formik.isSubmitting}
              >
                Uložit službu
              </Button>
            ) : (
              <BasePreloader size={29} className="d-inline-block float-right" />
            )}
            <div className="clearfix" />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ServiceForm;
