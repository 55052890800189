import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { DocumentState, UserDocument } from '@types';
import { useDocumentsApi } from '@api/documents';
import * as routes from '@routes';
import { BasePage, Pagination, BasePreloader, AdminBoxContent } from '@components';
import { closeMessage, confirmMessage } from '@helpers/messages';
import { usePageStore } from '@stores';
import FiltersForm from './components/FiltersForm';
import ItemsTable from './components/ItemsTable';
import RejectModal from './components/RejectModal';

const DocumentsPage: React.FC = () => {
  const location = useLocation();
  const pageState = usePageStore();
  const documentsApi = useDocumentsApi();
  const page = pageState.getPage(routes.admin.DOCUMENTS);
  const [documentBeingRejected, setDocumentBeingRejected] = React.useState<UserDocument | null>(null);
  const [loaded, setLoaded] = React.useState(false);
  const [data, setData] = React.useState<Array<UserDocument>>([]);
  const [dataLen, setDataLen] = React.useState(0);
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    perPage: withDefault(NumberParam, 15),
    state: withDefault(StringParam, undefined),
    email: withDefault(StringParam, undefined),
    name: withDefault(StringParam, undefined),
  });

  React.useEffect(() => {
    loadData();
    return () => documentsApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, query]);

  React.useEffect(() => {
    if (!query.state) {
      setQuery({
        state: DocumentState.uploaded,
      });
    }
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    try {
      const response = await documentsApi.list(query);
      setData(response.data.data);
      setDataLen(response.data.total as number);
      setLoaded(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setLoaded(true);
    }
  };

  const handleApproveDocument = (item: UserDocument) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete schválit tento dokument?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return documentsApi.approve(item.id as any).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  const handleRejectDocument = (item: UserDocument, reason: string) => {
    documentsApi
      .reject(item.id as any, reason)
      .catch(() => closeMessage())
      .then(loadData);
  };

  return (
    <BasePage page={routes.admin.DOCUMENTS} title={page?.name}>
      <RejectModal
        title="Zamítnutí"
        isOpen={!!documentBeingRejected}
        onCancel={() => setDocumentBeingRejected(null)}
        onReject={(reason) => {
          handleRejectDocument(documentBeingRejected as any, reason);
          setDocumentBeingRejected(null);
        }}
      />
      <FiltersForm values={query} onSubmit={(values) => setQuery(values, 'push')} />
      <AdminBoxContent>
        <Row className="align-items-center">
          <Col xs={12} lg={12} className="text-left text-lg-right mt-4 mt-lg-0">
            {loaded && (
              <p className="m-0">
                Celkem <strong>{dataLen}</strong> dokumentů
              </p>
            )}
          </Col>
        </Row>
        <Row className="mt-3 pt-3">
          <Col xs={12}>
            {loaded ? (
              <div>
                <div>
                  <ItemsTable
                    data={data}
                    onApproveClick={handleApproveDocument}
                    onRejectClick={setDocumentBeingRejected}
                  />
                </div>
                {data.length > 0 && (
                  <div className="mt-2">
                    <Pagination
                      page={query.page}
                      perPage={query.perPage}
                      totalRows={dataLen}
                      onPageChange={(page) => setQuery({ ...query, page }, 'push')}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
                <BasePreloader />
              </div>
            )}
          </Col>
        </Row>
      </AdminBoxContent>
    </BasePage>
  );
};

export default DocumentsPage;
