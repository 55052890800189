import React from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { ValueType } from 'react-select';
import { useHistory } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';

import * as routes from '@routes';
import { useBuyerApi } from '@api/buyer';
import { useUsersApi } from '@api/users';
import FormControls from './FormControls';
import { UserSections } from '../UserUpdatePage';
import { usePageStore, useUserTypes } from '@stores';
import Address from '@components/RegistrationSteps/Address';
import { FormGroup, Button, Visible, Select, ControlFeedback, BasePreloader, useVisibility } from '@components';
import {
  BuyerAddress,
  BuyerFormValues,
  BuyerIndividual,
  BuyerLegal,
  BuyerSJMPV,
  User,
  UserPermission,
  UserRole,
  UserAddressTypes,
  BuyerOwnAccountWorker,
} from '@types';

interface Props {
  section: UserSections;
  user?: User;
  readOnly?: boolean;
  onUpdate?: () => void;
}

interface SelectOptionType {
  label: string;
  value: string;
}

interface AddressResult {
  index: number;
  address: BuyerAddress;
}

const UserForm: React.FC<Props> = (props) => {
  const registrationTypes = [
    { label: 'Základní registrace', value: UserRole.user },
    { label: 'Dražitel', value: UserRole.buyer },
  ];

  const history = useHistory();
  const { userTypes } = useUserTypes();
  const pageState = usePageStore();
  const usersApi = useUsersApi();
  const buyerApi = useBuyerApi();
  const { shouldBeVisible } = useVisibility();
  const [loaded, setLoaded] = React.useState(props.user === undefined || props.user?.role === UserRole.user);
  const formik = useFormik<BuyerFormValues>({
    enableReinitialize: true,
    initialValues: {
      registrationType: !!props.user ? props.user.role : registrationTypes[0].value,
      type: userTypes && userTypes[0] ? userTypes[0].value : undefined,
      email: props.user?.email || '',
      citizenship: '',
      degreePre: '',
      degreePost: '',
      firstName: '',
      lastName: '',
      birthDate: undefined,
      companyName: '',
      position: '',
      ico: '',
      dic: '',
      issuer: '',
      eBox: '',
      identityCardNumber: '',
      personalIdentificationNumber: '',
      phone: '+420',
      bankAccountNumber: '',
      bankAccountCode: '',
      address: '',
      zip: '',
      city: '',
      country: '',
      hasDifferentDeliveryAddress: false,
      deliveryAddress: '',
      deliveryZip: '',
      deliveryCity: '',
      deliveryCountry: '',
      hasDifferentInvoiceAddress: false,
      invoiceAddress: '',
      invoiceZip: '',
      invoiceCity: '',
      invoiceCountry: '',
      note: '',
      share: '',
    },
    onSubmit: () => handleSubmit(),
  });

  React.useEffect(() => {
    if (!!props.user && props.user?.role === UserRole.buyer) {
      loadData();
    }
    return () => {
      usersApi.cancelAllRequests();
      buyerApi.cancelAllRequests();
    };
    // eslint-disable-next-line
  }, [props.user]);

  const handleSubmit = () => {
    if (!props.user && formik.values.registrationType === UserRole.user) {
      createBasic();
    }
    if (!props.user && formik.values.registrationType === UserRole.buyer) {
      createBuyer();
    }
    if (!!props.user) {
      updateBuyer();
    }
  };

  const updateBuyer = async () => {
    try {
      await buyerApi.update(formik.values, props.user?.id || '');
      formik.setSubmitting(false);
      if (!!props.onUpdate) {
        props.onUpdate();
      }
    } catch (err) {
      if (!err.response) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  const createBuyer = async () => {
    try {
      const response = await buyerApi.create(formik.values, true);
      history.push(pageState.getPagePath(routes.admin.USER_LIST));
      if (shouldBeVisible({ permissionName: UserPermission.canEdit })) {
        history.push(pageState.getPagePath(routes.admin.USER_UPDATE, { ':id': response.data.data.id }));
      } else {
        history.push(pageState.getPagePath(routes.admin.USER_DETAIL, { ':id': response.data.data.id }));
      }
    } catch (err) {
      if (!err.response) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  const createBasic = async () => {
    try {
      const response = await usersApi.createUser(formik.values.email || '');
      history.push(pageState.getPagePath(routes.admin.USER_LIST));
      if (shouldBeVisible({ permissionName: UserPermission.canEdit })) {
        history.push(pageState.getPagePath(routes.admin.USER_UPDATE, { ':id': response.data.data.id }));
      } else {
        history.push(pageState.getPagePath(routes.admin.USER_DETAIL, { ':id': response.data.data.id }));
      }
    } catch (err) {
      if (!err.response) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  const loadData = async () => {
    try {
      const response = await buyerApi.detail(props.user?.id || '', true);
      const data = response.data.data;
      if (data.buyerType === 'individual') {
        formik.setValues(getDataIndividual(data as BuyerIndividual));
      }
      if (data.buyerType === 'own_account_worker') {
        formik.setValues(getDataOwnAccountWorker(data as BuyerOwnAccountWorker));
      }
      if (data.buyerType === 'legal') {
        formik.setValues(getDataLegal(data as BuyerLegal));
      }
      if (data.buyerType === 'joint_ownership' || data.buyerType === 'joint_assets') {
        formik.setValues(getDataSJMPV(data as BuyerSJMPV));
      }
      setLoaded(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const handleRegistrationTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('registrationType', itemValue?.value || '');
  };

  const handleUserTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('type', itemValue?.value || '');
  };

  const getAddress = (address: BuyerAddress[], addressType: UserAddressTypes): AddressResult | undefined => {
    for (let index = 0; index < address.length; index++) {
      if (address[index].type === addressType) {
        return {
          index,
          address: address[index],
        };
      }
    }
    return undefined;
  };

  const getDataIndividual = (data: BuyerIndividual): BuyerFormValues => {
    const deliveryAddress = getAddress(data.userAddresses, UserAddressTypes.delivery);
    const residenceAddress = getAddress(data.userAddresses, UserAddressTypes.residence);
    const invoiceAddress = getAddress(data.userAddresses, UserAddressTypes.invoice);
    const bankAccount: string[] = !!data.accountNumber ? data.accountNumber.split('/') : [];
    return {
      registrationType: props.user?.role,
      email: data.email,
      type: data.buyerType,
      companyName: '',
      ico: '',
      dic: '',
      issuer: '',
      position: '',
      citizenship: data.citizenship || '',
      degreePre: data.degreePre || '',
      degreePost: data.degreePost || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      birthDate: !!data.birthdate ? moment(data.birthdate).toDate() : undefined,
      identityCardNumber: data.identityCardNumber || '',
      personalIdentificationNumber: data.personalIdentificationNumber || '',
      eBox: data.dataBox || '',
      phone: data.phone || '',
      bankAccountNumber: bankAccount.length > 0 ? bankAccount[0] : '',
      bankAccountCode: bankAccount.length > 1 ? bankAccount[1] : '',
      address: residenceAddress?.address?.address || '',
      addressIndex: residenceAddress?.index,
      addressId: residenceAddress?.address?.id,
      deliveryAddressId: deliveryAddress?.address?.id,
      deliveryAddressIndex: deliveryAddress?.index,
      invoiceAddressId: invoiceAddress?.address?.id,
      invoiceAddressIndex: invoiceAddress?.index,
      zip: residenceAddress?.address?.zipCode || '',
      city: residenceAddress?.address?.city || '',
      country: residenceAddress?.address?.country || '',
      hasDifferentDeliveryAddress: !!deliveryAddress,
      deliveryAddress: deliveryAddress?.address?.address || '',
      deliveryZip: deliveryAddress?.address?.zipCode || '',
      deliveryCity: deliveryAddress?.address?.city || '',
      deliveryCountry: deliveryAddress?.address?.country || '',
      hasDifferentInvoiceAddress: !!invoiceAddress,
      invoiceAddress: invoiceAddress?.address?.address || '',
      invoiceZip: invoiceAddress?.address?.zipCode || '',
      invoiceCity: invoiceAddress?.address?.city || '',
      invoiceCountry: invoiceAddress?.address?.country || '',
      note: '',
      share: '',
      doesAggreeTnc: true,
      doesAggreePrivacyPolicy: true,
      doesAggreeMarketing: true,
    };
  };

  const getDataOwnAccountWorker = (data: BuyerOwnAccountWorker): BuyerFormValues => {
    const deliveryAddress = getAddress(data.userAddresses, UserAddressTypes.delivery);
    const residenceAddress = getAddress(data.userAddresses, UserAddressTypes.residence);
    const invoiceAddress = getAddress(data.userAddresses, UserAddressTypes.invoice);
    const bankAccount: string[] = !!data.accountNumber ? data.accountNumber.split('/') : [];
    return {
      registrationType: props.user?.role,
      email: data.email,
      type: data.buyerType,
      companyName: '',
      ico: data.ico || '',
      dic: data.dic || '',
      issuer: '',
      position: '',
      citizenship: data.citizenship || '',
      degreePre: data.degreePre || '',
      degreePost: data.degreePost || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      birthDate: !!data.birthdate ? moment(data.birthdate).toDate() : undefined,
      identityCardNumber: data.identityCardNumber || '',
      personalIdentificationNumber: data.personalIdentificationNumber || '',
      eBox: data.dataBox || '',
      phone: data.phone || '',
      bankAccountNumber: bankAccount.length > 0 ? bankAccount[0] : '',
      bankAccountCode: bankAccount.length > 1 ? bankAccount[1] : '',
      address: residenceAddress?.address?.address || '',
      addressId: residenceAddress?.address?.id,
      addressIndex: residenceAddress?.index,
      deliveryAddressId: deliveryAddress?.address?.id,
      deliveryAddressIndex: deliveryAddress?.index,
      invoiceAddressId: invoiceAddress?.address?.id,
      invoiceAddressIndex: invoiceAddress?.index,
      zip: residenceAddress?.address?.zipCode || '',
      city: residenceAddress?.address?.city || '',
      country: residenceAddress?.address?.country || '',
      hasDifferentDeliveryAddress: !!deliveryAddress,
      deliveryAddress: deliveryAddress?.address?.address || '',
      deliveryZip: deliveryAddress?.address?.zipCode || '',
      deliveryCity: deliveryAddress?.address?.city || '',
      deliveryCountry: deliveryAddress?.address?.country || '',
      hasDifferentInvoiceAddress: !!invoiceAddress,
      invoiceAddress: invoiceAddress?.address?.address || '',
      invoiceZip: invoiceAddress?.address?.zipCode || '',
      invoiceCity: invoiceAddress?.address?.city || '',
      invoiceCountry: invoiceAddress?.address?.country || '',
      note: '',
      share: '',
      doesAggreeTnc: true,
      doesAggreePrivacyPolicy: true,
      doesAggreeMarketing: true,
    };
  };

  const getDataLegal = (data: BuyerLegal): BuyerFormValues => {
    const deliveryAddress = getAddress(data.userAddresses, UserAddressTypes.delivery);
    const residenceAddress = getAddress(data.userAddresses, UserAddressTypes.residence);
    const invoiceAddress = getAddress(data.userAddresses, UserAddressTypes.invoice);
    const bankAccount: string[] = !!data.accountNumber ? data.accountNumber.split('/') : [];
    return {
      registrationType: props.user?.role,
      email: data.email,
      type: data.buyerType,
      companyName: data.company || '',
      ico: data.ico || '',
      dic: data.dic || '',
      issuer: data.register || '',
      position: data.position,
      degreePre: data.degreePre || '',
      degreePost: data.degreePost || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      citizenship: data.citizenship || '',
      birthDate: !!data.birthdate ? moment(data.birthdate).toDate() : undefined,
      identityCardNumber: data.identityCardNumber || '',
      personalIdentificationNumber: data.personalIdentificationNumber || '',
      eBox: data.dataBox || '',
      phone: data.phone || '',
      bankAccountNumber: bankAccount.length > 0 ? bankAccount[0] : '',
      bankAccountCode: bankAccount.length > 1 ? bankAccount[1] : '',
      address: residenceAddress?.address?.address || '',
      addressId: residenceAddress?.address?.id,
      addressIndex: residenceAddress?.index,
      deliveryAddressId: deliveryAddress?.address?.id,
      deliveryAddressIndex: deliveryAddress?.index,
      invoiceAddressId: invoiceAddress?.address?.id,
      invoiceAddressIndex: invoiceAddress?.index,
      zip: residenceAddress?.address?.zipCode || '',
      city: residenceAddress?.address?.city || '',
      country: residenceAddress?.address?.country || '',
      hasDifferentDeliveryAddress: !!deliveryAddress,
      deliveryAddress: deliveryAddress?.address?.address || '',
      deliveryZip: deliveryAddress?.address?.zipCode || '',
      deliveryCity: deliveryAddress?.address?.city || '',
      deliveryCountry: deliveryAddress?.address?.country || '',
      hasDifferentInvoiceAddress: !!invoiceAddress,
      invoiceAddress: invoiceAddress?.address?.address || '',
      invoiceZip: invoiceAddress?.address?.zipCode || '',
      invoiceCity: invoiceAddress?.address?.city || '',
      invoiceCountry: invoiceAddress?.address?.country || '',
      note: '',
      share: '',
      doesAggreeTnc: true,
      doesAggreePrivacyPolicy: true,
      doesAggreeMarketing: true,
    };
  };

  const getDataSJMPV = (data: BuyerSJMPV): BuyerFormValues => {
    const deliveryAddress = getAddress(data.userAddresses, UserAddressTypes.delivery);
    const residenceAddress = getAddress(data.userAddresses, UserAddressTypes.residence);
    const invoiceAddress = getAddress(data.userAddresses, UserAddressTypes.invoice);
    const bankAccount: string[] = !!data.accountNumber ? data.accountNumber.split('/') : [];
    return {
      registrationType: props.user?.role,
      email: data.email,
      type: data.buyerType,
      companyName: '',
      ico: '',
      dic: '',
      issuer: '',
      position: '',
      degreePre: data.degreePre || '',
      degreePost: data.degreePost || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      birthDate: !!data.birthdate ? moment(data.birthdate).toDate() : undefined,
      identityCardNumber: data.identityCardNumber || '',
      personalIdentificationNumber: data.personalIdentificationNumber || '',
      eBox: data.dataBox || '',
      phone: data.phone || '',
      citizenship: data.citizenship || '',
      bankAccountNumber: bankAccount.length > 0 ? bankAccount[0] : '',
      bankAccountCode: bankAccount.length > 1 ? bankAccount[1] : '',
      address: residenceAddress?.address?.address || '',
      addressId: residenceAddress?.address?.id,
      addressIndex: residenceAddress?.index,
      deliveryAddressId: deliveryAddress?.address?.id,
      deliveryAddressIndex: deliveryAddress?.index,
      invoiceAddressId: invoiceAddress?.address?.id,
      invoiceAddressIndex: invoiceAddress?.index,
      zip: residenceAddress?.address?.zipCode || '',
      city: residenceAddress?.address?.city || '',
      country: residenceAddress?.address?.country || '',
      hasDifferentDeliveryAddress: !!deliveryAddress,
      deliveryAddress: deliveryAddress?.address?.address || '',
      deliveryZip: deliveryAddress?.address?.zipCode || '',
      deliveryCity: deliveryAddress?.address?.city || '',
      deliveryCountry: deliveryAddress?.address?.country || '',
      hasDifferentInvoiceAddress: !!invoiceAddress,
      invoiceAddress: invoiceAddress?.address?.address || '',
      invoiceZip: invoiceAddress?.address?.zipCode || '',
      invoiceCity: invoiceAddress?.address?.city || '',
      invoiceCountry: invoiceAddress?.address?.country || '',
      note: '',
      share: data.share || '',
      doesAggreeTnc: true,
      doesAggreePrivacyPolicy: true,
      doesAggreeMarketing: true,
    };
  };

  if (!loaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div>
      <h2>Informace o dražiteli</h2>
      <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-3">
        <div className="responsive-table-content">
          <div>
            <>
              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label text-right">Typ registrace *</Form.Label>
                <div className="f-inline-control">
                  <div className="w-max-500">
                    <Select
                      size="md"
                      isDisabled={props.readOnly || (!!props.user && props.user.role === UserRole.buyer)}
                      options={registrationTypes}
                      isInvalid={!!formik.errors.registrationType}
                      value={registrationTypes.find((i) => i.value === formik.values.registrationType) || null}
                      onChange={handleRegistrationTypeChange}
                    />
                    {!!formik.errors.registrationType && (
                      <ControlFeedback type="invalid">{formik.errors.registrationType}</ControlFeedback>
                    )}
                  </div>
                </div>
              </Form.Group>
              <FormGroup
                type="text"
                name="email"
                label="E-mail"
                required
                readOnly={!!props.user}
                value={formik.values.email}
                error={formik.errors.email}
                onChange={formik.handleChange}
              />
              {formik.values.registrationType === UserRole.buyer && (
                <>
                  <Form.Group className="f-inline-group">
                    <Form.Label className="f-inline-label text-right">Typ účtu *</Form.Label>
                    <div className="f-inline-control">
                      <div className="w-max-500">
                        <Select
                          isInvalid={!!formik.errors.type}
                          size="md"
                          readOnly={props.readOnly}
                          options={userTypes}
                          value={userTypes.find((i) => i.value === formik.values.type) || null}
                          onChange={handleUserTypeChange}
                        />
                        {!!formik.errors.type && <ControlFeedback type="invalid">{formik.errors.type}</ControlFeedback>}
                      </div>
                    </div>
                  </Form.Group>
                  <FormControls
                    noResponsive
                    values={formik.values}
                    errors={formik.errors}
                    readOnly={props.readOnly}
                    handleChange={formik.handleChange}
                    setFieldValue={formik.setFieldValue}
                  />
                </>
              )}
            </>
            {formik.values.registrationType === UserRole.buyer && (
              <div className="section-address mt-3">
                <h2>{formik.values.type === 'legal' ? 'Adresa sídla' : 'Trvalé bydliště'}</h2>
                <Address
                  withoutSuggest={formik.values.citizenship === 'other'}
                  contentClassName="block-address"
                  readOnly={props.readOnly}
                  values={formik.values}
                  errors={formik.errors}
                  setFieldValue={formik.setFieldValue}
                  handleChange={formik.handleChange}
                />
              </div>
            )}
          </div>
        </div>
        <Row>
          <Visible permissionName={UserPermission.canEdit}>
            <Col xs={12} className="mt-4 text-right">
              {props.readOnly ? (
                <Button
                  to={`${pageState.getPagePath(routes.admin.USER_UPDATE, {
                    ':id': props.user ? props.user.id.toString() : '',
                  })}?section=${props.section}`}
                >
                  Upravit
                </Button>
              ) : (
                <>
                  {(!props.user || formik.values.registrationType === UserRole.buyer) && (
                    <>
                      {!formik.isSubmitting ? (
                        <Button type="submit" disabled={formik.isSubmitting}>
                          Uložit
                        </Button>
                      ) : (
                        <BasePreloader size={29} className="d-inline-block" />
                      )}
                    </>
                  )}
                </>
              )}
            </Col>
          </Visible>
        </Row>
      </Form>
    </div>
  );
};

export default UserForm;
