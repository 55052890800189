import React from 'react';
import { ValueType } from 'react-select';
import { useToasts } from 'react-toast-notifications';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { applicationStore } from '@stores';
import { fileToBase64 } from '@helpers/files';
import { useAuctionsApi } from '@api/auctions';
import { getFormatBytes } from '@helpers/formats';
import { AuctionDocumentTypeResponse } from '@types';
import { BasePreloader, Button, Select } from '@components';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';
import colorIcoCheck from '@assets/images/color-ico-check.svg';

interface Props {
  id: string | number;
  accept?: string;
  auctionType: string;
  isPublic: boolean;
  onSave: () => void;
}

interface SelectOptionType {
  value: string;
  label: string;
}

const AddDocument: React.FC<Props> = (props) => {
  const { addToast } = useToasts();
  const auctionsApi = useAuctionsApi();
  const [saved, setSaved] = React.useState(true);
  const inputRef = React.createRef<HTMLInputElement>();
  const [file, setFile] = React.useState<File | undefined>(undefined);
  const [documentType, setDocumentType] = React.useState('');
  const [documentTypes, setDocumentTypes] = React.useState<AuctionDocumentTypeResponse>({});
  const documentTypeOptions = Object.getOwnPropertyNames(documentTypes).map((prop) => ({
    value: prop,
    label: documentTypes[prop],
  }));

  React.useEffect(() => {
    loadDocumentTypes();
    return () => auctionsApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDocumentTypes = async () => {
    try {
      const response = await auctionsApi.getDocumentTypes(props.auctionType);
      setDocumentTypes(response.data.data);
      const types = Object.getOwnPropertyNames(response.data.data);
      if (types.length > 0) {
        setDocumentType(types[0]);
      }
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const saveFile = async () => {
    const maxPostBodySize = applicationStore.getState().systemInfo?.post_max_size;
    if (!!file && !!maxPostBodySize && maxPostBodySize < file.size) {
      addToast(`Soubor ${file.name} nesmí být větší než ${getFormatBytes(maxPostBodySize)}`, {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    setSaved(false);
    try {
      const fileBase64 = await fileToBase64(file as File);
      const data = {
        type: 'file',
        data: fileBase64,
        mime: file?.type as string,
        original_name: file?.name as string,
        title: documentTypeOptions.find((d) => d.value === documentType)?.label,
      };
      if (props.isPublic) {
        await auctionsApi.createDocument(props.id, documentType, data);
      } else {
        await auctionsApi.createDocumentPrivate(props.id, data);
      }
      setFile(undefined);
      setDocumentType(documentTypeOptions[0]?.value || '');
      setSaved(true);
      props.onSave();
    } catch (err) {
      if (!err.response) {
        return;
      }
      setSaved(true);
    }
  };

  const handleDocumentTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    setDocumentType(itemValue.value);
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined = e.target.files !== null ? e.target.files[0] : undefined;
    if (!!file) {
      setFile(file);
    }
  };

  const handleAddClick = (e: React.MouseEvent) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  return (
    <div className="add-document">
      <div className="item-input">
        <div className="item-input-wrapper">
          <div className="block-title">Přidat dokument:</div>
          <a href="/" className="item-add" onClick={handleAddClick}>
            Vyberte soubor z počítače
            {!!file && (
              <OverlayTrigger
                key="file"
                placement="top"
                overlay={
                  <Tooltip id="table-permission">
                    <div>{file.name}</div>
                  </Tooltip>
                }
              >
                <img src={colorIcoCheck} alt="ico" className="success-ico" />
              </OverlayTrigger>
            )}
          </a>
          {saved && (
            <input
              type="file"
              accept={props.accept || 'application/pdf'}
              ref={inputRef}
              className="d-none"
              onChange={handleSelectFile}
            />
          )}
        </div>
        {props.isPublic && (
          <div className="item-type-select">
            <div className="select-label">Typ dokumentu:</div>
            <div className="select-container">
              <Select
                options={documentTypeOptions}
                onChange={handleDocumentTypeChange}
                value={documentTypeOptions.find((i) => i.value === documentType) || null}
              />
            </div>
          </div>
        )}
      </div>
      <div className="item-submit">
        {saved ? (
          <>
            {!!file && (
              <Button type="button" onClick={() => saveFile()}>
                <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
                <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
                Přidat dokument
              </Button>
            )}
          </>
        ) : (
          <BasePreloader className="file-preloader" size={25} />
        )}
        <div className="clearfix" />
      </div>
      <div className="clearfix" />
    </div>
  );
};

export default AddDocument;
