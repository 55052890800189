import React from 'react';
import { Table } from 'react-bootstrap';
import fileDownload from 'js-file-download';

import * as routes from '@routes';
import { UserEmailType } from '@types';
import { useUsersApi } from '@api/users';
import { getDateTimeFormat } from '@helpers/datetime';
import { BaseAccountPage, BasePreloader, FileIcon, FrontAccountBox, Modal, Pagination } from '@components';

import colorIcoEye from '@assets/images/color-ico-eye.svg';

const AccountEmailsPage: React.FC = () => {
  const perPage = 15;
  const usersApi = useUsersApi();
  const [detailLoaded, setDetailLoaded] = React.useState(0);
  const [emails, setEmails] = React.useState<UserEmailType[]>([]);
  const [loaded, setLoaded] = React.useState(false);
  const [totalRows, setTotalRows] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalTile, setModalTitle] = React.useState('');
  const [modalContent, setModalContent] = React.useState('');

  React.useEffect(() => {
    loadEmails(1);
    // eslint-disable-next-line
  }, []);

  const loadEmails = async (page: number) => {
    window.scrollTo(0, 0);
    setLoaded(false);
    setCurrentPage(page);
    try {
      const response = await usersApi.getMyEmails(page, perPage);
      setTotalRows(response.data.total || 0);
      setEmails(response.data.data);
      setLoaded(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setDetailLoaded(0);
    }
  };

  const handleFileClick = (e: React.MouseEvent<HTMLAnchorElement>, item: UserEmailType, index: number) => {
    e.preventDefault();
    usersApi
      .getEmailAttachment(item.id, item.attachments[index].id)
      .then((res) => {
        fileDownload(res.data, item.attachments[index].fileName);
      })
      .catch((err) => {
        if (usersApi.isCancel(err)) {
          return;
        }
      });
  };

  const handleShowEmail = async (e: React.MouseEvent, email: UserEmailType) => {
    e.preventDefault();
    setDetailLoaded(email.id);
    try {
      const emailResponse = await usersApi.getMyEmail(email.id);
      setModalTitle(email.subject);
      setModalContent(emailResponse.data);
      setDetailLoaded(0);
      setIsModalOpen(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setDetailLoaded(0);
    }
  };

  return (
    <BaseAccountPage page={routes.front.ACCOUNT_EMAILS}>
      <FrontAccountBox title="Doručené e-maily">
        {loaded ? (
          <>
            <Modal
              noPadding
              title={modalTile}
              isOpen={isModalOpen}
              className="modal-content-lg h-100vh"
              shouldCloseOnOverlayClick={true}
              onRequestClose={() => setIsModalOpen(false)}
            >
              <div>
                {!!modalContent && (
                  <iframe
                    title={modalTile}
                    srcDoc={modalContent}
                    className="w-100p b-0"
                    style={{ height: 'calc(100vh - 150px)' }}
                  />
                )}
              </div>
            </Modal>
            <div>
              <div className="mt-4">
                <div className="responsive-table-content">
                  <Table className="text-left table-middle border-bottom-0" striped>
                    <thead>
                      <tr>
                        <th className="text-left">Předmět</th>
                        <th className="text-left">Datum</th>
                        <th className="text-left">Přílohy</th>
                        <th className="w-1" />
                      </tr>
                    </thead>
                    <tbody>
                      {emails.length < 1 && (
                        <tr>
                          <td className="text-left" colSpan={100}>
                            Nebyla nalezena žádná data.
                          </td>
                        </tr>
                      )}
                      {emails.map((item, index) => (
                        <tr key={`email-${index}`}>
                          <td>{item.subject}</td>
                          <td className="text-left">{getDateTimeFormat(item.date)}</td>
                          <td>
                            {item.attachments.map((attachment, index) => (
                              <>
                                <FileIcon name={attachment.fileName} className="ml-2" />
                                <a href="/" onClick={(e) => handleFileClick(e, item, index)} className="mr-2">
                                  {attachment.fileName}
                                </a>
                              </>
                            ))}
                          </td>
                          <td className="text-right nowrap">
                            {detailLoaded !== item.id ? (
                              <a
                                href="/"
                                onClick={(e) => handleShowEmail(e, item)}
                                className="d-inline-flex align-items-center"
                              >
                                <img src={colorIcoEye} alt="ico" className="mr-2" />
                                Náhled
                              </a>
                            ) : (
                              <BasePreloader className="d-inline-block m-0 p-0" size={17} />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>

              {totalRows > perPage && (
                <div className="mt-2">
                  <Pagination
                    page={currentPage}
                    perPage={perPage}
                    totalRows={totalRows}
                    onPageChange={(page) => loadEmails(page)}
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
            <BasePreloader />
          </div>
        )}
      </FrontAccountBox>
    </BaseAccountPage>
  );
};

export default AccountEmailsPage;
