import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { ValueType } from 'react-select';
import { useHistory } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import { FormikValues, useFormik } from 'formik';

import * as routes from '@routes';
import { useAuctioneersApi } from '@api/auctioneers';
import { getRequestDateFormat } from '@helpers/datetime';
import { InformationAddress } from './InformationAddress';
import { useCurrentUserRoleChecks, usePageStore } from '@stores';
import {
  Auctioneer,
  AuctioneerAddress,
  AuctioneerStates,
  AuctioneerType,
  PermissionGroup,
  UserPermission,
} from '@types';
import {
  Select,
  FormGroup,
  BasePreloader,
  ControlFeedback,
  DatePickerInput,
  Button,
  Visible,
  useVisibility,
} from '@components';

import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';

interface Props {
  id?: string;
  readOnly?: boolean;
  onNotFound: () => void;
}

interface TypeSelectOptionType {
  value: string;
  label: string;
}

interface BaseInputType {
  name: string;
  value: string;
  label: string;
  type?: string;
  error?: string;
  required?: boolean;
  className?: string;
}

const Informations: React.FC<Props> = (props) => {
  const history = useHistory();
  const pageState = usePageStore();
  const { shouldBeVisible } = useVisibility();
  const { isOfRoleSuperAdmin } = useCurrentUserRoleChecks();
  const auctioneersApi = useAuctioneersApi();
  const [loaded, setLoaded] = React.useState(true);
  const [auctioneerTypes, setAuctioneerTypes] = React.useState([] as Array<AuctioneerType>);
  const [auctioneerStates, setAuctioneerStates] = React.useState<AuctioneerStates[]>([]);
  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      auctioneerName: '',
      type: '',
      ic: '',
      dic: '',
      email: '',
      state: 'active',
      emailInvoice: '',
      phone: '+420',
      dataBox: '',
      website: '',
      bankAccountNumber: '',
      bankAccountCode: '',
      comment: '',
      executive: '',
      testDate: false,
      testDateFrom: '',
      testDateTo: '',
      invoice: false,
      invoiceDateFrom: '',
      invoiceDateTo: '',
      auctioneerNumber: null,
      secondPartFileMark: '',
      deliveryIsSame: true,
      invoiceAddress: {
        country: '',
        region: '',
        district: '',
        street: '',
        houseNumber: '',
        city: '',
        zipCode: '',
        ruian: '',
        gps: '',
        cadastralArea: '',
      },
      deliveryAddress: {
        country: '',
        region: '',
        district: '',
        street: '',
        houseNumber: '',
        city: '',
        zipCode: '',
        ruian: '',
        gps: '',
        cadastralArea: '',
      },
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const readOnly =
    props.readOnly ||
    !shouldBeVisible({
      or: [
        {
          permissionGroupName: PermissionGroup.auctioneerInformation,
          permissionName: UserPermission.canEdit,
        },
        {
          permissionGroupName: PermissionGroup.myCompanyInformation,
          permissionName: UserPermission.canEdit,
        },
      ],
    });

  React.useEffect(() => {
    loadAuctioneerStates();
    loadAuctioneerTypes();
    if (props.id) {
      loadDetail(props.id);
    }
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const loadDetail = (id: string) => {
    setLoaded(false);
    auctioneersApi
      .detail(id)
      .then((res) => {
        const data = _.omit(res.data.data, [
          'auctioneer',
          'invoiceAddress',
          'deliveryAddress',
          'createdUserId',
          'createdUserFullName',
          'auctioneerAccessCsas',
        ]);
        formik.setValues(data as any);
        formik.setFieldValue('testDateFrom', !!data.testDateFrom ? getRequestDateFormat(data.testDateFrom) : '');
        formik.setFieldValue('testDateTo', !!data.testDateTo ? getRequestDateFormat(data.testDateTo) : '');
        formik.setFieldValue(
          'invoiceDateFrom',
          !!data.invoiceDateFrom ? getRequestDateFormat(data.invoiceDateFrom) : ''
        );
        formik.setFieldValue('invoiceDateTo', !!data.invoiceDateTo ? getRequestDateFormat(data.invoiceDateTo) : '');
        setApiAddress(res.data.data.invoiceAddress, 'invoiceAddress');
        setApiAddress(res.data.data.deliveryAddress, 'deliveryAddress');
        setLoaded(true);
      })
      .catch((err) => {
        if (auctioneersApi.isCancel(err)) {
          return;
        }
        if (err?.response?.status === 404 || err?.response?.status === 403) {
          props.onNotFound();
          return;
        }
        console.error(err);
      });
  };

  const setApiAddress = (address: AuctioneerAddress | null, type: string) => {
    formik.setFieldValue(type, {
      country: address?.country?.id || '',
      region: address?.region?.id || '',
      district: address?.district?.id || '',
      street: address?.street || '',
      houseNumber: address?.houseNumber || '',
      city: address?.city || '',
      zipCode: address?.zipCode || '',
      ruian: address?.ruian || '',
      gps: address?.gps || '',
      cadastralArea: address?.cadastralArea || '',
    });
  };

  const loadAuctioneerTypes = async () => {
    try {
      const response = await auctioneersApi.types();
      setAuctioneerTypes(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadAuctioneerStates = async () => {
    try {
      const response = await auctioneersApi.getAuctioneerStates();
      setAuctioneerStates(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const getAuctioneerTypeOptions = () => {
    return auctioneerTypes.map((i) => ({ label: i.translated, value: i.type }));
  };

  const getAuctioneerStateOptions = () => {
    return auctioneerStates.map((i) => ({ label: i.translated, value: i.type }));
  };

  const handleSubmit = (values: FormikValues) => {
    const omitInputs = [
      'id',
      'auction',
      'auctionTypes',
      'services',
      'timeCreated',
      'timeUpdated',
      'typeTranslation',
      'stateTranslation',
      'createdUser',
      'referenceNumber',
      'licitator'
    ];
    if (!formik.values.type) {
      omitInputs.push('auctioneerNumber');
      omitInputs.push('secondPartFileMark');
    }

    if (formik.values.deliveryIsSame) {
      omitInputs.push('deliveryAddress');
    }

    omitInputs.push('auctioneerAccessFio');

    const inputs = _.omit(values, omitInputs) as Partial<Auctioneer>;
    inputs.phone = inputs.phone?.replaceAll(' ', '');

    (!!props.id ? auctioneersApi.update(props.id || '', inputs) : auctioneersApi.create(inputs))
      .then((res) => {
        formik.setSubmitting(false);
        history.push(
          pageState.getPagePath(
            !!pageState.getPage(routes.admin.AUCTIONEER_UPDATE)
              ? routes.admin.AUCTIONEER_UPDATE
              : routes.admin.MY_COMPANY_UPDATE,
            { ':id': `${res.data.data.id}` }
          )
        );
      })
      .catch((err) => {
        if (auctioneersApi.isCancel(err)) {
          return;
        }
        const errors = err.response?.data?.errors || {};
        Object.getOwnPropertyNames(errors).map((prop) => {
          formik.setFieldError(prop, errors[prop][0]);
          return prop;
        });
        formik.setSubmitting(false);
      });
  };

  const handleTypeChange = (value: ValueType<TypeSelectOptionType, boolean>) => {
    const itemValue = value as TypeSelectOptionType;
    formik.setFieldValue('type', itemValue?.value || '');
  };

  const handleDateChange = (prop: string, value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      formik.setFieldValue(prop, !!inputValue ? getRequestDateFormat(inputValue) : '');
    }
  };

  const handleCheckboxChange = (prop: string, event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(prop, event.target.checked);
  };

  return (
    <div>
      {loaded ? (
        <>
          <h2>Informace o dražebníkovi</h2>
          <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-5">
            <div className="responsive-table-content">
              <div>
                {isOfRoleSuperAdmin() && (
                  <Form.Group className="f-inline-group">
                    <Form.Label className="f-inline-label text-right">Stav *</Form.Label>
                    <div className="f-inline-control">
                      <div className="w-max-500">
                        <Select
                          size="md"
                          options={getAuctioneerStateOptions()}
                          readOnly={props.readOnly}
                          isInvalid={!!_.get(formik.errors, `state`)}
                          value={
                            getAuctioneerStateOptions().find((i) => i.value.toString() === formik.values.state) || null
                          }
                          onChange={(v) => formik.setFieldValue(`state`, v?.value)}
                        />
                        {!!_.get(formik.errors, `state`) && (
                          <ControlFeedback type="invalid">{_.get(formik.errors, `state`)}</ControlFeedback>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                )}

                <FormGroup
                  type="text"
                  name="auctioneerName"
                  label="Název subjektu"
                  required={true}
                  error={formik.errors.auctioneerName}
                  value={formik.values.auctioneerName || ''}
                  onChange={formik.handleChange}
                  readOnly={readOnly}
                />
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-right">Typ subjektu *</Form.Label>
                  <div className="f-inline-control">
                    <div className="w-max-500">
                      <Select
                        isInvalid={!!formik.errors.type}
                        size="md"
                        options={getAuctioneerTypeOptions()}
                        value={getAuctioneerTypeOptions().find((i) => i.value === formik.values.type) || null}
                        onChange={handleTypeChange}
                        readOnly={readOnly}
                      />
                      {!!formik.errors.type && <ControlFeedback type="invalid">{formik.errors.type}</ControlFeedback>}
                    </div>
                  </div>
                </Form.Group>
                {formik.values.type && (
                  <FormGroup
                    type="text"
                    name="auctioneerNumber"
                    label={`Číslo ${formik.values.type === 'executor' ? 'exekutora' : 'dražebníka'}`}
                    required={true}
                    error={formik.errors.auctioneerNumber}
                    value={formik.values.auctioneerNumber || undefined}
                    onChange={formik.handleChange}
                    readOnly={readOnly}
                  />
                )}

                {(formik.values.type === 'auctioneer' || formik.values.type === 'juris') && (
                  <FormGroup
                    type="text"
                    name="secondPartFileMark"
                    label="Spisová značka"
                    required={true}
                    error={formik.errors.secondPartFileMark}
                    value={formik.values.secondPartFileMark || ''}
                    onChange={formik.handleChange}
                    readOnly={readOnly}
                  />
                )}
                {formik.values.type === 'executor' && (
                  <FormGroup
                    required
                    readOnly
                    value="Ex"
                    type="text"
                    label="Spisová značka"
                    name="secondPartFileMark"
                  />
                )}

                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-right mt-2">IČ *</Form.Label>
                  <div className="f-inline-control d-flex align-items-center">
                    <div>
                      <FormGroup
                        type="text"
                        name="ic"
                        controlClassName="w-max-225"
                        required={true}
                        controlOnly={true}
                        error={formik.errors.ic}
                        value={formik.values.ic}
                        onChange={formik.handleChange}
                        readOnly={readOnly}
                      />
                    </div>
                    <span className="f-size-12 w-weight-400 ml-3 mr-3">DIČ</span>
                    <FormGroup
                      type="text"
                      name="dic"
                      controlClassName="w-max-225"
                      required={true}
                      controlOnly={true}
                      error={formik.errors.dic}
                      value={formik.values.dic}
                      onChange={formik.handleChange}
                      readOnly={readOnly}
                    />
                  </div>
                </Form.Group>

                {(
                  [
                    {
                      label: 'E-mail',
                      type: 'text',
                      name: 'email',
                      required: true,
                      value: formik.values.email,
                      error: formik.errors.email,
                    },
                    {
                      label: 'Fakturační E-mail',
                      type: 'text',
                      name: 'emailInvoice',
                      required: true,
                      value: formik.values.emailInvoice,
                      error: formik.errors.emailInvoice,
                    },
                    {
                      name: 'phone',
                      label: 'Tel.:',
                      className: 'w-max-220',
                      required: true,
                      value: formik.values.phone,
                      error: formik.errors.phone,
                    },
                    {
                      name: 'dataBox',
                      label: 'Datová schránka',
                      value: formik.values.dataBox,
                      error: formik.errors.dataBox,
                    },
                    {
                      label: 'Jednatel',
                      name: 'executive',
                      value: formik.values.executive,
                      error: formik.errors.executive,
                    },
                    {
                      label: 'Web',
                      name: 'website',
                      value: formik.values.website,
                      error: formik.errors.website,
                    },
                  ] as Array<BaseInputType>
                ).map((item: BaseInputType, index: number) => (
                  <FormGroup
                    key={`input-${index}`}
                    type={item.type}
                    name={item.name}
                    error={item.error}
                    label={item.label}
                    value={item.value}
                    required={item.required}
                    onChange={formik.handleChange}
                    controlClassName={item.className}
                    readOnly={readOnly}
                  />
                ))}

                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-right">Bankovní účet</Form.Label>
                  <div className="f-inline-control d-flex align-items-center">
                    <FormGroup
                      error={formik.errors.bankAccountNumber}
                      type="text"
                      name="bankAccountNumber"
                      controlClassName="w-max-315"
                      controlOnly={true}
                      value={formik.values.bankAccountNumber || ''}
                      onChange={formik.handleChange}
                      readOnly={readOnly}
                    />
                    <span className="f-size-12 w-weight-400 ml-3 mr-3">/</span>
                    <FormGroup
                      error={formik.errors.bankAccountCode}
                      type="text"
                      name="bankAccountCode"
                      controlOnly={true}
                      controlClassName="w-max-150"
                      value={formik.values.bankAccountCode || ''}
                      onChange={formik.handleChange}
                      readOnly={readOnly}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="d-flex align-items-center ml-3">
                  <div className="w-140 checkbox-margin label-right mr-2">
                    <Form.Check
                      custom
                      type="checkbox"
                      label="Zkušební doba"
                      className="mr-4"
                      id="test"
                      name="test"
                      checked={formik.values.testDate}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (!readOnly) {
                          handleCheckboxChange('testDate', e);
                          return;
                        }
                      }}
                      readOnly={readOnly}
                    />
                  </div>
                  {formik.values.testDate ? (
                    <>
                      <Form.Group className="mb-2 inline-label mr-3 input-ico input-ico-md">
                        <div className="w-135 position-relative">
                          <DatePickerInput
                            className={classNames(['form-control', { 'is-invalid': !!formik.errors.testDateFrom }])}
                            calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                            clearIcon={null}
                            onChange={(val) => handleDateChange('testDateFrom', val)}
                            value={
                              !!formik.values.testDateFrom
                                ? moment(formik.values.testDateFrom || '').toDate()
                                : undefined
                            }
                            readOnly={readOnly}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="mb-2 inline-label input-ico input-ico-md">
                        <div className="w-135 position-relative">
                          <DatePickerInput
                            className={classNames(['form-control', { 'is-invalid': !!formik.errors.testDateTo }])}
                            calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                            clearIcon={null}
                            onChange={(val) => handleDateChange('testDateTo', val)}
                            value={
                              !!formik.values.testDateTo ? moment(formik.values.testDateTo || '').toDate() : undefined
                            }
                            readOnly={readOnly}
                          />
                        </div>
                      </Form.Group>
                    </>
                  ) : (
                    <div style={{ height: 52 }} />
                  )}
                </Form.Group>
                <Form.Group className="d-flex align-items-center ml-3">
                  <div className="w-140 checkbox-margin label-right mr-2">
                    <Form.Check
                      custom
                      type="checkbox"
                      label="Smlouva"
                      className="mr-4"
                      id="invoice"
                      name="invoice"
                      checked={formik.values.invoice}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (!readOnly) {
                          handleCheckboxChange('invoice', e);
                          return;
                        }
                      }}
                      readOnly={readOnly}
                    />
                  </div>
                  {formik.values.invoice ? (
                    <>
                      <Form.Group className="mb-2 inline-label mr-3 input-ico input-ico-md">
                        <div className="w-135 position-relative">
                          <DatePickerInput
                            className={classNames(['form-control', { 'is-invalid': !!formik.errors.invoiceDateFrom }])}
                            calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                            clearIcon={null}
                            onChange={(val) => handleDateChange('invoiceDateFrom', val)}
                            value={
                              !!formik.values.invoiceDateFrom
                                ? moment(formik.values.invoiceDateFrom || '').toDate()
                                : undefined
                            }
                            readOnly={readOnly}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="mb-2 inline-label input-ico input-ico-md">
                        <div className="w-135 position-relative">
                          <DatePickerInput
                            className={classNames(['form-control', { 'is-invalid': !!formik.errors.invoiceDateTo }])}
                            calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                            clearIcon={null}
                            onChange={(val) => handleDateChange('invoiceDateTo', val)}
                            value={
                              !!formik.values.invoiceDateTo
                                ? moment(formik.values.invoiceDateTo || '').toDate()
                                : undefined
                            }
                            readOnly={readOnly}
                          />
                        </div>
                      </Form.Group>
                    </>
                  ) : (
                    <div style={{ height: 52 }} />
                  )}
                </Form.Group>
                {isOfRoleSuperAdmin() && (
                  <Form.Group className="f-inline-group align-items-start">
                    <Form.Label className="f-inline-label text-right">Poznámka</Form.Label>
                    <div className="f-inline-control">
                      <Form.Control
                        isInvalid={!!formik.errors.comment}
                        as="textarea"
                        rows={10}
                        name="comment"
                        className="w-max-500 resize-none"
                        value={formik.values.comment || ''}
                        onChange={formik.handleChange}
                        readOnly={readOnly}
                      />
                    </div>
                  </Form.Group>
                )}
              </div>
            </div>

            <div className="pt-4">
              <InformationAddress
                type="invoiceAddress"
                title="Fakturační adresa"
                values={formik.values}
                errors={formik.errors}
                readOnly={readOnly}
                setFieldValue={formik.setFieldValue}
                handleChange={formik.handleChange}
              />
            </div>

            <div className="mt-4">
              <Form.Group className="d-flex align-items-center ml-3">
                <Form.Check
                  custom
                  type="checkbox"
                  label={<p className="terms">Doručovací adresa je stejná jako fakturační</p>}
                  className="mr-4"
                  id="deliveryIsSame"
                  name="deliveryIsSame"
                  checked={formik.values.deliveryIsSame}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (!readOnly) {
                      handleCheckboxChange('deliveryIsSame', e);
                      return;
                    }
                  }}
                />
              </Form.Group>
            </div>

            {!formik.values.deliveryIsSame && (
              <InformationAddress
                type="deliveryAddress"
                title="Doručovací adresa"
                values={formik.values}
                errors={formik.errors}
                readOnly={readOnly}
                setFieldValue={formik.setFieldValue}
                handleChange={formik.handleChange}
              />
            )}

            <Row>
              <Visible and={[() => !readOnly]} disableSuperAdminOverride>
                <Col xs={12} className="mt-4 text-right">
                  {!formik.isSubmitting ? (
                    <Button type="submit" disabled={formik.isSubmitting}>
                      Uložit
                    </Button>
                  ) : (
                    <BasePreloader size={29} className="d-inline-block" />
                  )}
                </Col>
              </Visible>
              <Visible
                and={[
                  {
                    permissionGroupName: PermissionGroup.auctioneerInformation,
                    permissionName: UserPermission.canEdit,
                  },
                  () => readOnly,
                ]}
                disableSuperAdminOverride
              >
                <Col xs={12} className="mt-4 text-right">
                  <Button
                    to={
                      !!pageState.getPage(routes.admin.AUCTIONEER_UPDATE)
                        ? pageState.getPagePath(routes.admin.AUCTIONEER_UPDATE, { ':id': props.id as string })
                        : pageState.getPagePath(routes.admin.MY_COMPANY_UPDATE, { ':id': props.id as string })
                    }
                  >
                    Upravit
                  </Button>
                </Col>
              </Visible>
            </Row>
          </Form>
        </>
      ) : (
        <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
          <BasePreloader />
        </div>
      )}
    </div>
  );
};

export default Informations;
