import numeral from 'numeral';

export function removeAllWhitespace(value: string): string {
  return value.replace(/\s/g, '');
}

export function getIcoFormat(value: string): string {
  return removeAllWhitespace(value).replace(/^(.{3})(.{2})(.*)$/, '$1 $2 $3');
}

export function getZipFormat(value: string): string {
  return removeAllWhitespace(value).replace(/^(.{3})(.{2})$/, '$1 $2');
}

export function getBaseNumberFormat(value?: string | number) {
  return numeral(value).format('0,0.[0]');
}

export function getCurrencyFormat(value?: string | number) {
  return numeral(value).format('0,0.[0] $');
}

export function getPercentageFormat(value?: string | number) {
  return `${numeral(value).format('0,0.[0]')} %`;
}

export function getFormatBytes(size: number) {
  const suffixes = ['', 'K', 'M', 'G', 'T'];
  const base = Math.log(size) / Math.log(1024);
  return `${Math.round(Math.pow(1024, base - Math.floor(base)))}${suffixes[Math.floor(base)]}`;
}
