import React from 'react';
import classNames from 'classnames';

import adminSidebarToggle from '@assets/images/admin-sidebar-toggle.svg';
import { useAdminConfigStore } from '@stores';
import SidebarMenu from './components/SidebarMenu';

const AdminSidebar: React.FC = () => {
  const adminConfigState = useAdminConfigStore();

  return (
    <div className={classNames(['component-admin-sidebar', { 'menu-hide': adminConfigState.hideSidebar }])}>
      <div className="sidebar-content">
        <span className="sidebar-toggle-content">
          <img src={adminSidebarToggle} alt="icon" />
        </span>
        <h2 className="sidebar-title">Menu</h2>
        <SidebarMenu />
      </div>
    </div>
  );
};

export default AdminSidebar;
