import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { BasePreloader } from '@components';
import { useAuctionsApi } from '@api/auctions';
import { getCurrencyFormat } from '@helpers/formats';
import { getDateTimeFormat } from '@helpers/datetime';
import { AuctionAdminBid, AuctionResponse } from '@types';
import { getName } from '@helpers/string';

interface Props {
  auction: AuctionResponse;
}

let bidTimeout: NodeJS.Timer | undefined = undefined;

const BidsTable: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  const auctionsApi = useAuctionsApi();
  const [bids, setBids] = React.useState<AuctionAdminBid[]>([]);
  const [bidsLoaded, setBidsLoaded] = React.useState(false);

  React.useEffect(() => {
    loadBids();
    return () => {
      auctionsApi.cancelAllRequests();
      if (!!bidTimeout) {
        clearTimeout(bidTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadBids = async () => {
    if (!!bidTimeout) {
      clearTimeout(bidTimeout);
    }
    try {
      const response = await auctionsApi.getBidsAdmin(props.auction.id);
      setBidsLoaded(true);
      setBids(response.data.data);
      bidTimeout = setTimeout(() => loadBids(), 5000);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      bidTimeout = setTimeout(() => loadBids(), 5000);
    }
  };

  const renderAccountName = (bid: AuctionAdminBid) => {
    if (!!bid.userAuction.user.company) {
      return bid.userAuction.user.company;
    }
    return getName(bid.userAuction.user) || '';
  };

  return (
    <div>
      {bidsLoaded ? (
        <div className="responsive-table-content">
          <Table className="table-middle b-bottom-0">
            <thead>
              <tr>
                <th>Číslo</th>
                <th>Název účtu</th>
                <th>Kontaktní osoba</th>
                <th>Příhoz</th>
                <th>Cena</th>
                <th className="text-right">Čas přihození</th>
              </tr>
            </thead>
            <tbody>
              {bids.map((bid, index) => (
                <tr key={`bid-${index}`} className="border-top">
                  <td>{bid.id}</td>
                  <td>{renderAccountName(bid)}</td>
                  <td>
                    <Link to={pageState.getPagePath(routes.admin.USER_DETAIL, { ':id': bid.userAuction.user.id })}>
                      {getName(bid.userAuction.user) || ''}
                    </Link>
                  </td>
                  <td>{bid.bid > 0 ? getCurrencyFormat(bid.bid) : 'Dorovnáno'}</td>
                  <td>{getCurrencyFormat(bid.bidPrice)}</td>
                  <td className="text-right">{getDateTimeFormat(bid.timeCreated)}</td>
                </tr>
              ))}
              {bids.length < 1 && (
                <tr>
                  <td colSpan={100}>Nebyla nalezena žádná data.</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      ) : (
        <BasePreloader size={20} />
      )}
    </div>
  );
};

export default BidsTable;
