import * as React from 'react';
import { Table } from 'react-bootstrap';

import downloadGreenIco from '@assets/images/download-green.svg';
import { FileIcon } from '@components';
import { useUsersApi } from '@api/users';
import { AuthenticationFormFormat } from '@types';
import { useMediaApi } from '@api/media';
import fileDownload from 'js-file-download';
//import fileDownload from 'js-file-download';

const SupportDocuments: React.FC = () => {
  const usersApi = useUsersApi();
  const mediaApi = useMediaApi();
  const supportDocuments = [
    {
      format: 'pdf' as AuthenticationFormFormat,
      description: 'Doklad o prokázání totožnosti ve formátu PDF',
    },
    {
      format: 'doc' as AuthenticationFormFormat,
      description: 'Doklad o prokázání totožnosti ve formátu DOC',
    },
  ];

  const handleFileClick = async (e: React.MouseEvent<HTMLTableRowElement>, format: AuthenticationFormFormat) => {
    e.preventDefault();
    const documentData = await usersApi.getAuthenticationForm(format);
    mediaApi
      .detailFront(documentData.data.data.hash, true)
      .then((res) => {
        fileDownload(res.data, documentData.data.data.originalName);
      })
      .catch((err) => {
        if (usersApi.isCancel(err)) {
          return;
        }
        console.error(err);
      });
  };

  return (
    <div className="component-account-documents">
      <h2>Dokumenty ke stažení</h2>
      <div className="mt-4">
        <div className="responsive-table-content">
          <Table className="text-center table-middle border-bottom-0" striped>
            <tbody>
              {supportDocuments.map((supportDocument) => (
                <tr
                  key={supportDocument.format}
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    handleFileClick(e, supportDocument.format);
                  }}
                >
                  <td className="text-left">
                    <FileIcon name={supportDocument.format} />
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {supportDocument.description}
                    </a>
                  </td>
                  <td className="text-right" style={{ color: '#62CEA3' }}>
                    <img src={downloadGreenIco} alt="download" style={{ marginRight: '8px' }} />
                    Stáhnout
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default SupportDocuments;
